import React, {FC, useCallback, useEffect, useState} from "react";
import {Grid, Typography, Stack, Paper, Button, CircularProgress} from "@mui/material";
import {Reload, setNotificationMessage, setReload} from "../../../../../../../utils/redux";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CopyToClipboard from "react-copy-to-clipboard";
import {useDispatch, useSelector} from "react-redux";
import {Validators} from "../../../../../../../utils/validators";
import InputField from "../../../../../../../components/InputField";
import axiosInstance from "../../../../../../../utils/axios";
import {URLS} from "../../../../../../../utils/constants/urls";

const VettingDocNumberSection: FC<{
  number: string,
  id: string | undefined,
  activeDocument: any,
  candidate_id: string | undefined,
  title: string,
  type: string
}> = ({
        number,
        id,
        activeDocument,
        candidate_id,
        title,
        type
      }) => {


  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [vettingNumber, setVettingNumber] = useState(number);
  const reload = useSelector(Reload);
  const [formError, setFormError] = useState<boolean>();

  const handleSave = useCallback(async () => {
    try {
      setLoading(true);
      await axiosInstance.post(
        `${process.env.REACT_APP_NODE_V3_API_URL}${URLS.vetting_doc_number_change}/${type}`,
        {
          request_id: id,
          vetting_document_id: activeDocument.id,
          candidate_id: candidate_id,
          number: vettingNumber
        }
      );
      dispatch(setReload(!reload));
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: `${title} has been changed successfully`,
        })
      );
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error updating data, try again later.",
        })
      );
    }
  }, [dispatch, id, reload, vettingNumber]);

  const handleNumberChange = (value: string, field: string, error?: { error: boolean; message: string }) => {
    if (error && error.error) {
      setFormError(true);
    } else {
      setFormError(false);
    }
    setVettingNumber(value);
  }

  useEffect(() => {
    setVettingNumber(number);
  }, [number]);

  return (
    <>
      <Grid xs={12} container className="card-vetting" sx={{marginTop: '2rem !important'}}>
        <Grid
          container
          className="card-head-table"
          direction="row"
          justifyContent={"space-between"}
          alignItems={'center'}
          sx={{marginBottom: '0 !important'}}
        >
          <Grid
            item
            xs={12}
            sx={{pl: 1, pt: 1, pb: 2}}
          >

            <Typography
              variant={"body2"}
              fontSize={"16px"}
              fontWeight={"bold"}
              paddingTop={"10px"}
              sx={{pl: {xs: "18px", md: 2}}}
            >
              {title}
            </Typography>

          </Grid>
        </Grid>
        <Grid xs={12} item md={12}>
          <Grid
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >

            <Stack sx={{
              backgroundColor: "#f5fbff",
              padding: '1.5rem 3rem',
              width: '100%',
              borderBottomRightRadius: "12px",
              borderBottomLeftRadius: "12px",
            }}>

              <Stack justifyContent={"space-between"} direction={"row"} sx={{flex: 1, padding: '1rem 2rem'}}
                     component={Paper}>
                <Stack direction={"row"} gap={"1rem"} alignItems={"start"}>
                  <InputField
                    label={title}
                    fullWidth
                    focused
                    textChange={handleNumberChange}
                    disabled={loading}
                    value={vettingNumber}
                    validators={[{
                      check: type === 'rtw' ? Validators.RTW : Validators.NIN,
                      message: type === 'rtw' ? 'Please enter a valid RTW Number' : 'Please enter a valid NIN Number'
                    }]}
                  />
                  <Button
                    sx={{
                      background: "#5AB9F9",
                      color: "#ffffff",
                      fontSize: 16,
                      fontWeight: 500,
                      p: "14px 40px",
                      height: 'fit-content',
                      width: 'fit-content',
                      textWrap: 'nowrap',
                      '&:hover': {
                        background: "#3e9bda",
                      },
                      '&:disabled': {
                        background: "#e7e7e7",
                        color: '#8f8f8f'
                      }
                    }}
                    disabled={loading || formError}
                    onClick={handleSave}
                  >
                    {loading && <CircularProgress sx={{mr: 1, color: '#fff'}} size={"1rem"}/>}Save Changes
                  </Button>
                </Stack>
                <Stack direction={"row"} gap={"1rem"}>

                  <CopyToClipboard
                    text={number}
                    onCopy={() =>
                      dispatch(
                        setNotificationMessage({
                          display: true,
                          severity: "success",
                          message: `${title} is copied to Clipboard`
                        })
                      )
                    }
                  >
                    <Button variant={"text"}>
                      <ContentCopyIcon/>
                    </Button>
                  </CopyToClipboard>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default VettingDocNumberSection
