import React, { FC, useState } from "react";
import { images } from "../../utils/constants/images";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { mainOptionsConfig, subOptionsConfig } from "./config";
import * as Amplify from "../../utils/services/amplify";
import { setAuthentication } from "../../utils/redux";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box } from "@mui/system";

import { UserRole } from "../../utils/redux";
import { User } from "../../utils/redux/reducer/authentication-slice";
import { selectItrisStatus } from "../../utils/redux/reducer/itrisStatus-slice";

const DrawerContent: FC<{
  isMobile: boolean;
  open: boolean;
  setOpen: (value: boolean) => void;

}> = ({ isMobile, open, setOpen }) => {
  const Navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("");
  const userRole = useSelector(UserRole);
  const user = useSelector(User);
  const isItrisEnabled = useSelector(selectItrisStatus);

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: isMobile ? "flex-end" : open ? "flex-start" : "center",
    padding: theme.spacing(0, 0.9),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  return (
    <>
      {!isMobile && (
        <DrawerHeader>

          <Box display={"flex"} justifyContent={"center"} sx={{ width: open ? '100%' : 'auto' }}>
            <img
              src={open ? images.kedExpandIcon : images.kedSmallIcon}
              alt="ked.io"
            />
          </Box>

        </DrawerHeader>
      )}

      {isMobile && (
        <IconButton onClick={() => setOpen(false)} sx={{ position: 'absolute', right: '0.5rem', top: '0.5rem' }}>
          {<ChevronLeftIcon />}
        </IconButton>
      )}

      {isMobile && (
        <Box alignItems="center" padding="1rem 1rem 0px 1rem" justifyContent={"center"} display={"flex"} flexDirection={"column"} gap={"1rem"}>
          <img src={images.kedExpandIcon} alt="ked.io" style={{ margin: 0 }} />
          <Box
            textAlign="center"
          >
            <img className={user && user.picture ? 'circle-photo' : ''} src={user && user.picture ? user.picture : images.dummyProfilePic} alt="profilepic" />
            {user && (
              <Typography variant="h6" fontSize="18px" fontWeight="500" sx={{ textTransform: 'capitalize' }}>
                {user.given_name + " " + user.family_name}
              </Typography>
            )}
          </Box>
        </Box>
      )}
      <List
        sx={{
          display: "flex",
          gap: "20px",
          flexDirection: "column",
        }}
      >
        {mainOptionsConfig(userRole, isItrisEnabled).map((item, index) => (
          <React.Fragment key={index}>
            {!!item && (
              <ListItem
                key={item.text}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => {
                    setSelected(item.text);
                    Navigate(item.url);
                    if (isMobile) {
                      setOpen(false)
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                    data-tut={!isMobile ? item.tourCofig : ''}
                  >
                    <img
                      src={
                        selected === item.text || location.pathname === item.url
                          ? item.selectedIcon
                          : item.icon
                      }
                      alt={item.text}
                    />
                  </ListItemIcon>
                  <ListItemText
                    data-tut={isMobile ? item.tourCofig : ''}
                    primary={item.text}
                    sx={{
                      opacity: open ? 1 : 0,
                      color:
                        selected === item.text || location.pathname === item.url
                          ? "#00B2FF"
                          : "#212121",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </React.Fragment>
        ))}
      </List>
      <List
        sx={{
          display: "flex",
          gap: { xs: "10px", sm: "10px", md: "20px", lg: "40px" },
          pb: { xs: "10px", sm: "10px", md: "20px", lg: "40px" },
          flexDirection: "column",
        }}
      >
        {subOptionsConfig(userRole).map((item) => (
          <>
            {!!item && (
              <ListItem
                key={item.text}
                disablePadding
                sx={{ display: item.isMobile }}
                data-tut={item.tourCofig}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={async () => {
                    if (item.option === "logout") {
                      await Amplify.Logout();
                      dispatch(
                        setAuthentication({
                          authenticated: false,
                          isProfileComplete: false,
                          user: null,
                        })
                      );
                    } else {
                      setSelected(item.text);
                      Navigate(item.url);
                      if (isMobile) {
                        setOpen(false)
                      }
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                    data-tut={item.tourCofig}
                  >
                    <img
                      src={
                        selected === item.text ? item.selectedIcon : item.icon
                      }
                      alt={item.text}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{
                      opacity: open ? 1 : 0,
                      color: selected === item.text ? "#00B2FF" : "#212121",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </>
        ))}
      </List>
    </>
  );
};

export default DrawerContent;
