import React, {FC, useState} from "react";
import {Button, Collapse, Typography} from "@mui/material";
import {ArrowDropDown, ArrowDropUp} from "@mui/icons-material";
import {Stack} from "@mui/system";

const FailCasesBreakdowns: FC<{
  breakdowns: any
}> = ({breakdowns}) => {

  const breakdownsFiltered = breakdowns.filter((data:any) => data.result.toLowerCase() === 'fail');
  const [showBreakdown, setShowBreakdown] = useState(false);

  return(
    <Stack gap={"0.5rem"}>
      <Button
        size={"small"}
        endIcon={showBreakdown ? <ArrowDropDown/> : <ArrowDropUp/>}
        variant={"text"}
        sx={{fontSize: '0.875rem', width: 'fit-content'}}
        onClick={() => {
          setShowBreakdown(!showBreakdown)
        }}
      >
        Breakdowns
      </Button>
      <Collapse in={showBreakdown}>
        {breakdownsFiltered.map((breakdown: any, index: number) => (
          <Stack
            justifyContent={"space-between"}
            direction={"row"}
            key={index}
            sx={{
              position: 'relative',
              '&:after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                height: '1px',
                width: '100%',
                background: '#d5d5d5',
                zIndex: 1,
              }
            }}
          >
            <Typography sx={{
              fontSize: '0.875rem',
              textTransform: 'capitalize',
              background: '#fff',
              position: 'relative',
              zIndex: 2,
              paddingRight: '0.5rem'
            }}>
              {breakdown.subCheck.replace(/_/g, ' ')}
            </Typography>
            <Typography sx={{
              fontSize: '0.875rem',
              textTransform: 'capitalize',
              background: '#fff',
              position: 'relative',
              zIndex: 2,
              paddingLeft: '0.5rem',
              color: breakdown.result.toLowerCase() === 'fail' ? '#d92828' : 'black'
            }}>
              {breakdown.result}
            </Typography>
          </Stack>
        ))}
      </Collapse>
    </Stack>
  )
}

export default FailCasesBreakdowns
