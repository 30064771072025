import React, { FC, useMemo, useState } from "react";
import {
  Stack,
  Typography,
  Divider,
  Table,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
  Chip,
  Grid, CircularProgress,
  TableRow
} from "@mui/material";
import dayjs from "dayjs";
import { VisibilityOutlined } from "@mui/icons-material";
import PrimaryButton from "../../../../../../components/Button/PrimaryButton";
import Modal from "../../../../../../components/modal/Modal";
import axiosInstance from "../../../../../../utils/axios";
import FailedCases from "../YotiResult/FailChecks/FailedCases";

const RTWHistory: FC<any> = ({ history }) => {

  const [showDetails, setShowDetails] = useState(false);
  const [sessionId, setSessionId] = useState<string>('')
  const [failChecks, setFailChecks] = useState<any>([])
  const [isFailChecking, setIsFailChecking] = useState<boolean>(false);

  const openModal = async (id: string) => {
    setSessionId(id);
    setShowDetails(true);

    setIsFailChecking(true)
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_NODE_V3_API_URL}/authenticated/yoti/checks/${id}`
    );
    setFailChecks(response.data);
    setIsFailChecking(false)
  }
  const closeModal = () => {
    setShowDetails(false);
    setSessionId('');
  }

  const viewModalText = useMemo(() => {
    const openedHistory = history.find((data: any) => data.id === sessionId)
    return (
      <Stack gap={"0"}>
        {isFailChecking &&
          <Stack
            position={"absolute"}
            left={0}
            right={0}
            bottom={0}
            alignItems={"center"}
            justifyContent={"center"}
            top={0}
            sx={{ background: 'rgba(255, 255, 255, 1)', backgroundFilter: 'blur(4px)', zIndex: 2 }}>
            <CircularProgress size={20} />
          </Stack>
        }
        {openedHistory &&
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>CREATED AT</TableCell>
                <TableCell>CLOSED AT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {dayjs(
                    new Date(openedHistory.created_at)
                  ).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell>
                  {dayjs(
                    new Date(openedHistory.closed_at)
                  ).format("DD/MM/YYYY")}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        }

        {
          failChecks && failChecks.aborted && failChecks.aborted.length > 0 &&
          <FailedCases
            checks={failChecks.aborted}
            subChecks={failChecks.checks}
            type={"ABORTED"}
            id={sessionId}
            showReinit={false}
          />
        }
        {
          failChecks && failChecks.requirement_not_met && failChecks.requirement_not_met.length > 0 &&
          <FailedCases
            checks={failChecks.requirement_not_met}
            subChecks={failChecks.checks}
            type={"REQUIREMENT_NOT_MET"}
            id={sessionId}
            showReinit={false}
          />
        }
      </Stack>
    )
  }, [showDetails, sessionId, isFailChecking, failChecks]);

  const openViewModal = useMemo(() => {
    return (
      <Modal
        open={showDetails}
        setModalClose={() => closeModal()}
        children={viewModalText}
        title={undefined}
        size="sm"
        padding="0px"
        Radius={"15px"}
        closeButton={true}
        className={undefined}
        sx={{
          "& .closeButton": {
            position: 'absolute',
            top: '0.5rem',
            right: '0.5rem'
          }
        }}
      />
    );
  }, [showDetails, viewModalText, sessionId, isFailChecking, failChecks]);

  return (
    <>
      {showDetails && openViewModal}
      <Stack
        flex={1}
        alignItems={"left"}
        justifyContent={"start"}
        width={"100%"}
        padding={"1.5rem"}>
        <Stack sx={{
          background: "#fff",
          borderRadius: "1rem",
          padding: '1rem',
          boxShadow: '0 0 10px #ddd',
          display: "flex",
        }}>
          <Typography variant="h6" fontSize={"16px"} fontWeight={"bold"}>
            Right to Work history
          </Typography>
          <Divider sx={{ m: '0.5rem 0' }} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>CREATED AT</TableCell>
                <TableCell>CLOSED REASON</TableCell>
                <TableCell>CLOSED AT</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((data: any, index: number) => (
                <TableRow key={index} onClick={() => openModal(data.id)} sx={{ cursor: 'pointer' }}>
                  <TableCell>
                    {dayjs(
                      new Date(data.created_at)
                    ).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>
                    <Chip label={'FAILED'} color={"error"} variant="outlined" />
                  </TableCell>
                  <TableCell>
                    {dayjs(
                      new Date(data.closed_at)
                    ).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell align={"right"}>
                    <IconButton onClick={() => openModal(data.id)}>
                      <VisibilityOutlined />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Stack>
      </Stack>
    </>
  )
}
export default RTWHistory;
