import React, { FC } from "react";
import { Grid, Typography, Stack } from "@mui/material";
import FileUpload from "react-material-file-upload";
import { identifiers } from "../../../../../../../../utils/constants/identifiers";

const FromDevice: FC<{
  files: File[] | undefined;
  setFiles: (file: File[]) => void;
  isPadded?: boolean
}> = ({ files, setFiles, isPadded = true }) => {
  const fileValidator = (file: any) => {
    if (file.size > identifiers.maxFileSize) {
      return {
        code: "size-too-large",
        message: `File is larger than ${identifiers.maxFileSizeText}`,
      };
    }
    return null;
  }

  return (
    <Grid
      container
      position={"relative"}
      justifyContent={"center"}
      xs={12}
      // spacing={2}
      sx={{
        p: { xs: 1, md: isPadded ? 3 : "1.5rem 0" },
        pt: 2,
        position: 'relative'
      }}
    >
      <FileUpload
        sx={{
          border: "2px dashed",
          minHeight: "280px",
          width: "100%",
          backgroundColor: "white",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          '& .MuiFormHelperText-root': {
            color: 'red'
          }
        }}
        value={files as File[]}
        onChange={setFiles}
        buttonProps={{ variant: "text" }}
        buttonText={"Choose Files"}
        multiple={false}
        title={"Drop Your File here or"}
        typographyProps={{
          fontSize: 14,
          fontWeight: 600,
        }}
        accept={identifiers.accept as string}
        validator={fileValidator}
      />
      <Stack sx={{
        position: 'absolute',
        bottom: '2.5rem',
        alignItems: 'center'
      }}>
        <Typography sx={{ fontSize: '0.85rem', color: 'rgba(0,0,0,0.6)' }}>
          or drag file in here
        </Typography>
        <Typography sx={{ fontSize: '0.85rem', color: 'rgba(0,0,0,0.6)' }}>
          accepted file .doc, .docx, .jpg, .png and .pdf (max size {identifiers.maxFileSizeText})
        </Typography>
      </Stack>
    </Grid>
  );
};

export default FromDevice;
