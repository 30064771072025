import demoLayout from '../../assets/images/demoLayout.svg';
import kedIcon from '../../assets/images/ked-icon.svg';
import deleteWIcon from '../../assets/images/delete-white.svg';
import userIcon from '../../assets/images/user.svg';
import mobileLoginBanner from '../../assets/images/mobileLoginBanner.svg';

// Checkboxes
import { ReactComponent as checkBoxSquareChecked } from '../../assets/images/checkbox-square-checked.svg';
import { ReactComponent as checkBoxSquareUnchecked } from '../../assets/images/checkbox-square-unchecked.svg';
import { ReactComponent as checkBoxCircleChecked } from '../../assets/images/checkbox-circular-checked.svg';
import { ReactComponent as checkBoxCircleUnchecked } from '../../assets/images/checkbox-circular-unchecked.svg';
import { ReactComponent as checkBoxShieldChecked } from '../../assets/images/checkbox-shield-checked.svg';
import { ReactComponent as checkBoxShieldUnchecked } from '../../assets/images/checkbox-shield-unchecked.svg';
import { ReactComponent as successShieldChecked } from '../../assets/images/success-shield.svg';
import { ReactComponent as dangerShieldChecked } from '../../assets/images/danger-shiled.svg';
import { ReactComponent as warningShieldChecked } from '../../assets/images/warning-shiled.svg';
import { ReactComponent as shieldUnchecked } from '../../assets/images/uncheckedShield.svg';
// Sidebar Icons
import dashboardIcon from '../../assets/images/sideBarIcons/dashboard.svg';
import documentsIcon from '../../assets/images/sideBarIcons/documents.svg';
import applicantsIcon from '../../assets/images/sideBarIcons/applicants.svg';
import inviteMembersIcon from '../../assets/images/sideBarIcons/invite-members.svg';
import logoutIcon from '../../assets/images/sideBarIcons/logout.svg';
import settingsIcon from '../../assets/images/sideBarIcons/settings.svg';
import requestIcon from '../../assets/images/sideBarIcons/request.svg';
import kedSmallIcon from '../../assets/images/sideBarIcons/ked.svg';
import kedExpandIcon from '../../assets/images/sideBarIcons/ked-expanded.svg';
import helloIcon from '../../assets/images/sideBarIcons/helloIcon.svg';
import dummyProfilePic from '../../assets/images/sideBarIcons/dummyProfile.svg';
import helpIcon from '../../assets/images/sideBarIcons/help.svg';
import helpIconSelected from '../../assets/images/sideBarIcons/help-selected.svg';

// Selected Sidebar Icons
import dashboardSelectedIcon from '../../assets/images/sideBarIcons/dashboard-selected.svg';
import applicantsSelectedIcon from '../../assets/images/sideBarIcons/applicants-selected.svg';
import documentsSelectedIcon from '../../assets/images/sideBarIcons/documents-selected.svg';
import requestSelectedIcon from '../../assets/images/sideBarIcons/request-selected.svg';
import settingsSelectedIcon from '../../assets/images/sideBarIcons/settings-selected.svg';
// dummy icon
import dummyIcon from '../../assets/images/sideBarIcons/dummy.svg';
import dragAndDropImage from '../../assets/images/dragAndDropImage.svg';
import dropZoneText from '../../assets/images/drop-zone-text.png';
import dateIcon from '../../assets/images/date.svg';
import no_data_available from '../../assets/images/no_data_available.png';
import not_found_page from '../../assets/images/404.svg';
import tooltip from '../../assets/images/tooltip.svg';
import infoTooltip from '../../assets/images/info.svg';
import whiteDownLoadBtn from '../../assets/images/white-download-btn.svg';
import warningTooltip from '../../assets/images/warningTooltip.svg';
import closeBtn from '../../assets/images/close.svg';
import successIcon from '../../assets/images/successIcon.svg';
// Navbar Icons
import searchIcon from '../../assets/images/navBarIcons/search.svg';
import notificationIcon from '../../assets/images/navBarIcons/notification.svg';
import noNotificationIcon from '../../assets/images/navBarIcons/no-notification.svg';
import chevronDownIcon from '../../assets/images/navBarIcons/chevron-down.svg';
import chevronUpIcon from '../../assets/images/navBarIcons/chevron-up.svg';
import requestUpload from '../../assets/images/requestUpload.svg';
import requestDesignation from '../../assets/images/requestDesignation.svg';
import WorkReference from '../../assets/images/WorkReference.svg';

import calenderIcon from '../../assets/images/calender.svg';
import infoIcon from '../../assets/images/infoIcon.svg';
import infoIconActive from '../../assets/images/infoIcon-active.svg';

import deleteIcon from '../../assets/images/deleteIcon.svg';
import downloadIcon from '../../assets/images/downloadIcon.svg';
import viewIcon from '../../assets/images/viewIcon.svg';
import totalRequestIcon from '../../assets/images/totalRequestIcon.svg';
import draftRequestIcon from '../../assets/images/draftRequestIcon.svg';
import publicRequestIcon from '../../assets/images/publicRequestIcon.svg';
import blurBg from '../../assets/images/blurBg.svg';
import sampleReferenceForm from '../../assets/images/sample-reference-form.svg';
import emailTop from '../../assets/images/emailTop.svg';
import emailBottom from '../../assets/images/emailBottom.svg';
import emailRefereeDetails from '../../assets/images/emailRefereeDetails.svg';
import emailEmploymentDetails from '../../assets/images/emailEmploymentDetails.svg';
import emailAdditionalComments from '../../assets/images/emailAdditionalComments.svg';
import viewImage from '../../assets/images/viewImage.svg';
import navigateNext from '../../assets/images/navigateNext.svg';
import dummyBackground from '../../assets/images/dummyBackground.png';
import AcademicReference from '../../assets/images/academic-reference.svg';
import CharacterReference from '../../assets/images/character-reference.png';
import EmploymentReference from '../../assets/images/employmentReference.svg';
import emptyIcon from '../../assets/images/empty.svg';
import calenderIconCreateRequest from '../../assets/images/calenderIcon.svg';
import thumbsUp from '../../assets/images/thubms-up.svg';
import ItrisLogo from '../../assets/images/itrisLogo.svg';
import YotiScanIcon from '../../assets/images/YotiScanIcon.svg'
import YotiKedIcon from '../../assets/images/YotiKedIcon.png'

const referenceVideo = require('../../assets/videos/dummyVideo.mp4');

export const images = {
  mobileLoginBanner,
  demoLayout,
  CharacterReference,
  AcademicReference,
  draftRequestIcon,
  publicRequestIcon,
  kedIcon,
  deleteWIcon,
  totalRequestIcon,
  userIcon,
  dashboardIcon,
  documentsIcon,
  applicantsIcon,
  inviteMembersIcon,
  logoutIcon,
  settingsIcon,
  requestIcon,
  kedSmallIcon,
  kedExpandIcon,
  dashboardSelectedIcon,
  applicantsSelectedIcon,
  documentsSelectedIcon,
  requestSelectedIcon,
  settingsSelectedIcon,
  dummyIcon,
  searchIcon,
  notificationIcon,
  noNotificationIcon,
  chevronDownIcon,
  chevronUpIcon,
  requestUpload,
  requestDesignation,
  calenderIcon,
  infoIcon,
  infoIconActive,
  deleteIcon,
  downloadIcon,
  viewIcon,
  checkBoxSquareChecked,
  checkBoxSquareUnchecked,
  checkBoxCircleChecked,
  checkBoxCircleUnchecked,
  helloIcon,
  dummyProfilePic,
  blurBg,
  checkBoxShieldChecked,
  checkBoxShieldUnchecked,
  dragAndDropImage,
  dateIcon,
  dropZoneText,
  sampleReferenceForm,
  emailTop,
  emailBottom,
  emailRefereeDetails,
  emailEmploymentDetails,
  emailAdditionalComments,
  viewImage,
  navigateNext,
  dummyBackground,
  WorkReference,
  no_data_available,
  EmploymentReference,
  not_found_page,
  emptyIcon,
  successShieldChecked,
  dangerShieldChecked,
  warningShieldChecked,
  shieldUnchecked,
  calenderIconCreateRequest,
  tooltip,
  whiteDownLoadBtn,
  thumbsUp,
  infoTooltip,
  referenceVideo,
  warningTooltip,
  closeBtn,
  successIcon,
  helpIcon,
  helpIconSelected,
  YotiScanIcon,
  YotiKedIcon,
  ItrisLogo
};


