import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
} from "@mui/material";
import DocumentList from "./DocumentList";
import { VettingDocument } from "../../RequestDetails/RequestDetailsModel";
import "../CandidateProfile.scss";
import CheckBox from "../../../../../components/CheckBox";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import {
  AcademicData,
  CandidateIDV,
  uCheckStatus,
} from "../../../../../utils/redux";
import { useSelector } from "react-redux";
import AcademicQualification from "./DocumentList/AcademicQualification";
import { identifiers } from "../../../../../utils/constants/identifiers";

interface VettingDocumentsProps {
  documents: VettingDocument[];
  setDocuments: (documents: VettingDocument[]) => void;
  getRequest: () => void;
  yotiReport: { check_result: any; documents: any[]; status: string, identity_profile_status: string };
  setActive: Dispatch<SetStateAction<number>>;
  active: number;
  isDataLoading?: boolean;
  yotiHistory?: [];
}

const LinearProgressWithLabel: FC<
  { value: number; reqNumber: number } & LinearProgressProps
> = ({ value, reqNumber, ...props }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          value={(value / reqNumber) * 100}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {`${Math.round(value)}`}/{reqNumber}
        </Typography>
      </Box>
    </Box>
  );
};

const VettingDocuments: FC<VettingDocumentsProps> = ({
  documents,
  setDocuments,
  yotiReport,
  setActive,
  active,
  getRequest,
  isDataLoading = false,
  yotiHistory,
}) => {
  const data = useSelector(AcademicData);
  const candidate_idv = useSelector(CandidateIDV);
  const [academicDataList, setAcademicDataList] = useState<any>();
  const status = useSelector(uCheckStatus);

  const handleActive = (index: number) => {
    setActive(index);
    getRequest();
  };

  useEffect(() => {
    if (data.academic_qualification_ids && data.academic_qualification_data) {
      const arr1 = data.academic_qualification_data;
      const arr2 = data.academic_qualification_ids;
      const result: any[] = [];
      arr1.forEach((val) => {
        if (arr2.includes(val.id)) {
          result.push(val);
        }
      });
      setAcademicDataList(result);
    }
  }, [data.academic_qualification_data, data.academic_qualification_ids]);

  const background = (item: VettingDocument, index: number) => {
    if (index === active) {
      return "#E3F4FF";
    }
    if (item.is_rejected) {
      return "#F6D3D3";
    }
    if (item.name === identifiers.opc && item.approval_status === "REJECTED") {
      return "#F6D3D3";
    }

    if (
      item.name === identifiers.rtw &&
      item.is_british_or_irish_citizen &&
      candidate_idv &&
      candidate_idv.status !== "ONGOING"
    ) {
      return "#E3F4FF";
    }
    if (
      item.name === identifiers.rtw &&
      item.sub_document_count === parseInt(item.documents_required) &&
      !!item.rtw_code
    ) {
      return "#E3F4FF";
    }

    if (
      item.name === identifiers.rtw &&
      item.sub_document_count > 0 &&
      !item.rtw_code
    ) {
      return "#FEFBEC";
    }

    if (
      item.name === identifiers.nin &&
      item.sub_document_count === parseInt(item.documents_required) &&
      !!item.nin
    ) {
      return "#E3F4FF";
    }

    if (
      item.name === identifiers.nin &&
      item.sub_document_count > 0 &&
      !item.nin
    ) {
      return "#FEFBEC";
    }
    if (item.sub_document_count === parseInt(item.documents_required)) {
      return "#E3F4FF";
    }
    if (item.name === identifiers.dbs && status === "SUBMITTED") {
      return "#FEFBEC";
    }
    if (item.name === identifiers.dbs && status === "IN_REVIEW") {
      return "#FEFBEC";
    }
    if (item.name === identifiers.dbs && status === "REJECTED") {
      return "#F6D3D3";
    }
    if (item.name === identifiers.dbs && status === "APPROVED") {
      return "#FEFBEC";
    }
    if (item.sub_document_count > 0) {
      return "#FEFBEC";
    }
    if (
      item.name === identifiers.academic_qualification &&
      data.academic_qualification_ids?.length > 0
    ) {
      return "#E3F4FF";
    }
    return "#FFFFFF";
  };

  return (
    <Grid container spacing={4}>
      <Grid item md={12} xs={12} lg={3.4}>
        <List dense sx={{
          width: "100%",
          p: 0,
          display: 'flex',
          gap: {
            xs: '1rem',
            md: '1.5rem'
          },
          flexDirection: 'column'
        }}>
          {documents?.map((item, index) => {
            return (
              <ListItem key={index} disablePadding sx={{ p: 0 }} onClick={() => handleActive(index)}>

                {item.name === identifiers.rtw &&
                  item.is_british_or_irish_citizen ? (
                  <ListItemIcon sx={{ cursor: 'pointer' }}>
                    <CheckBox
                      checked={
                        candidate_idv && candidate_idv?.status !== "ONGOING"
                      }
                      disabled
                      type="shield"
                      sx={{ marginBottom: "8px" }}
                      shieldColor={
                        item.is_rejected
                          ? "error"
                          : item.is_british_or_irish_citizen &&
                            candidate_idv &&
                            candidate_idv?.status !== "ONGOING" && candidate_idv?.identity_profile_status !== "ABORTED"
                            ? "success"
                            : "warning"
                      }
                    />
                  </ListItemIcon>
                ) : item.name === identifiers.rtw &&
                  !item.is_british_or_irish_citizen ? (
                  <ListItemIcon sx={{ cursor: 'pointer' }}>
                    <CheckBox
                      checked={
                        (item.sub_document_count ===
                          parseInt(item.documents_required) &&
                          !!item.rtw_code) ||
                        item.sub_document_count > 0
                      }
                      disabled
                      type="shield"
                      sx={{ marginBottom: "8px" }}
                      shieldColor={
                        item.is_rejected
                          ? "error"
                          : item.sub_document_count ===
                            parseInt(item.documents_required) &&
                            item.rtw_code
                            ? "success"
                            : "warning"
                      }
                    />
                  </ListItemIcon>
                ) : item.name === identifiers.academic_qualification ? (
                  <ListItemIcon sx={{ cursor: 'pointer' }}>
                    <CheckBox
                      checked={data.academic_qualification_ids?.length > 0}
                      disabled
                      type="shield"
                      sx={{ marginBottom: "8px" }}
                      shieldColor={
                        item.is_rejected
                          ? "error"
                          : data.academic_qualification_ids?.length > 0
                            ? "success"
                            : "warning"
                      }
                    />
                  </ListItemIcon>
                ) : (
                  <ListItemIcon sx={{ cursor: 'pointer' }}>
                    <CheckBox
                      checked={
                        item.sub_document_count > 0 ||
                        (item.name === identifiers.dbs &&
                          (status === "IN_REVIEW" ||
                            status === "SUBMITTED" ||
                            status === "REJECTED" ||
                            status === "APPROVED"))
                      }
                      disabled
                      type="shield"
                      sx={{ marginBottom: "8px" }}
                      shieldColor={
                        item.is_rejected
                          ? "error"
                          : item.name === identifiers.opc &&
                            item.approval_status === "REJECTED"
                            ? "error"
                            : item.name === identifiers.nin &&
                              item.sub_document_count ===
                              parseInt(item.documents_required) &&
                              item.nin
                              ? "success"
                              : item.name !== identifiers.nin &&
                                item.sub_document_count ===
                                parseInt(item.documents_required)
                                ? "success"
                                : item.name === identifiers.dbs &&
                                  status === "IN_REVIEW"
                                  ? "warning"
                                  : item.name === identifiers.dbs &&
                                    status === "SUBMITTED"
                                    ? "warning"
                                    : item.name === identifiers.dbs &&
                                      status === "APPROVED"
                                      ? "warning"
                                      : item.name === identifiers.dbs &&
                                        status === "REJECTED"
                                        ? "error"
                                        : "warning"
                      }
                    />
                  </ListItemIcon>
                )}
                <ListItemButton sx={{ p: 0 }}>
                  <ListItemText
                    sx={{
                      background: background(item, index),
                      border: "1px solid rgba(90, 185, 249, 0.25)",
                      borderRadius: "8px",
                      margin: "0",
                      padding: "8px 20px",
                    }}
                    primary={
                      <Typography
                        variant={"body2"}
                        fontWeight={500}
                        color={"#212121"}
                        fontSize={14}
                      >
                        {item.name}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography
                          variant={"body2"}
                          fontWeight={400}
                          color={"#333333"}
                          fontSize={14}
                        >
                          {item.sub_text}
                        </Typography>
                        {item.documents_required !== "N/A" && (
                          <>
                            {item.name === identifiers.rtw &&
                              item.is_british_or_irish_citizen ? (
                              <LinearProgressWithLabel
                                variant="determinate"
                                value={
                                  candidate_idv &&
                                    candidate_idv?.status !== "ONGOING"
                                    ? 1
                                    : 0
                                }
                                color={
                                  index === active
                                    ? "primary"
                                    : candidate_idv &&
                                      candidate_idv?.status !== "ONGOING"
                                      ? "primary"
                                      : "secondary"
                                }
                                reqNumber={1}
                              />
                            ) : item.name === identifiers.rtw &&
                              !item.is_british_or_irish_citizen ? (
                              <LinearProgressWithLabel
                                variant="determinate"
                                value={item.sub_document_count}
                                color={
                                  index === active
                                    ? "primary"
                                    : item.is_rejected
                                      ? "error"
                                      : item.sub_document_count ===
                                        parseInt(item.documents_required) &&
                                        item.rtw_code
                                        ? "success"
                                        : item.sub_document_count > 0
                                          ? "warning"
                                          : item.sub_document_count > 0 &&
                                            !item.rtw_code
                                            ? "warning"
                                            : "secondary"
                                }
                                reqNumber={parseInt(item.documents_required)}
                              />
                            ) : item.name ===
                              identifiers.academic_qualification ? (
                              <LinearProgressWithLabel
                                variant="determinate"
                                value={
                                  data.academic_qualification_ids &&
                                    data.academic_qualification_ids?.length
                                    ? data.academic_qualification_ids?.length
                                    : 0
                                }
                                color={
                                  index === active
                                    ? "primary"
                                    : data.academic_qualification_ids &&
                                      data.academic_qualification_ids?.length >
                                      0
                                      ? "primary"
                                      : "secondary"
                                }
                                reqNumber={
                                  data.academic_qualification_ids &&
                                    data.academic_qualification_ids?.length > 0
                                    ? data.academic_qualification_ids?.length
                                    : 1
                                }
                              />
                            ) : (
                              <LinearProgressWithLabel
                                variant="determinate"
                                value={item.sub_document_count}
                                color={
                                  index === active
                                    ? "primary"
                                    : item.is_rejected
                                      ? "error"
                                      : item.name === identifiers.opc &&
                                        item.approval_status === "REJECTED"
                                        ? "error"
                                        : item.name === identifiers.nin &&
                                          item.sub_document_count ===
                                          parseInt(item.documents_required) &&
                                          item.nin
                                          ? "primary"
                                          : item.name !== identifiers.nin &&
                                            item.sub_document_count ===
                                            parseInt(item.documents_required)
                                            ? "primary"
                                            : item.name === identifiers.dbs &&
                                              status === "IN_REVIEW"
                                              ? "warning"
                                              : item.name === identifiers.dbs &&
                                                status === "SUBMITTED"
                                                ? "warning"
                                                : item.name === identifiers.dbs &&
                                                  status === "APPROVED"
                                                  ? "warning"
                                                  : item.name === identifiers.dbs &&
                                                    status === "REJECTED"
                                                    ? "error"
                                                    : item.sub_document_count > 0
                                                      ? "warning"
                                                      : "secondary"
                                }
                                reqNumber={parseInt(item.documents_required)}
                              />
                            )}
                          </>
                        )}
                      </>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Grid>
      {documents?.length > 0 && (
        <Grid item xs={12} md={12} lg={8.6} sx={{ position: 'relative' }}>
          {documents[active].name !== identifiers.academic_qualification ? (
            <DocumentList
              documents={documents}
              active={active}
              setDocuments={setDocuments}
              yotiReport={yotiReport}
              yotiHistory={yotiHistory}
              isDataLoading={isDataLoading}
            />
          ) : (
            <AcademicQualification
              documents={documents}
              active={active}
              setDocuments={setDocuments}
              academicData={academicDataList}
              isDataLoading={isDataLoading}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default VettingDocuments;
