import React, {FC, useMemo, useState, useEffect, useCallback} from "react";
import {
  CircularProgress,
  Grid,
  Typography,
  TableCell,
  styled,
  tableCellClasses,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper, Stack,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputField from "../../../../../../components/InputField";
import {DocumentListProps} from "../../../RequestDetails/DocumentList/DocumentList";
import "../../CandidateProfile.scss";
import dayjs from "dayjs";
import {images} from "../../../../../../utils/constants/images";
import {identifiers} from "../../../../../../utils/constants/identifiers";
import {URLS} from "../../../../../../utils/constants/urls";
import axiosInstance from "../../../../../../utils/axios";
import PrimaryButton from "../../../../../../components/Button/PrimaryButton";
import {Document, Page} from "react-pdf";
import Modal from "../../../../../../components/modal/Modal";
import {SubDocument} from "../../../RequestDetails/RequestDetailsModel";
import {setNotificationMessage} from "../../../../../../utils/redux";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {AcademicDocument} from "../../../../../../utils/redux/reducer/candidate-vetting-slice";
import * as Amplify from "../../../../../../utils/services/amplify";
import axios from "axios";
import PageLoader from "../../../../../../components/PageLoader";
import {tourStep} from "../../../../../../utils/constants/coachMarks";
import {
  User,
  setUserData,
} from "../../../../../../utils/redux/reducer/authentication-slice";
import {CognitoUser} from "amazon-cognito-identity-js";
import {useTour} from "@reactour/tour";
import {isTourCompleted, markTourAsCompleted} from "../../../../../../utils/storage/tours";
import CandidateAcademicQualificationForm from "./CandidateAcademicQualificationForm";
import {ContactPage} from "@mui/icons-material";
import AgencyDeleteAcademicItem from "./AgencyDeleteAcademicItem";

const AcademicQualification: FC<DocumentListProps> = ({
                                                        documents,
                                                        active,
                                                        academicData,
  isDataLoading = false
                                                      }) => {
  const dispatch = useDispatch();
  const [report, setReport] = useState(false);
  const [documentType, setDocumentType] = useState<"pdf" | "image">();
  const [documentUrl, setDocumentUrl] = useState<string>();
  const [viewDocument, setViewDocument] = useState(false);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [displayTimePeriod, setDisplayTimePeriod] = useState<boolean>(false);
  // const [selectedFile, setSelectedFile] = useState("");
  const {candidate_id, id} = useParams();
  // const [subDocumentId, setSubDocumentId] = useState("");
  // const [inputValues, setInputValues] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };

  const {setIsOpen, currentStep, setCurrentStep} = useTour();

  // useEffect(() => {
  //   if (documents[active].comment) {
  //     setInputValues(documents[active]?.comment || "");
  //   }
  // }, [documents, active]);
  const downloadAllDocuments = async (vetting_id: string) => {
    const session = await Amplify.currentSession();
    const token = session.getIdToken().getJwtToken();
    setIsLoading(true);
    try {
      const {data} = await axios.post(
        `${process.env.REACT_APP_DOWNLOADS_API_URL}${URLS.download_vetting_documents}`,
        {
          request_id: id,
          vetting_document_id: vetting_id,
          candidate_id: candidate_id,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      setIsLoading(false);
      console.log(data);
      window.open(data.download_url, "_self");
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "Unable to download all documents",
        })
      );
    }
  };

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#f5fbff",
      color: "#727272",
      border: "none",
    },
    [`&.${tableCellClasses.body}`]: {
      color: "#263238",
      fontSize: "14px",
      fontWeight: 500,
      padding: "5px 16px",
    },
    [`&.${tableCellClasses.head}:first-of-type`]: {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
    },
    [`&.${tableCellClasses.head}:last-of-type`]: {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      width: "14rem",
      textAlign: "start",
      paddingLeft: "35px",
    },
    [`&.${tableCellClasses.body}:first-of-type`]: {
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
      borderLeft: "1px solid #DADADA",
    },
    [`&.${tableCellClasses.body}:last-of-type`]: {
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
      borderRight: "1px solid #DADADA",
      textAlign: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&": {
      borderRadius: "8px",
      borderTop: "1px solid #DADADA",
      backgroundColor: "white",
    },
  }));

  useEffect(() => {
    let hasOptions: boolean = false;
    documents[active].sub_documents.forEach((document: SubDocument) => {
      if (document.options) {
        hasOptions = true;
      }
    });
    setDisplayTimePeriod(hasOptions);
  }, [active, documents]);

  const download = async (id: string) => {
    setIsLoading(true);
    try {
      const {data} = await axiosInstance.get(`${URLS.document}/${id}`);
      const type =
        data.mimetype.indexOf("pdf") > -1
          ? "pdf"
          : data.mimetype.indexOf("image") > -1
            ? "image"
            : null;
      if (!!type) {
        setDocumentType(type);
        setDocumentUrl(data.url);
        setViewDocument(true);
      } else {
        window.open(data.url, "_blank");
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in downloading document",
        })
      );
    }
  };

  const reportDocs = useCallback(async () => {
    try {
      setIsLoading(true);
      let payload;
      if (documents[active].name !== identifiers.opc) {
        payload = {
          approval_status: "REJECTED",
          vetting_document_id: documents[active].id,
          // sub_document_id: subDocumentId,
          sub_document_id: "",
        };
      } else {
        payload = {
          approval_status: "REJECTED",
          vetting_document_id: documents[active].id,
        };
      }
      await axiosInstance.patch(
        `${URLS.agency_request}${id}/candidate/${candidate_id}`,
        payload
      );
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: "Document reported successfully",
        })
      );
      setIsLoading(false);
      setReport(false);
    } catch (e) {
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in reporting  document",
        })
      );
      setIsLoading(false);
      setReport(false);
    }
  }, [documents, active, id, candidate_id, dispatch]);

  // const submitFeedback = async () => {
  //   setIsLoading(true);
  //   try {
  //     let payload = {
  //       vetting_document_id: documents[active].id,
  //       comment: inputValues,
  //     };
  //
  //     const { status } = await axiosInstance.patch(
  //       `${URLS.agency_request}/${id}/candidate/${candidate_id}/comment`,
  //       payload
  //     );
  //     if (status === 200) {
  //       dispatch(
  //         setNotificationMessage({
  //           display: true,
  //           severity: "success",
  //           message: "Additional comments has been sent successfully",
  //         })
  //       );
  //     }
  //     setIsLoading(false);
  //   } catch (e) {
  //     console.log(e);
  //     dispatch(
  //       setNotificationMessage({
  //         display: true,
  //         severity: "error",
  //         message: "There is an error in saving the Additional comments",
  //       })
  //     );
  //     setIsLoading(false);
  //   }
  // };
  const setCoachMarks = useCallback(
    async (value: any) => {
      //if (!!user && user["custom:reference_tutorial"] !== "true") {
      const currentUser: CognitoUser = await Amplify.UserDetail();
      const body = value;
      await Amplify.UpdateUserDetails(body, currentUser);
      await Amplify.RefreshSession();
      dispatch(setUserData({...user, ...body}));
      //}
    },
    [user, dispatch]
  );

  useEffect(() => {
    if (!!user && user["custom:download_all"] !== "true") {
      if (!!academicData) {
        if (academicData?.length > 0) {
          setCurrentStep(tourStep.candidate_profile_download_all.index);
        }
      }
    }
  }, [user, academicData, setCurrentStep]);

  useEffect(() => {
    //download all docs
    if (tourStep.candidate_profile_download_all.index === currentStep) {
      if (!isTourCompleted('candidate_profile_download_all')) {
        setIsOpen(true);
      }
    } else if (
      currentStep ===
      tourStep.candidate_profile_download_all.index +
      tourStep.candidate_profile_download_all.steps
    ) {
      setIsOpen(false);
      markTourAsCompleted('candidate_profile_download_all');
      if (!!user && user["custom:download_all"] !== "true") {
        (async () => {
          await setCoachMarks({
            "custom:download_all": "true",
          });
        })();
      }
    }
  }, [currentStep, setCoachMarks, setIsOpen, user]);

  const reportModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "20px",
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              textAlign: "center",
              letterSpacing: "0.15px",
            }}
          >
            Report Document?
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            Document will be rejected and Candidate will have to upload a new
            document.
            <br/>
            Are you sure?
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="right"
          justifyContent="right"
          sx={{flexWrap: "noWrap"}}
        >
          <PrimaryButton
            variant={"text"}
            fullWidth={false}
            sx={{
              mt: 1,
              mr: 2,
              background: "white",
              border: "1px solid #5AB9F9",
              color: "#5AB9F9",
              fontSize: 16,
              fontWeight: 500,
              maxHeight: "45px",
            }}
            onClick={reportDocs}
            disabled={isLoading}
          >
            {isLoading && <CircularProgress sx={{mr: 1, height: "20px"}}/>}
            Report Document
          </PrimaryButton>
          <PrimaryButton
            disabled={isLoading}
            fullWidth={false}
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={() => {
              setReport(false);
              // setDocument(null);
            }}
          >
            Cancel
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [isLoading, reportDocs]);

  const viewModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          {documentType === "pdf" && (
            <Document
              file={{url: documentUrl}}
              onLoadSuccess={({numPages}) => setNumPages(numPages)}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1}/>
              ))}
            </Document>
          )}
          {documentType === "image" && (
            <img src={documentUrl} alt={"document"} className={"img-fluid"}/>
          )}
        </Grid>
      </Grid>
    );
  }, [documentType, documentUrl, numPages]);

  const openReportModal = useMemo(() => {
    return (
      <Modal
        open={report}
        setModalClose={setReport}
        children={reportModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [report, reportModalText]);

  const openViewModal = useMemo(() => {
    return (
      <Modal
        open={viewDocument}
        setModalClose={setViewDocument}
        children={viewModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [viewDocument, viewModalText]);
  return (
    <>
      <Grid xs={12} container className="card-vetting">
        {openReportModal}
        {isLoading && <PageLoader/>}
        {openViewModal}
        <Grid container className="card-head-table">
          <Grid item xs={12} md={4} sx={{pl: 1, py: 3}}>
            <Typography
              variant={"body2"}
              fontSize={"16px"}
              fontWeight={"bold"}
              paddingTop={"10px"}
              sx={{pl: {xs: 3, ms: 2}}}
            >
              {documents[active].name}
            </Typography>
          </Grid>
          {documents[active].documents_required !== "N/A" && (
            <>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  py: {xs: 0, md: 3},
                  pl: {xs: 3, md: 0},
                  textAlign: {xs: "left", md: "right"},
                }}
              >
                <Typography
                  variant={"body2"}
                  fontSize={"16px"}
                  fontWeight={"bold"}
                  paddingTop={"10px"}
                >
                  Number of Documents required for this section
                </Typography>
              </Grid>
              <Grid item xs={12} md={2} sx={{px: 2, py: 1}}>
                <FormControl
                  sx={{minWidth: 25, px: 1, paddingY: "8px", width: "100%"}}
                >
                  <InputField
                    inputValue={documents[active].documents_required}
                    size={"small"}
                    className="headerTimePeriod mt-2"
                    disabled={true}
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
        <Grid xs={12} item md={12}>
          <Grid
            item
            md={12}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            sx={{ position: 'relative' }}
          >
            <>
              {isDataLoading &&
                <Stack alignItems={"center"} justifyContent={"center"} sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  bottom: 0,
                  top: 0,
                  zIndex: 9,
                  background: '#fdfdfd87',
                  backdropFilter: 'blur(0.51px)'
                }}>
                  <CircularProgress size={24}/>
                </Stack>
              }
              {academicData?.length === 0 || academicData === undefined ? (
                <Grid item md={12}
                      minHeight={"200px"}
                      textAlign={"center"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      direction={"column"}
                      gap={"1rem"}
                >
                  <img
                    src={images.no_data_available}
                    width="100%"
                    height="100px"
                    style={{objectFit: "contain"}}
                    alt={"No ReferenceForms"}
                  />
                  <Typography variant="h6" fontSize="14px" fontWeight="400">
                    No Documents Uploaded
                  </Typography>
                </Grid>
              ) : (
                <>
                  <TableContainer
                    component={Paper}
                    sx={{boxShadow: "none", width: "90%"}}
                  >
                    <Table
                      sx={{
                        minWidth: 700,
                        borderSpacing: "0 1rem",
                        borderCollapse: "separate",
                        backgroundColor: "#f5fbff",
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell sx={{minWidth: "150px"}}>
                            Document Name
                          </StyledTableCell>
                          {displayTimePeriod && (
                            <StyledTableCell>Time Period</StyledTableCell>
                          )}
                          <StyledTableCell sx={{minWidth: "111px"}}>
                            {" "}
                            Upload Date
                          </StyledTableCell>
                          <StyledTableCell>Action</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={"documents-table-body"}>
                        {academicData?.map(
                          (document: AcademicDocument, index) => (
                            <>
                              <StyledTableRow
                                className={"documents-table-body-row"}
                                key={index}
                              >
                                <StyledTableCell>
                                  {document?.document_name}

                                  {document?.agency_upload &&
                                  <Stack gap={"0.3rem"} direction={"row"} alignItems={"center"}>
                                    <ContactPage sx={{color: "#4a73c2", fontSize: '12px'}}/>
                                    <Typography
                                      sx={{color: "#4a73c2", fontSize: '12px'}}
                                    >
                                      Uploaded by: <strong>Compliance Officer</strong>
                                    </Typography>
                                  </Stack>
                                  }
                                </StyledTableCell>

                                <StyledTableCell sx={{position: "relative"}}>
                                  {" "}
                                  {dayjs(
                                    new Date(
                                      document?.graduation_date
                                        ? document?.graduation_date
                                        : ""
                                    )
                                  ).format(identifiers.date_format as string)}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Grid
                                    item
                                    display={"flex"}
                                    flexWrap={"nowrap"}
                                    md={6}
                                  >
                                    <PrimaryButton
                                      onClick={() =>
                                        download(document.document_repo_id)
                                      }
                                      sx={{
                                        cursor: "pointer",
                                      }}
                                      variant={"text"}
                                      fullWidth={false}
                                    >
                                      <img
                                        src={images.viewIcon}
                                        alt="view"
                                        className="action-icons"
                                      />
                                    </PrimaryButton>
                                    <AgencyDeleteAcademicItem  id={document.id} candidate_id={candidate_id} request_id={id}/>
                                  </Grid>
                                </StyledTableCell>
                              </StyledTableRow>
                            </>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid
                    container
                    className="card-footer-table pt-2 pb-2"
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    <Grid item md={6} data-tut="download_all_btn">
                      <PrimaryButton
                        fullWidth={true}
                        sx={{
                          mt: 1,
                          background: "white",
                          border: "1px solid #5AB9F9",
                          color: "#5AB9F9",
                          fontSize: 16,
                          fontWeight: 500,
                        }}
                        onClick={async () => {
                          await downloadAllDocuments(documents[active].id);
                        }}
                      >
                        Download All
                      </PrimaryButton>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          </Grid>
        </Grid>
      </Grid>


      <CandidateAcademicQualificationForm active={active}/>

    </>

  );
};

export default AcademicQualification;
