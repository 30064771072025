import React, {
  ChangeEvent,
  FC,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "../../../../components/Button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import {
  ReferenceData,
  setNotificationMessage,
  setReferenceData,
} from "../../../../utils/redux";
import InputField from "../../../../components/InputField";
import { routes } from "../../../../utils/constants/routes";
import Modal from "../../../../components/modal/Modal";
import axiosInstance from "../../../../utils/axios";
import { URLS } from "../../../../utils/constants/urls";
import { images } from "../../../../utils/constants/images";
import mime from "mime";
import axios from "axios";
import { identifiers } from "../../../../utils/constants/identifiers";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../utils/constants/coachMarks";

const AdditionalComments: FC = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { id } = useParams();
  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  const reference = useSelector(ReferenceData);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [email, setEmail] = useState<boolean>();
  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!reference.referee_additional_comments) {
      setCurrentStep(tourStep.referee_additional_comments.index);
    }
  }, [reference, setCurrentStep]);

  useEffect(() => {
    if (tourStep.referee_additional_comments.index === currentStep) {
      setIsOpen(true);
    } else {
      dispatch(
        setReferenceData({ ...reference, referee_additional_comments: true })
      );
    }
  }, [currentStep, dispatch, reference, setIsOpen]);

  const setComments = (value: string) => {
    dispatch(
      setReferenceData({
        ...reference,
        referee_comments: value,
      })
    );
  };

  const submitReference = useCallback(
    async (uploadedFileIds: string[]) => {
      try {
        const body = {
          referee_details: reference.referee_details,
          ked_questionnaire_value:
            reference.available_routes.indexOf(
              identifiers.ked_questionnaire as string
            ) > -1
              ? reference.ked_questionnaire
              : undefined,
          referee_comments: reference.referee_comments,
          gap_reference: reference.is_gap_reference,
          email_referee_pdf: email,
          additional_questionnaire_value:
            reference.additional_questionnaire_forms,
          reference_form_documents: uploadedFileIds,
          skip_questionnaire: false
        };

        if(reference.referee_document_key){
          body.skip_questionnaire = !reference.is_referee_declined_doc;
        }
        if(reference.referee_uploaded_doc_key){
          body.skip_questionnaire = true;
        }

        await axiosInstance.put(`${URLS.referee_reference}/${id}`, body, {
          noAuth: true,
        } as any);
        setLoading(false);
        dispatch(
          setReferenceData({
            ...reference,
            completed: true,
          })
        );
        setStep(1);
      } catch (e) {
        setLoading(false);
        console.log(e);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "There is an error in setting email value",
            autoHideDuration: 6000,
          })
        );
      }
    },
    [dispatch, email, id, reference]
  );

  const uploadFile = useCallback(
    async (file: File, url: string, mime: string) => {
      try {
        const { status } = await axios.put(url, file, {
          headers: { "Content-Type": mime },
        });
        if (status === 200) {
          return true;
        }
      } catch (e) {
        setLoading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "There is an error in uploading document",
          })
        );
      }
    },
    [dispatch]
  );

  const uploadReferenceFiles = useCallback(async () => {
    setLoading(true);
    const uploadedFileIds: string[] = [];
    if (!!reference.reference_document_forms) {
      for (let reference_file of reference.reference_document_forms) {
        const selected_file: File = reference_file.selected_file as File;
        const file_name = selected_file.name.split(".");
        const extension = file_name.splice(
          selected_file.name.split(".").length - 1,
          1
        );
        const referenceForm: {
          candidate_reference_id: string | undefined;
          reference_form_id: string;
          type: string;
          file_name: string;
        } = {
          candidate_reference_id: id,
          reference_form_id: reference_file.id,
          type: mime.getType(selected_file.name)
            ? (mime.getType(selected_file.name) as string)
            : selected_file.type,
          file_name: `${new Date().getTime()}_${file_name.join(
            " "
          )}.${extension}`,
        };
        try {
          const { data } = await axiosInstance.post(
            URLS.referee_file_upload,
            referenceForm,
            { noAuth: true } as any
          );
          await uploadFile(
            selected_file,
            data.url,
            mime.getType(selected_file.name)
              ? (mime.getType(selected_file.name) as string)
              : selected_file.type
          );
          uploadedFileIds.push(data.id);
        } catch (e: any) {
          setLoading(false);
          dispatch(
            setNotificationMessage({
              display: true,
              severity: "error",
              message: `Unable to upload ${selected_file.name}`,
            })
          );
          console.log(e);
        }
      }
    }
    await submitReference(uploadedFileIds);
  }, [
    dispatch,
    id,
    reference.reference_document_forms,
    submitReference,
    uploadFile,
  ]);

  const emailModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item xs={12}>
          {step === 0 && (
            <Stack>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant={"body2"}
                  textAlign={"center"}
                  fontWeight={500}
                >
                  Would you like to receive a copy of the reference you just
                  filled?
                </Typography>
                <img
                  onClick={() => {
                    setOpenModal(false);
                  }}
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    width: "20px",
                    cursor: "pointer",
                  }}
                  src={images.closeBtn}
                  alt={"close button"}
                />
              </Box>
              <Box sx={{ mt: 1 }} textAlign={"center"}>
                <Radio
                  value={true}
                  checked={email === true}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setEmail(event.target.value === "true")
                  }
                />
                <Typography
                  variant={"body1"}
                  display={"inline-block"}
                  sx={{ pr: 2 }}
                >
                  Yes
                </Typography>
                <Radio
                  value={false}
                  checked={email === false}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setEmail(event.target.value === "true")
                  }
                />
                <Typography variant={"body1"} display={"inline-block"}>
                  No
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }} textAlign={"center"}>
                <PrimaryButton
                  variant={"contained"}
                  sx={{ width: "200px", color: "#ffffff" }}
                  onClick={uploadReferenceFiles}
                  disabled={loading}
                >
                  {loading && (
                    <CircularProgress
                      sx={{ mr: 1, color: "white" }}
                      size={30}
                    />
                  )}
                  Submit
                </PrimaryButton>
              </Box>
            </Stack>
          )}
          {step === 1 && (
            <Stack alignItems={"center"}>
              <img
                src={images.thumbsUp}
                alt={"thumbs up"}
                style={{ height: "80px", width: "80px", marginBottom: "8px" }}
              />
              {email ? (
                <Stack>
                  <Typography
                    variant={"body2"}
                    textAlign={"center"}
                    sx={{ mb: 1 }}
                  >
                    Thank you for taking the time to fill out this reference.
                  </Typography>
                  <Typography
                    variant={"body2"}
                    textAlign={"center"}
                    sx={{ mb: 1 }}
                  >
                    Please expect an email in your inbox within this week which
                    will have a copy of your completed reference.
                  </Typography>
                </Stack>
              ) : (
                <Stack>
                  <Typography
                    variant={"body2"}
                    textAlign={"center"}
                    sx={{ mb: 1 }}
                  >
                    Thank you!
                  </Typography>
                  <Typography
                    variant={"body2"}
                    textAlign={"center"}
                    sx={{ mb: 1 }}
                  >
                    Your reference has been received!
                  </Typography>
                  <Typography
                    variant={"body2"}
                    textAlign={"center"}
                    sx={{ mb: 1 }}
                  >
                    Thank you for taking the time to fill out this reference.
                  </Typography>
                </Stack>
              )}
              <Box sx={{ mt: 1 }} textAlign={"center"}>
                <PrimaryButton
                  variant={"contained"}
                  sx={{ width: "200px", color: "#ffffff" }}
                  onClick={() => Navigate("../")}
                >
                  Back to Site
                </PrimaryButton>
              </Box>
            </Stack>
          )}
        </Grid>
      </Grid>
    );
  }, [Navigate, email, loading, step, uploadReferenceFiles]);

  const openEmailModal = useMemo(() => {
    return (
      <Modal
        open={openModal}
        setModalClose={setOpenModal}
        children={emailModalText}
        title={undefined}
        size={"xs"}
        className={undefined}
        isBackdrop={true}
      />
    );
  }, [openModal, emailModalText]);

  return (
    <Grid
      container
      display={"flex"}
      alignItems={{ xs: "start", md: "center" }}
      justifyContent={"center"}
      sx={{ minHeight: "calc(100vh - 120px)" }}
    >
      {openEmailModal}
      <Grid
        item
        xs={10}
        lg={8}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              fontWeight={"500"}
              fontSize={"14px"}
              sx={{ mb: 2 }}
            >
              Additional Comments
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <InputField
            data-tut="additional_coments_field"
            inputValue={reference.referee_comments}
            textChange={setComments}
            multiline
            sx={{ background: "white" }}
            rows={5}
          />
        </Grid>
        <Grid
          container
          sx={{
            position: { xs: "fixed", sm: "fixed", md: "relative" },
            bottom: "0px",
            left: "0px",
            width: "100%",
            zIndex: "99",
            textAlign: { xs: "center", md: "right" },
            background: { xs: "white", md: "transperant" },
            boxShadow: { xs: "0px -4px 7px #e6e6e6", md: "none" },
            borderRadius: { xs: "15px 15px 0px 0px", md: "none" },
          }}
        >
          <Grid
            item
            textAlign={"center"}
            xs={12}
            display={"flex"}
            justifyContent={"space-evenly"}
            sx={{
              py: 1,
              px: 3,
              mt: { xs: "10px", md: "20px" },
            }}
          >
            <PrimaryButton
              fullWidth={false}
              sx={{
                background: "#FFFFFF",
                border: " 1px solid #5AB9F9",
                borderRadius: "5px",
                padding: "4px 44px",
                color: "#5AB9F9",
                fontSize: "14px",
                // margin: "0px 20px",
              }}
              onClick={() => {
                const active_index = reference.active_index;
                dispatch(
                  setReferenceData({
                    ...reference,
                    active_index: active_index - 1,
                  })
                );
                Navigate(
                  `../${routes[reference.available_routes[active_index - 1]]}`
                );
              }}
            >
              Go Back
            </PrimaryButton>
            <PrimaryButton
              fullWidth={false}
              sx={{
                background: "#5AB9F9",
                boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
                borderRadius: "5px",
                padding: "4px 30px",
                color: "white",
              }}
              disabled={!reference.referee_comments || step === 1}
              onClick={() => setOpenModal(true)}
              data-tut="additional_comments_procedd_btn"
            >
              Proceed
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdditionalComments;
