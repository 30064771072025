import FromDevice
  from "../../../Authenticated/Candidate/ViewRequest/VettingDocuments/DocumentList/common/UploadFiles/FromDevice";
import {Typography, Stack, CircularProgress} from "@mui/material";
import React, {FC, useEffect, useState} from "react";
import * as Amplify from "../../../../utils/services/amplify";
import axios from "axios";

const RefereeUploadDocument: FC<{
  reference_id: string | null,
  onComplete: (key: string) => void;
  onLoading: () => void;
}> = ({
        reference_id,
        onComplete,
  onLoading,
      }) => {
  const [files, setFiles] = useState<File[]>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    console.log(reference_id)
    const handleFileUpload = async () => {
      if (files && files.length > 0) {
        setLoading(true);
        onLoading()
        try {
          const file: File = files[0];

          const formRequestData = new FormData();

          const session = await Amplify.currentSession();
          const token = session.getIdToken().getJwtToken();

          formRequestData.append("files", file, file.name);
          formRequestData.append("doc_type", 'referee_upload');

          const {data} = await axios.post(
            `${process.env.REACT_APP_NODE_V3_API_URL}/authenticated/referee-document/${reference_id}`,
            formRequestData,
            {
              headers: {
                Authorization: `${token}`
              }
            }
          );
          if (data.key) {
            onComplete(data.key)
          }


        } catch (error) {
          // Handle errors (e.g., failed session retrieval or file upload)
          console.error('Error during file upload or session retrieval:', error);
        } finally {
          // Always set loading state to false when done
          setLoading(false);
        }
      }
    };

    // Call the async function
    handleFileUpload();
  }, [files, reference_id]);

  return (
    <Typography
      variant="h6"
      fontWeight={"500"}
      fontSize={"14px"}
    >
      <Stack sx={{ position: 'relative'}}>

        {loading &&
          <Stack sx={{
            background: 'rgba(255,255,255,0.6)',
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 2
          }}>
            <CircularProgress size={32} />
          </Stack>
        }
        <FromDevice files={files} setFiles={setFiles}/>

      </Stack>
    </Typography>
  )
}
export default RefereeUploadDocument
