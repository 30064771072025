import React, {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CircularProgress,
  Grid,
  Typography,
  Box,
  Button,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  tableCellClasses,
  styled,
  TableCell,
  Stack,
  TablePagination,
  TableFooter,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import "../RequestDetails.scss";
import { images } from "../../../../../utils/constants/images";
import SearchIcon from "@mui/icons-material/Search";
import CheckBox from "../../../../../components/CheckBox";
import InputField from "../../../../../components/InputField";
import { DocumentModel } from "../../DocumentRepository/DocumentRepositoryModel";
import mime from "mime";
import { URLS } from "../../../../../utils/constants/urls";
import axios from "axios";
import axiosInstance from "../../../../../utils/axios";
import { identifiers } from "../../../../../utils/constants/identifiers";
import dayjs from "dayjs";
import PrimaryButton from "../../../../../components/Button/PrimaryButton";
import Modal from "../../../../../components/modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  IsEditable,
  setAdditionalForms,
  setNotificationMessage,
} from "../../../../../utils/redux";
import { useParams } from "react-router-dom";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import PageLoader from "../../../../../components/PageLoader";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Document, Page } from "react-pdf";
import { debounce } from "lodash";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { Validators } from "../../../../../utils/validators";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../../utils/constants/coachMarks";
import {
  User,
  setUserData,
} from "../../../../../utils/redux/reducer/authentication-slice";
import * as Amplify from "../../../../../utils/services/amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { isTourCompleted, markTourAsCompleted } from "../../../../../utils/storage/tours";

const LinearProgressWithLabel: FC<
  { value: number; reqNumber: number } & LinearProgressProps
> = ({ value, reqNumber, ...props }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          color="success"
          variant="determinate"
          {...props}
          value={(value / reqNumber) * 100}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F5FBFF",
    color: "#727272",
    border: "none",
    maxWidth: "300px",
    width: "300px",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    color: "#263238",
    fontSize: "14px",
    fontWeight: 600,
  },
  [`&.${tableCellClasses.head}:first-of-type`]: {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    maxWidth: "1em",
    width: "1em",
  },
  [`&.${tableCellClasses.head}:nth-of-type(2)`]: {
    maxWidth: "200px",
    width: "200px",
  },
  [`&.${tableCellClasses.head}:nth-of-type(5)`]: {
    maxWidth: "200px",
    width: "200px",
  },
  [`&.${tableCellClasses.head}:nth-of-type(6)`]: {
    maxWidth: "180px",
    width: "180px",
  },
  [`&.${tableCellClasses.head}:last-of-type`]: {
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    maxWidth: "3em",
    width: "3em",
  },
  [`&.${tableCellClasses.body}:first-of-type`]: {
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
  },
  [`&.${tableCellClasses.body}:last-of-type`]: {
    borderTopRightRadius: "12px",
    borderBottomRightRadius: "12px",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&": {
    backgroundColor: "#f9fbfc",
    paddingBottom: "15px",
    paddingTop: "15px",
  },
}));

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "document_name_low_search",
    numeric: true,
    disablePadding: false,
    label: "Document Name",
  },
  {
    id: "uploaded_on",
    numeric: true,
    disablePadding: false,
    label: "Upload Date",
  },
];

interface EnhancedTableProps {
  order: boolean;
  orderBy: string;
  onRequestSort: (property: string) => void;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { orderBy, order, onRequestSort } = props;

  const createSortHandler = (property: string) => {
    onRequestSort(property);
  };

  return (
    <StyledTableRow>
      <StyledTableCell></StyledTableCell>
      {headCells.map((headCell) => (
        <StyledTableCell
          key={headCell.id}
          align={headCell.numeric ? "left" : "right"}
          padding={headCell.disablePadding ? "none" : "normal"}
          sortDirection={order ? "asc" : "desc"}
        >
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={order ? "asc" : "desc"}
            onClick={() => createSortHandler(headCell.id)}
          >
            {headCell.label}
            {orderBy === headCell.id && (
              <Box component="span" sx={visuallyHidden}>
                {order ? "sorted ascending" : "sorted descending"}
              </Box>
            )}
          </TableSortLabel>
        </StyledTableCell>
      ))}
      <StyledTableCell>Action</StyledTableCell>
    </StyledTableRow>
  );
}

interface AdditionalFormsProps {
  forms: string[];
}

const AdditionalForms: FC<AdditionalFormsProps> = ({ forms }) => {
  const theme = useTheme();
  const isDekstop = useMediaQuery(theme.breakpoints.up("md"));

  const { setIsOpen, currentStep, setCurrentStep } = useTour();
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoading, setisLoading] = useState<boolean>();
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };
  const dispatch = useDispatch();
  const { id } = useParams() as any;
  const fileUploadRef = useRef<any>(null);
  const [documentName, setDocumentName] = useState<string>("");
  const [hasError, setHasError] = useState<boolean>(false);
  const [documents, setDocuments] = useState<DocumentModel[]>([]);
  const [viewDelete, setViewDelete] = useState(false);
  const [document, setDocument] = useState<DocumentModel | null>(null);
  const isEditable = useSelector(IsEditable);
  const [isUploading, setIsUploading] = useState(false);
  const [percentageProcess, setPercentageProcess] = useState(0);
  const [opacity, setOpacity] = useState("1");
  const [documentType, setDocumentType] = useState<"pdf" | "image">();
  const [documentUrl, setDocumentUrl] = useState<string>();
  const [viewDocument, setViewDocument] = useState(false);
  const [viewSigner, setViewSigner] = useState(false);
  const [selected, setSelected] = useState("both");
  const [numPages, setNumPages] = useState<number | null>(null);
  const [filter, setFilter] = useState<{
    search: string;
    index: number;
    page_size: number;
    delay: number;
    name: string;
    sort: boolean;
  }>({
    search: "",
    index: 0,
    page_size: 10,
    delay: 0,
    name: "uploaded_on",
    sort: false,
  });

  const setAdditionalFormsCoachMarks = useCallback(
    async (value: any) => {
      //if (!!user && user["custom:reference_tutorial"] !== "true") {
      const currentUser: CognitoUser = await Amplify.UserDetail();
      const body = value;
      await Amplify.UpdateUserDetails(body, currentUser);
      await Amplify.RefreshSession();
      dispatch(setUserData({ ...user, ...body }));
      //}
    },
    [user, dispatch]
  );

  useEffect(() => {
    if (isLoading !== undefined && !isLoading) {
      if (!!user && user["custom:additional_forms"] !== "true") {
        setCurrentStep(tourStep.additional_forms.index);
      }
    }
  }, [user, isLoading, setCurrentStep]);

  useEffect(() => {
    if (!loading) {
      if (tourStep.additional_forms.index === currentStep) {
        if (!isTourCompleted('additional_forms')) {
          setIsOpen(true);
        }
      } else if (
        currentStep ===
        tourStep.additional_forms.index + tourStep.additional_forms.steps
      ) {
        setIsOpen(false);
        markTourAsCompleted('additional_forms');
        if (!!user && user["custom:additional_forms"] !== "true") {
          (async () => {
            await setAdditionalFormsCoachMarks({
              "custom:additional_forms": "true",
            });
          })();
        }
        if (!!user && user["custom:additional_set_signer"] !== "true") {
          if (documents?.length > 0) {
            setTimeout(() => {
              setCurrentStep(tourStep.set_signer.index);
            }, 1000);
          }
        }
      }

      if (tourStep.set_signer.index === currentStep) {
        if (!isTourCompleted('set_signer')) {
          setIsOpen(true);
        }
      } else if (
        currentStep ===
        tourStep.set_signer.index + tourStep.set_signer.steps
      ) {
        setIsOpen(false);
        markTourAsCompleted('set_signer');
        if (!!user && user["custom:additional_set_signer"] !== "true") {
          (async () => {
            await setAdditionalFormsCoachMarks({
              "custom:additional_set_signer": "true",
            });
          })();
        }
      }
    }
  }, [
    currentStep,
    documents?.length,
    isLoading,
    loading,
    setAdditionalFormsCoachMarks,
    setCurrentStep,
    setIsOpen,
    user,
  ]);

  const handleRequestSort = (property: string) => {
    setFilter((prevState) => ({
      ...prevState,
      index: 0,
      delay: 0,
      name: property,
      sort: prevState.name === property ? !prevState.sort : true,
    }));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter((prevState) => ({ ...prevState, index: newPage, delay: 0 }));
  };

  const getAdditionalForms = async (
    search: string,
    name: string,
    sort: boolean
  ) => {
    const searchQuery = !!search && `&keyword=${search}`;
    const sortColumn = !!name && `&asc_sort=${sort}&sort_column=${name}`;
    let page_key = null;
    const document_list: DocumentModel[] = [];

    setisLoading(true);
    try {
      do {
        const { data }: { status: number; data: any } =
          await axiosInstance.post(
            `${URLS.agency_request}${id}/document?page_size=${filter.page_size
            }${sortColumn || ""}${searchQuery || ""}`,
            page_key
          );
        setisLoading(false);
        document_list.push(...data.data);
        if (!!page_key) {
          setDocuments((prevState) => [...prevState, ...data.data]);
        } else {
          setDocuments(data.data);
        }
        page_key = JSON.parse(data.page_key);
      } while (!!page_key && page_key !== "null");
      setDocuments(
        document_list.sort((a, b) => {
          if (!!sortColumn) {
            if (name === "uploaded_on") {
              if (sort) {
                return a.uploaded_on - b.uploaded_on;
              } else {
                return b.uploaded_on - a.uploaded_on;
              }
            } else {
              if (sort) {
                return a.document_name < b.document_name ? -1 : 1;
              } else {
                return b.document_name < a.document_name ? -1 : 1;
              }
            }
          } else {
            return b.uploaded_on - a.uploaded_on;
          }
        })
      );
    } catch (e: any) {
      setisLoading(false);
      if (await Auth.currentUserInfo()) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "Unable to Get List of ReferenceForms",
          })
        );
      }
    }
  };

  const delayedQuery = useRef(
    debounce(getAdditionalForms, filter.delay)
  ).current;

  useEffect(() => {
    (async () => {
      await delayedQuery(filter.search, filter.name, filter.sort);
    })();
  }, [delayedQuery, filter.search, filter.name, filter.sort, filter]);

  const setSearch = async (search: string) => {
    setFilter((prevState) => ({ ...prevState, index: 0, delay: 500, search }));
  };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    setIsUploading(true);
    if (event.target.files) {
      let max_size = identifiers.agency_max_file_size as number;
      if (event.target.files[0].size <= max_size) {
        setLoading(true);
        const file: File = event.target.files[0];
        let file_name = file.name.split(".");
        // file_name.slice(file.name.split(".").length - 1, 1);
        const fileName = file_name.slice(0, -1).join("");
        const extention = file_name.slice(-1).join();
        const additionalForm: {
          name: string;
          type: string;
          directory: string;
          file_name: string;
          document_type: string;
        } = {
          type: mime.getType(file.name)
            ? (mime.getType(file.name) as string)
            : file.type,
          name: `${documentName}_${new Date().getTime()} ${fileName}.${extention}`,
          file_name: documentName,
          directory: "documents",
          document_type: "document",
        };
        try {
          const { data } = await axiosInstance.post(
            `${URLS.file_upload}`,
            additionalForm
          );
          if (fileUploadRef.current) {
            fileUploadRef.current.value = null;
          }
          await uploadFile(
            file,
            data.url,
            mime.getType(file.name)
              ? (mime.getType(file.name) as string)
              : file.type
          );
        } catch (e: any) {
          setIsUploading(true);
          setLoading(false);
          if (fileUploadRef.current) {
            fileUploadRef.current.value = null;
          }
          if (e.response.status === 409) {
            dispatch(
              setNotificationMessage({
                display: true,
                severity: "error",
                message: `File with name ${documentName} already exists`,
              })
            );
          } else {
            dispatch(
              setNotificationMessage({
                display: true,
                severity: "error",
                message: "Unable to Upload File",
              })
            );
          }
        }
      } else {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: `You cannot upload file more than ${identifiers.maxFileSizeText}`,
          })
        );
        if (fileUploadRef.current) {
          fileUploadRef.current.value = null;
        }
      }
    }
  };

  const uploadFile = async (file: File, url: string, mime: string) => {
    setOpacity("0.6");
    try {
      const { status } = await axios.put(url, file, {
        headers: { "Content-Type": mime },
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total || 100)
          );
          setPercentageProcess(percentCompleted);
        },
      });
      if (status === 200) {
        setOpacity("1");
        setIsUploading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "success",
            message: `File ${documentName} has been uploaded Successfully`,
          })
        );
        if (fileUploadRef.current) {
          fileUploadRef.current.value = null;
        }
        setDocumentName("");
        setLoading(false);
        setTimeout(
          () => setFilter((prevState) => ({ ...prevState, delay: 0 })),
          5000
        );
      }
    } catch (e) {
      setOpacity("1");
      setIsUploading(false);
      setLoading(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "Unable to Upload File",
        })
      );
    }
  };

  const handleUpload = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!!documentName) {
      if (fileUploadRef.current) {
        fileUploadRef.current.click();
      }
    } else {
      setHasError(true);
    }
  };

  const setSigner = useCallback(async () => {
    try {
      setLoading(true);
      await axiosInstance.patch(`${URLS.document}/${document?.id}`, {
        signer: selected.toLowerCase(),
      });
      setFilter((prevState) => ({ ...prevState, delay: 0 }));
      setLoading(false);
      setViewSigner(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in updating signature",
        })
      );
    }
  }, [document?.id, selected, dispatch]);

  const setName = (
    value: string,
    field: string,
    error?: { error: boolean; message: string }
  ) => {
    setDocumentName(value);
    if (!!error) {
      setHasError(error.error);
    } else {
      setHasError(!!error as boolean);
    }
  };

  const handleForm = (id: string) => {
    const selectedForms = JSON.parse(JSON.stringify(forms));
    if (forms.indexOf(id) > -1) {
      selectedForms.splice(forms.indexOf(id), 1);
    } else {
      selectedForms.push(id);
    }
    dispatch(setAdditionalForms(selectedForms));
  };

  const download = async (id: string, view: boolean) => {
    try {
      setisLoading(true);
      const { data } = await axiosInstance.get(`${URLS.document}/${id}`);
      const type =
        data.mimetype.indexOf("pdf") > -1
          ? "pdf"
          : data.mimetype.indexOf("image") > -1
            ? "image"
            : null;
      setisLoading(false);
      if (!!type && view) {
        setDocumentType(type);
        setDocumentUrl(data.url);
        setViewDocument(true);
      } else {
        window.open(data.url, "_blank");
      }
    } catch (e) {
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in downloading",
        })
      );
    }
  };

  const signerFile = async (selectedDocument: DocumentModel) => {
    setDocument(selectedDocument);
    setSelected(selectedDocument.envelope_signers.toLocaleLowerCase());
    setViewSigner(true);
  };

  const deleteFile = async (selectedDocument: DocumentModel) => {
    if (isEditable) {
      setDocument(selectedDocument);
      setViewDelete(true);
    }
  };

  const confirmDelete = useCallback(async () => {
    try {
      setLoading(true);
      await axiosInstance.delete(
        `${URLS.document}/${document?.id}?type=document`
      );
      setViewDelete(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: `File ${document?.document_name} has been deleted Successfully`,
        })
      );
      setDocument(null);
      setLoading(false);
      setFilter((prevState) => ({ ...prevState, delay: 5000 }));
    } catch (e) {
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in deleting document",
        })
      );
    }
  }, [dispatch, document?.document_name, document?.id]);

  const deleteModal = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "20px",
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              textAlign: "center",
              letterSpacing: "0.15px",
            }}
          >
            Delete Document Permanently
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            {document?.document_name} will be deleted permanently from the
            repository.
            <br />
            Are you sure?
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="right"
          justifyContent="right"
          flexWrap={"nowrap"}
        >
          <PrimaryButton
            sx={{
              border: "none",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
              mr: 2,
              mt: 1,
              p: "10px",
              minWidth: "180px",
            }}
            disabled={loading}
            fullWidth={false}
            onClick={confirmDelete}
          >
            {loading && (
              <CircularProgress sx={{ mr: 1, color: "white" }} size={20} />
            )}
            Delete Document
          </PrimaryButton>
          <PrimaryButton
            disabled={loading}
            fullWidth={false}
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={() => {
              setViewDelete(false);
              setDocument(null);
            }}
          >
            Cancel
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [confirmDelete, document?.document_name, loading]);

  const viewSignerText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "20px",
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              letterSpacing: "0.15px",
            }}
          >
            Document Signer
          </Typography>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={selected}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSelected(
                  event.target.value as
                  | "COMPLIANCE_OFFICER"
                  | "CANDIDATE"
                  | "BOTH"
                );
              }}
            >
              <FormControlLabel
                value="compliance_officer"
                control={<Radio />}
                label="Compliance Officer"
              />
              <FormControlLabel
                value="candidate"
                control={<Radio />}
                label="Applicant"
              />
              <FormControlLabel value="both" control={<Radio />} label="Both" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid container alignItems="right" justifyContent="right">
          <Button
            sx={{
              border: "none",
              color: "#727272",
              fontSize: 16,
              fontWeight: 500,
              mr: 2,
              mt: 1,
              p: "10px",
            }}
            disabled={loading}
            onClick={setSigner}
          >
            {loading && (
              <CircularProgress sx={{ mr: 1, color: "#5AB9F9" }} size={30} />
            )}
            Submit
          </Button>
          <PrimaryButton
            disabled={loading}
            fullWidth={false}
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={() => {
              setViewSigner(false);
            }}
          >
            Cancel
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [setSigner, selected, loading]);

  const viewModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          {documentType === "pdf" && (
            <Document
              file={{ url: documentUrl }}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          )}
          {documentType === "image" && (
            <img src={documentUrl} alt={"document"} className={"img-fluid"} />
          )}
        </Grid>
      </Grid>
    );
  }, [documentType, documentUrl, numPages]);

  const openDeleteModal = useMemo(() => {
    return (
      <Modal
        open={viewDelete}
        setModalClose={setViewDelete}
        children={deleteModal}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [viewDelete, deleteModal]);

  const openSignerModal = useMemo(() => {
    return (
      <Modal
        open={viewSigner}
        setModalClose={setViewSigner}
        children={viewSignerText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [viewSigner, viewSignerText]);

  const openViewModal = useMemo(() => {
    return (
      <Modal
        open={viewDocument}
        setModalClose={setViewDocument}
        children={viewModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [viewDocument, viewModalText]);

  return (
    <Grid container>
      {isLoading && <PageLoader />}
      {openDeleteModal}
      {openViewModal}
      {openSignerModal}
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        padding="0"
        className="mob-card text-center pt-0"
      >
        <Grid item md={12} className=" card-heading-right mb-1 ">
          Upload Documents
        </Grid>
        <Grid xs={12} className="dotted-card content-card">
          <Typography variant={"body1"} color={"#727272"} sx={{ mb: 1 }}>
            Document Name
          </Typography>
          <InputField
            placeholder={"Type Here..."}
            size={"small"}
            sx={{
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              background: "#F6F7FB",
              fontSize: "12px",
              borderRadius: "5px",
              mb: 3,
            }}
            variant={"outlined"}
            inputValue={documentName}
            textChange={setName}
            validators={[
              {
                check: Validators.required,
                message: identifiers.field_error as string,
              },
              {
                check: Validators.FileName,
                message:
                  "File name can only contain Alpha-Numeric Characters, underscores and dashes",
              },
            ]}
            // hasError={hasError}
            // fieldError={identifiers.field_error as string}
            disabled={!isEditable}
          />
          <PrimaryButton
            variant={"contained"}
            className={"upload-button"}
            onClick={handleUpload}
            fullWidth={false}
            data-tut={!isDekstop ? "choose_file_additional_forms" : ""}
            disabled={isUploading || hasError || !isEditable}
          >
            {/* {isUploading && <CircularProgress sx={{ mr: 1, color: "white" }} />}{" "} */}
            Choose File
          </PrimaryButton>
          <input
            ref={fileUploadRef}
            hidden
            accept={identifiers.accept as string}
            size={identifiers.maxFileSize as number}
            type="file"
            onChange={handleFileUpload}
          />
          <p className="instructions mt-4">
            {/*or drag file in here <br />*/}
            accepted file .doc, .docx and .pdf (max size {identifiers.maxFileSizeText})
          </p>
        </Grid>
        {isUploading && (
          <Grid
            className="dotted-card pt-2 text-start pb-2 "
            sx={{ mt: 2 }}
            width={"100%"}
            md={8}
          >
            {documentName}
            <LinearProgressWithLabel
              variant="determinate"
              value={percentageProcess}
              reqNumber={100}
            />
          </Grid>
        )}
      </Grid>
      <Grid xs={12} md={3} className="mb-2 ml-0 ml-md-3" sx={{ mt: 2, padding: { xs: '1rem', md: 0 } }}>
        <InputField
          sx={{ marginLeft: { xs: 0, md: '1rem' } }}
          placeholder="Search Document..."
          value={filter.search}
          textChange={setSearch}
          inputProps={{
            sx: {
              p: 1,
            },
          }}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
        />
      </Grid>
      {/*<Grid xs={3} md={1} sx={{ ml: { xs: 1, md: 2 }, mt: 2 }}>*/}
      {/*  <FormControl fullWidth size={"small"} sx={{ border: "none" }}>*/}
      {/*    <Select*/}
      {/*      value={"Latest"}*/}
      {/*      sx={{ height: 38, py: 1, borderColor: "#dadada" }}*/}
      {/*      // disabled*/}
      {/*    >*/}
      {/*      <MenuItem value={"Latest"}>Sort By</MenuItem>*/}
      {/*    </Select>*/}
      {/*  </FormControl>*/}
      {/*</Grid>*/}
      {!isLoading && (
        <Grid xs={12} md={12} display={"flex"} sx={{ padding: { xs: 0, md: '1rem' }, marginBottom: { xs: '3rem', md: 0 } }}>
          <Grid item xs={12} md={8} >
            <Grid container className="card-additional" >
              <Grid container className="card-head-table">
                <Grid item xs={12} md={5} className="card-header-text pl-3 py-3">
                  Forms and Declarations
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                display={"flex"}
                justifyContent={"center"}
                sx={{ width: isDekstop ? '100% !important' : 'inherit' }}
              >
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  <Table
                    sx={{
                      minWidth: 700,
                      borderSpacing: "0 1rem",
                      borderCollapse: "separate",
                    }}
                  >
                    <TableHead>
                      <EnhancedTableHead
                        order={filter.sort}
                        orderBy={filter.name}
                        onRequestSort={handleRequestSort}
                      />
                    </TableHead>
                    <TableBody className={"documents-table-body"}>
                      {documents
                        .slice(
                          filter.index * filter.page_size,
                          filter.index * filter.page_size + filter.page_size
                        )
                        .map((document, index) => (
                          <StyledTableRow
                            className={"documents-table-body-row"}
                            key={index}
                          >
                            <StyledTableCell>
                              <CheckBox
                                data-tut={
                                  index === 0 ? "addtional_forms_checkbox" : ""
                                }
                                type={"circle"}
                                checked={forms.indexOf(document.id) > -1}
                                sx={{ ml: 1 }}
                                onChange={() => handleForm(document.id)}
                                disabled={!isEditable}
                              />
                            </StyledTableCell>
                            {/* <StyledTableCell>{index + 1}</StyledTableCell> */}
                            <StyledTableCell
                              sx={{
                                width: "250px",
                                wordBreak: "break-all",
                              }}
                            >
                              {document.document_name}
                            </StyledTableCell>
                            <StyledTableCell>
                              {dayjs(new Date(document.uploaded_on)).format(
                                "DD-MM-YYYY"
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Stack direction={"row"}>
                                <Button
                                  onClick={() => deleteFile(document)}
                                  sx={{
                                    border: "none",
                                    color: "#727272",
                                    fontSize: 16,
                                    fontWeight: 500,
                                    mr: 2,
                                    mt: 1,
                                    p: "10px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img src={images.deleteIcon} alt="delete" />
                                </Button>
                                <Button
                                  onClick={() => download(document.id, false)}
                                  sx={{
                                    border: "none",
                                    color: "#727272",
                                    fontSize: 16,
                                    fontWeight: 500,
                                    mr: 2,
                                    mt: 1,
                                    p: "10px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src={images.downloadIcon}
                                    alt="download"
                                  />
                                </Button>
                                <Button
                                  onClick={() => download(document.id, true)}
                                  sx={{
                                    border: "none",
                                    color: "#727272",
                                    fontSize: 16,
                                    fontWeight: 500,
                                    mr: 2,
                                    mt: 1,
                                    p: "10px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src={images.viewIcon}
                                    alt="view"
                                    className="action-icon"
                                  />
                                </Button>
                                <Button
                                  onClick={() => signerFile(document)}
                                  sx={{
                                    border: "none",
                                    color: "#727272",
                                    fontSize: 16,
                                    fontWeight: 500,
                                    mr: 2,
                                    mt: 1,
                                    p: "10px",
                                    cursor: "pointer",
                                  }}
                                  data-tut="set_signer"
                                >
                                  <DriveFileRenameOutlineIcon />
                                </Button>
                              </Stack>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      {documents.length === 0 && !loading && (
                        <StyledTableRow className={"request-table-body-row"}>
                          <StyledTableCell colSpan={7}>
                            <Stack textAlign={"center"}>
                              <img
                                src={images.emptyIcon}
                                alt={"no request"}
                                className={
                                  "vertical-align-middle empty-image mb-2"
                                }
                              />
                              <Stack
                                direction={"row"}
                                justifyContent={"center"}
                              >
                                <Typography variant={"body2"}>
                                  No Documents Found, Upload To see the
                                  Documents.
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
                        </StyledTableRow>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          colSpan={7}
                          count={documents.length}
                          rowsPerPage={filter.page_size}
                          page={filter.index}
                          onRowsPerPageChange={(e) =>
                            setFilter((prevState) => ({
                              ...prevState,
                              page_size: parseInt(e.target.value, 10),
                            }))
                          }
                          onPageChange={handleChangePage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              //className="card-right text-center ml-3 p-5"
              sx={{
                background: "#f5fbff",
                //padding: '40px 0 70px 0 ',
                borderRadius: "10px",
                textAlign: "center",
                display: { xs: "none", md: "inline-block" },
                p: 8,
                ml: 3,
              }}
            >
              <Grid item width="100%" className="mb-3 card-heading-right mt-3">
                Upload Documents
              </Grid>
              <Grid
                item
                width="100%"
                className="dotted-card"
                sx={{ opacity: `${opacity}` }}
              >
                <p className="heading-right">Document Name</p>
                <InputField
                  placeholder={"Type Here..."}
                  size={"small"}
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    background: "#F6F7FB",
                    fontSize: "12px",
                    borderRadius: "5px",
                    mb: 3,
                  }}
                  variant={"outlined"}
                  inputValue={documentName}
                  textChange={setName}
                  validators={[
                    {
                      check: Validators.required,
                      message: identifiers.field_error as string,
                    },
                    {
                      check: Validators.FileName,
                      message:
                        "File name can only contain Alpha-Numeric Characters, underscores and dashes",
                    },
                  ]}
                  // hasError={hasError}
                  // fieldError={identifiers.field_error as string}
                  disabled={!isEditable}
                />
                <PrimaryButton
                  variant={"contained"}
                  className={"upload-button"}
                  onClick={handleUpload}
                  disabled={isUploading || hasError || !isEditable}
                  fullWidth={false}
                  data-tut={isDekstop ? "choose_file_additional_forms" : ""}
                >
                  {/* {isUploading && (
                  <CircularProgress sx={{ mr: 1, color: "white" }} />
                )}{" "} */}
                  Choose File
                </PrimaryButton>
                <input
                  ref={fileUploadRef}
                  hidden
                  accept={identifiers.accept as string}
                  type="file"
                  size={identifiers.maxFileSize as number}
                  onChange={handleFileUpload}
                />
                <p className="instructions mt-4">
                  {/*or drag file in here <br />*/}
                  accepted file .doc, .docx and .pdf (max size {identifiers.maxFileSizeText})
                </p>
              </Grid>
              {isUploading && (
                <Grid
                  item
                  className="dotted-card pt-3 text-start pb-3 "
                  sx={{ mt: 2 }}
                  width={"100%"}
                >
                  {documentName}
                  <LinearProgressWithLabel
                    variant="determinate"
                    value={percentageProcess}
                    reqNumber={100}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default AdditionalForms;
