import { images } from '../../utils/constants/images';
import { admin, agency, candidate } from '../../utils/constants/routes';

export const mainOptionsConfig = (Role: any, isItrisEnabled: any) => [
  Role !== 'admin' && {
    text: 'Dashboard',
    icon: images.dashboardIcon,
    selectedIcon: images.dashboardSelectedIcon,
    url: Role === 'agency' ? agency.dashboard : candidate.dashboard,
    tourCofig:"home_btn"
  },
  Role !== 'admin' && {
    text: 'Documents',
    icon: images.documentsIcon,
    selectedIcon: images.documentsSelectedIcon,
    url: Role === 'agency' ? agency.documents : candidate.documents,
    tourCofig:"document_repo_nav_btn"

  },
  // {
  //   text: 'Requests',
  //   icon: images.requestIcon,
  //   selectedIcon: images.requestSelectedIcon,
  //   url: Role === 'agency' ? agency.requests : candidate.requests,
  // },
  Role === 'agency' && {
    text: 'Applicants',
    icon: images.applicantsIcon,
    selectedIcon: images.applicantsSelectedIcon,
    url: agency.applicants,
    tourCofig:"applicant_nav_btn"

  },
  Role === 'agency' && {
    text: 'Notifications',
    icon: images.notificationIcon,
    selectedIcon: images.notificationIcon,
    url: agency.notifications,
    tourCofig:"applicant_nav_btn"

  },
   Role === 'agency' && isItrisEnabled && {
    text: 'itris Settings',
    icon: images.ItrisLogo,
    selectedIcon: images.ItrisLogo,
    url: agency.itris_settings,
  },
  Role === 'admin' && {
    text: 'Agencies',
    icon: images.applicantsIcon,
    selectedIcon: images.applicantsSelectedIcon,
    url: admin.agencies
  },
  Role !== 'admin' && {
    text: 'Help',
    icon: images.helpIcon,
    selectedIcon: images.helpIconSelected,
    url: Role === 'agency' ? agency.help : candidate.help,
  },
];

export const subOptionsConfig = (Role: any) => [
  Role === 'agency' && {
    text: 'Invite Members',
    icon: images.inviteMembersIcon,
    selectedIcon: images.inviteMembersIcon,
    option: 'invite',
    url: Role === 'agency' ? agency.invite : candidate.invite,
    isMobile: { xs: 'block', sm: 'block', md: 'block', lg: 'block' },
    tourCofig:"invite_member_nav_btn"
  },
  // {
  //   text: 'User Profile',
  //   icon: images.dummyIcon,
  //   selectedIcon: images.dummyIcon,
  //   option: 'profile',
  //   url: '/profile',
  //   isMobile: { xs: 'none', sm: 'none', md: 'block', lg: 'block' },
  // },
  {
    text: 'Log Out',
    icon: images.logoutIcon,
    selectedIcon: images.logoutIcon,
    option: 'logout',
    url: '/login',
    isMobile: { xs: 'block', sm: 'block', md: 'block', lg: 'block' },
  },
];
