import {
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell, tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {identifiers} from "../../../../../../utils/constants/identifiers";
import PrimaryButton from "../../../../../../components/Button/PrimaryButton";
import React, {FC, useState} from "react";
import {UploadFileOutlined} from "@mui/icons-material";
import UploadOnly from "../../../../Candidate/ViewRequest/VettingDocuments/DocumentList/common/UploadFiles/UploadOnly";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f5fbff",
    color: "#727272",
    border: "none",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#263238",
    fontSize: "14px",
    fontWeight: 500,
    padding: "5px 16px",
  },
  [`&.${tableCellClasses.head}:first-of-type`]: {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  [`&.${tableCellClasses.head}:last-of-type`]: {
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    width: "14rem",
    textAlign: "start",
    paddingLeft: "35px",
  },
  [`&.${tableCellClasses.body}:first-of-type`]: {
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
    borderLeft: "1px solid #DADADA",
  },
  [`&.${tableCellClasses.body}:last-of-type`]: {
    borderTopRightRadius: "12px",
    borderBottomRightRadius: "12px",
    borderRight: "1px solid #DADADA",
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&": {
    borderRadius: "8px",
    borderTop: "1px solid #DADADA",
    backgroundColor: "white",
  },
}));

const AgencyUploadItemsTable: FC<{
  activeDocument: any,
  id: string | undefined,
  candidate_id: string | undefined
}> = ({
        activeDocument,
        id,
        candidate_id
      }) => {

  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [subDocId, setSubDocId] = useState<string | null>(null);
  const [subDocName, setSubDocName] = useState<string>('');

  const handleClickUpload = (sub_document_id: string, sub_document_name: string) => {
    setSubDocId(sub_document_id);
    setSubDocName(sub_document_name);
    setShowUpload(true);
  }
  const handleClickBackUpload = () => {
    setSubDocId(null);
    setSubDocName('');
    setShowUpload(false);
  }


  return (
    <Grid xs={12} container className="card-vetting" sx={{marginTop: '2rem !important'}}>
      <Grid
        container
        className="card-head-table"
        direction="row"
        justifyContent={"space-between"}
        alignItems={'center'}
        sx={{marginBottom: '0 !important'}}
      >
        <Grid
          item
          xs={12}
          sx={{pl: 1, pt: 1, pb: 2}}
        >
          <Typography
            variant={"body2"}
            fontSize={"16px"}
            fontWeight={"bold"}
            paddingTop={"10px"}
            sx={{pl: {xs: "18px", md: 2}}}
          >
            The candidate still needs to upload the following documents
          </Typography>
        </Grid>
      </Grid>
      <Grid xs={12} item md={12}>
        <Grid
          container
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          {!showUpload &&
            <TableContainer
              component={Paper}
              sx={{boxShadow: "none", width: "90%"}}
            >
              <Table
                sx={{
                  minWidth: 600,
                  borderSpacing: "0 1rem",
                  borderCollapse: "separate",
                  backgroundColor: "#f5fbff",
                }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{minWidth: "150px"}}>
                      Document Name
                    </StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody className={"documents-table-body"}>
                  {activeDocument.sub_documents.map(
                    (document: any, index: number) => (
                      <React.Fragment key={index}>
                        {!document.document_repository_id &&
                          <StyledTableRow
                            className={"documents-table-body-row"}
                            key={index}
                          >
                            <StyledTableCell>
                              {document.name}
                            </StyledTableCell>
                            <StyledTableCell>
                              <PrimaryButton
                                onClick={() => handleClickUpload(document.id, document.name)}
                                sx={{
                                  borderRadius: "8px",
                                  p: "0px 21px",
                                  height: "32px",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                                variant={"outlined"}
                                fullWidth={false}
                                startIcon={<UploadFileOutlined/>}
                              >
                                Upload
                              </PrimaryButton>
                            </StyledTableCell>
                          </StyledTableRow>
                        }
                      </React.Fragment>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
          {showUpload &&
            <UploadOnly
              name={subDocName}
              request_id={id}
              vetting_document_id={activeDocument.id}
              candidate_id={candidate_id}
              showBack={true}
              onClickBack={handleClickBackUpload}
              sub_document_id={subDocId}
              onUploadComplete={handleClickBackUpload}
            />
          }
        </Grid>
      </Grid>
    </Grid>
  )
}
export default AgencyUploadItemsTable
