import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import * as Amplify from '../services/amplify';

interface CustomAxiosRetryConfig {
  retry: number;
}

const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  params: { lang: 'en_IN', t: new Date().getTime() },
});

axiosInstance.defaults.timeout = 300000;

axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const { noAuth } = config as any;
  const clonedConfig: any = config;

  if (!noAuth) {
    const session = await Amplify.currentSession();
    const token = session.getIdToken().getJwtToken();

    if (token) {
      clonedConfig.headers.common = {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      };
    }
  }

  if (clonedConfig.retry === undefined) {
    clonedConfig.retry = process.env.REACT_APP_ENV_NAME === 'dev' ? 0 : 3;
  }

  return clonedConfig;
});

axiosInstance.interceptors.response.use(
  // (response: AxiosResponse) => {
  //   console.log('success');
  //   console.log(response);
  //   return response;
  // },
  undefined,
  (error: { config: CustomAxiosRetryConfig } & AxiosError) => {
    console.log('error');
    const { config, response } = error;
    if (!config || !config.retry) {
      return Promise.reject(error);
    }

    config.retry -= 1;

    if (response?.status !== 503 && response?.status !== 500) {
      return Promise.reject(error);
    }

    if (response.status === 503) {
      const delayRetryRequest = new Promise<void>((resolve) => {
        setTimeout(() => {
          console.log('retry the request', config.url);
          resolve();
        }, 1000);
      });
      return delayRetryRequest.then(() => axiosInstance(config));
    }
    return axiosInstance(config);
  }
);

export default axiosInstance;
