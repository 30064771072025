import React, {
  ChangeEvent,
  FC,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
  FormHelperText,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  tableCellClasses,
  styled,
  TableCell,
  Stack,
  TableFooter,
  TablePagination,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PageLoader from "../../../../../components/PageLoader";
import { images } from "../../../../../utils/constants/images";
import SearchIcon from "@mui/icons-material/Search";
import "../DocumentRepository.scss";
import mime from "mime";
import axiosInstance from "../../../../../utils/axios";
import { URLS } from "../../../../../utils/constants/urls";
import axios from "axios";
import { identifiers } from "../../../../../utils/constants/identifiers";
import { DocumentModel } from "../DocumentRepositoryModel";
import dayjs from "dayjs";
import PrimaryButton from "../../../../../components/Button/PrimaryButton";
import Modal from "../../../../../components/modal/Modal";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { Document, Page } from "react-pdf";
import { debounce } from "lodash";
import InputField from "../../../../../components/InputField";
import { useDispatch, useSelector } from "react-redux";
import {
  setNotificationMessage,
  setUserData,
} from "../../../../../utils/redux";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";

import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../../utils/constants/coachMarks";
import { CognitoUser } from "amazon-cognito-identity-js";
import * as Amplify from "../../../../../utils/services/amplify";
import { User } from "../../../../../utils/redux/reducer/authentication-slice";
import { Auth } from "aws-amplify";
import { isTourCompleted, markTourAsCompleted } from "../../../../../utils/storage/tours";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "#727272",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#263238",
    fontSize: "14px",
    fontWeight: 600,
    borderTop: "1px solid #DADADA",
    borderBottom: "1px solid #DADADA",
    padding: "10px 16px",
  },
  [`&.${tableCellClasses.head}:first-of-type`]: {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  [`&.${tableCellClasses.head}:last-of-type`]: {
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    width: "12rem",
  },
  [`&.${tableCellClasses.body}:first-of-type`]: {
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
    borderLeft: "1px solid #DADADA",
  },
  [`&.${tableCellClasses.body}:last-of-type`]: {
    borderTopRightRadius: "12px",
    borderBottomRightRadius: "12px",
    borderRight: "1px solid #DADADA",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&": {
    backgroundColor: "white",
    borderRadius: "8px",
  },
}));

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "document_name_low_search",
    numeric: true,
    disablePadding: true,
    label: "Document Name",
  },
  {
    id: "uploaded_on",
    numeric: true,
    disablePadding: false,
    label: "Upload Date",
  },
];

interface EnhancedTableProps {
  order: boolean;
  loading: boolean;
  orderBy: string;
  onRequestSort: (property: string) => void;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { orderBy, order, onRequestSort, loading } = props;

  const createSortHandler = (property: string) => {
    onRequestSort(property);
  };

  return (
    <StyledTableRow>
      <StyledTableCell></StyledTableCell>
      {headCells.map((headCell) => (
        <StyledTableCell
          key={headCell.id}
          align={headCell.numeric ? "left" : "right"}
          padding={headCell.disablePadding ? "none" : "normal"}
          sortDirection={order ? "asc" : "desc"}
          title={
            loading ? "Please wait until all documents are fetched" : undefined
          }
        >
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={order ? "asc" : "desc"}
            onClick={() => createSortHandler(headCell.id)}
            disabled={loading}
          >
            {headCell.label}
            {orderBy === headCell.id && (
              <Box component="span" sx={visuallyHidden}>
                {order ? "sorted ascending" : "sorted descending"}
                descending
              </Box>
            )}
          </TableSortLabel>
        </StyledTableCell>
      ))}
      <StyledTableCell>Action</StyledTableCell>
    </StyledTableRow>
  );
}

const LinearProgressWithLabel: FC<
  { value: number; reqNumber: number } & LinearProgressProps
> = ({ value, reqNumber, ...props }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          color="success"
          variant="determinate"
          {...props}
          value={(value / reqNumber) * 100}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

const Placeholder = ({ children }: { children: ReactNode }) => {
  return <Box>{children}</Box>;
};

const initial_filter_state: {
  search: string;
  index: number;
  page_size: number;
  order_by: string;
  order: boolean;
} = {
  search: "",
  index: 0,
  page_size: 10,
  order_by: "uploaded_on",
  order: false,
};

const VettingDocuments: FC = () => {
  const fileUploadRef = useRef<any>(null);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };

  const [hasError, setHasError] = useState<boolean>(false);
  const [documents, setDocuments] = useState<DocumentModel[]>([]);
  const [categories, setCategories] = useState<{ id: string; name: string }[]>(
    []
  );
  const [category, setCategory] = useState<string>("");
  const [documentsLoading, setDocumentsLoading] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [document, setDocument] = useState<DocumentModel | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [percentageProcess, setPercentageProcess] = useState(0);
  const [opacity, setOpacity] = useState("1");
  const [documentType, setDocumentType] = useState<"pdf" | "image">();
  const [documentUrl, setDocumentUrl] = useState<string>();
  const [viewDocument, setViewDocument] = useState(false);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [filter, setFilter] = useState<{
    search: string;
    index: number;
    page_size: number;
    order: boolean;
    order_by: string;
  }>(initial_filter_state);
  const [count, setCount] = useState<number>(0);
  const [dataFetching, setDataFetching] = useState<boolean>(false);
  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  const getCategories = useCallback(async () => {
    try {
      setDocumentsLoading(true);
      const { data } = await axiosInstance.get(URLS.document_categories);
      setCategories(data);
      setDocumentsLoading(false);
    } catch (e: any) {
      console.log(e);
      if (await Auth.currentUserInfo()) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "There is an error in fetching categories",
          })
        );
      }
    }
  }, [dispatch]);

  const getDocuments = async (search: string, name: string, sort: boolean) => {
    const searchQuery = !!search && `&keyword=${search}`;
    const sortColumn = !!name && `&asc_sort=${sort}&sort_column=${name}`;
    let page_key = null;
    const document_list: DocumentModel[] = [];

    try {
      setLoading(true);
      setDataFetching(true);
      do {
        const { data }: { status: number; data: any } =
          await axiosInstance.post(
            `${URLS.document}?page_size=${filter.page_size}${sortColumn || ""}${searchQuery || ""
            }`,
            page_key
          );
        setDocumentsLoading(false);
        await setLoading(false);
        document_list.push(...data.data);
        if (!!page_key) {
          setDocuments((prevState) => [...prevState, ...data.data]);
        } else {
          setDocuments(data.data);
        }
        page_key = JSON.parse(data.page_key);
      } while (!!page_key && page_key !== "null");
      setDocuments(document_list);
      setCount(document_list.length);
      setDataFetching(false);
    } catch (e: any) {
      setDocumentsLoading(false);
      setLoading(false);
      setDataFetching(false);
      if (await Auth.currentUserInfo()) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "Unable to Get List of Documents",
          })
        );
      }
    }
  };

  const delayedQuery = useRef(debounce(getDocuments, 800)).current;

  const fetchData = useRef(debounce(getDocuments, 100)).current;

  useEffect(() => {
    (async () => {
      await fetchData(
        initial_filter_state.search,
        initial_filter_state.order_by,
        initial_filter_state.order
      );
    })();
  }, [fetchData]);

  useEffect(() => {
    (async () => {
      setDocumentsLoading(true);
      await getCategories();
    })();
  }, [getCategories]);

  const setCoachMarksValue = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:document_repository": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [user, dispatch]);

  useEffect(() => {
    if (documentsLoading !== undefined && !documentsLoading) {
      if (!!user && user["custom:document_repository"] !== "true") {
        setCurrentStep(tourStep.candidate_document_repository.index);
      }
    }
  }, [documentsLoading, setCurrentStep, user]);

  useEffect(() => {
    if (documentsLoading !== undefined && !documentsLoading) {
      if (tourStep.candidate_document_repository.index === currentStep) {
        if (!isTourCompleted('candidate_document_repository')) {
          setIsOpen(true);
        }
      } else if (
        currentStep ===
        tourStep.candidate_document_repository.index +
        tourStep.candidate_document_repository.steps
      ) {
        setIsOpen(false);
        markTourAsCompleted('candidate_document_repository');
        if (!!user && user["custom:document_repository"] !== "true") {
          (async () => {
            await setCoachMarksValue();
          })();
        }
      }
    }
  }, [currentStep, documentsLoading, setCoachMarksValue, setIsOpen, user]);

  const setSearch = async (search: string) => {
    setFilter((prevState) => ({ ...prevState, search, index: 0 }));
    delayedQuery(search, filter.order_by, filter.order);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter((prevState) => ({ ...prevState, index: newPage }));
  };

  const handleRequestSort = async (property: string) => {
    const sort = filter.order_by === property ? !filter.order : true;
    await fetchData(filter.search, property, sort);
    setFilter((prevState) => ({
      ...prevState,
      order_by: property,
      order: prevState.order_by === property ? !prevState.order : true,
      index: 0,
    }));
  };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    setIsUploading(true);
    if (event.target.files) {
      const max_size = identifiers.maxFileSize as number;
      if (event.target.files[0].size <= max_size) {
        setLoading(true);
        const file: File = event.target.files[0];
        const selected_category: { id: string; name: string } = categories.find(
          (category_data) => category_data.id === category
        ) as { id: string; name: string };
        const additionalForm: {
          name: string;
          type: string;
          directory: string;
          file_name: string;
        } = {
          type: mime.getType(file.name)
            ? (mime.getType(file.name) as string)
            : file.type,
          name: `${new Date().getTime()}_${file.name}`,
          file_name: selected_category.name
            .replace(" / ", " or ")
            .replace(" : ", ""),
          directory: "documents",
        };
        try {
          const { data } = await axiosInstance.post(
            URLS.candidate_file_upload,
            additionalForm
          );
          await uploadFile(
            file,
            data.url,
            mime.getType(file.name)
              ? (mime.getType(file.name) as string)
              : file.type,
            selected_category.name.replace(" / ", " or ").replace(" : ", "")
          );
        } catch (e: any) {
          setIsUploading(false);
          setLoading(false);
          if (fileUploadRef.current) {
            fileUploadRef.current.value = null;
          }
          if (e.response.status === 409) {
            dispatch(
              setNotificationMessage({
                display: true,
                severity: "error",
                message: `File with name ${category} already exists`,
              })
            );
          } else {
            dispatch(
              setNotificationMessage({
                display: true,
                severity: "error",
                message: `There was an error uploading the file`,
              })
            );
          }
        }
      } else {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: `You cannot upload file more than ${identifiers.maxFileSizeText}`,
          })
        );
        if (fileUploadRef.current) {
          fileUploadRef.current.value = null;
        }
      }
    }
  };

  const uploadFile = async (
    file: File,
    url: string,
    mime: string,
    name: string
  ) => {
    setOpacity("0.6");
    try {
      const { status } = await axios.put(url, file, {
        headers: { "Content-Type": mime },
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total || 100)
          );
          setPercentageProcess(percentCompleted);
        },
      });
      if (status === 200) {
        setOpacity("1");
        setIsUploading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "success",
            message: `File ${name} has been uploaded Successfully`,
          })
        );
        if (fileUploadRef.current) {
          fileUploadRef.current.value = null;
        }
        setCategory("");
        setLoading(false);
        setTimeout(
          () => fetchData(filter.search, filter.order_by, filter.order),
          5000
        );
      }
    } catch (e) {
      setOpacity("1");
      setIsUploading(false);
      setLoading(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in uploading document",
        })
      );
    }
  };

  const handleUpload = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!!category) {
      if (fileUploadRef.current) {
        fileUploadRef.current.click();
      }
    } else {
      setHasError(true);
    }
  };

  const download = async (id: string, view: boolean) => {
    try {
      const { data } = await axiosInstance.get(`${URLS.document}/${id}`);
      const type =
        data.mimetype.indexOf("pdf") > -1
          ? "pdf"
          : data.mimetype.indexOf("image") > -1
            ? "image"
            : null;
      if (!!type && view) {
        setDocumentType(type);
        setDocumentUrl(data.url);
        setViewDocument(true);
      } else {
        window.open(data.url, "_blank");
      }
    } catch (e) {
      console.log(e);
      if (await Auth.currentUserInfo()) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "There is an error in downloading",
          })
        );
      }
    }
  };

  const deleteFile = async (selectedDocument: DocumentModel) => {
    setDocument(selectedDocument);
    setOpenDelete(true);
  };

  const confirmDelete = useCallback(async () => {
    try {
      setLoading(true);
      await axiosInstance.delete(
        `${URLS.document}/${document?.id}?type=document`
      );
      setOpenDelete(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: `File ${document?.document_name} has been deleted Successfully`,
        })
      );
      setLoading(false);
      setDocument(null);
      await fetchData(filter.search, filter.order_by, filter.order);
    } catch (e) {
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in deleting document",
        })
      );
    }
  }, [
    dispatch,
    document?.document_name,
    document?.id,
    fetchData,
    filter.order,
    filter.order_by,
    filter.search,
  ]);

  const deleteModal = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "20px",
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              textAlign: "center",
              letterSpacing: "0.15px",
            }}
          >
            Delete Document Permanently
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            {document?.document_name} will be deleted permanently from the
            repository.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            Are you sure?
          </Typography>
        </Grid>
        <Grid container alignItems="right" justifyContent="right">
          <Button
            sx={{
              border: "none",
              color: "#727272",
              fontSize: 16,
              fontWeight: 500,
              mr: 2,
              mt: 1,
              p: "10px",
            }}
            disabled={loading}
            onClick={confirmDelete}
          >
            {loading && <CircularProgress sx={{ mr: 1 }} size={30} />}Delete
            Document
          </Button>
          <PrimaryButton
            disabled={loading}
            fullWidth={false}
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={() => {
              setOpenDelete(false);
              setDocument(null);
            }}
          >
            Cancel
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [confirmDelete, document?.document_name, loading]);

  const viewModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          {documentType === "pdf" && (
            <Document
              file={{ url: documentUrl }}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          )}
          {documentType === "image" && (
            <img src={documentUrl} alt={"document"} className={"img-fluid"} />
          )}
        </Grid>
      </Grid>
    );
  }, [documentType, documentUrl, numPages]);

  const openDeleteModal = useMemo(() => {
    return (
      <Modal
        open={openDelete}
        setModalClose={setOpenDelete}
        children={deleteModal}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [openDelete, deleteModal]);

  const openViewModal = useMemo(() => {
    return (
      <Modal
        open={viewDocument}
        setModalClose={setViewDocument}
        children={viewModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [viewDocument, viewModalText]);

  return (
    <Grid container spacing={2} sx={{ px: 0 }}>
      {openDeleteModal}
      {openViewModal}
      <Grid item xs={12} md={12} lg={4}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="mob-card text-center"
        >
          <Grid item md={12} className="mb-2 card-heading-right mt-1">
            Upload Documents
          </Grid>
          <Grid
            item
            xs={10}
            className="dotted-card"
            sx={{ opacity: `${opacity}` }}
          >
            <Typography
              variant={"body1"}
              color={"#727272"}
              sx={{ mb: 1, mx: 2 }}
              data-tut={
                !isDesktop ? "candidate_document_repo_select_cetegory" : ""
              }
            >
              Select Document
            </Typography>
            <Grid item md={10} sx={{ margin: "auto", mb: "16px" }}>
              <FormControl
                fullWidth
                sx={{
                  textAlign: "start",
                  borderRadius: "8px",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Select
                  value={category}
                  sx={{
                    height: 38,
                    py: 1,
                    backgroundColor: "#F6F7FB",
                    maxWidth: "250px",
                    overFlow: "hidden",
                  }}
                  onChange={(e) => {
                    setCategory(e.target.value);
                    setHasError(false);
                  }}
                  displayEmpty
                  renderValue={
                    !category
                      ? () => <Placeholder>Please Select Document</Placeholder>
                      : undefined
                  }
                >
                  {categories.map((category_data, index) => (
                    <MenuItem value={category_data.id} key={index}>
                      {category_data.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {hasError && (
              <FormHelperText
                sx={{
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                  mb: "16px",
                }}
              >
                Select Category to Upload the Documents
              </FormHelperText>
            )}
            <Button
              variant="contained"
              component="label"
              className="upload-button"
              onClick={handleUpload}
              disabled={loading || isUploading}
              data-tut={!isDesktop ? "candidate_document_repo_upload_file" : ""}
            >
              Choose File
            </Button>
            <input
              ref={fileUploadRef}
              hidden
              accept={identifiers.accept as string}
              size={identifiers.maxFileSize as number}
              type="file"
              onChange={handleFileUpload}
            />
            <p className="instructions mt-4">
              or drag file in here <br />
              accepted file .doc, .docx and .pdf (max size {identifiers.maxFileSizeText})
            </p>
          </Grid>
          {isUploading && (
            <Grid
              item
              className="dotted-card pt-1 text-start pb-1 "
              sx={{ mt: 2 }}
              width={"100%"}
              md={8}
            >
              Uploading File
              <LinearProgressWithLabel
                variant="determinate"
                value={percentageProcess}
                reqNumber={100}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: { xs: "space-between", md: "flex-start" },
          }}
        >
          <Grid item xs={12} lg={3}>
            <InputField
              sx={{ mr: 1 }}
              placeholder="Search Document..."
              value={filter.search}
              textChange={setSearch}
              inputProps={{
                sx: {
                  p: 1,
                },
                title: dataFetching
                  ? "Please wait until all documents are fetched"
                  : undefined,
              }}
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
              disabled={dataFetching}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={8} sx={{ paddingRight: 3 }}>
        {!documentsLoading ? (
          <>
            <Grid container className="card-head m-1">
              <Grid item md={5} className="card-header-text">
                Uploaded Document : {count}
              </Grid>
            </Grid>
            <Grid container spacing={2} className={"mb-3"}>
              <Grid item xs={12} md={12}>
                <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                  <Table
                    sx={{
                      minWidth: 700,
                      borderSpacing: "0 1rem",
                      borderCollapse: "separate",
                    }}
                  >
                    <TableHead>
                      <EnhancedTableHead
                        order={filter.order}
                        orderBy={filter.order_by}
                        onRequestSort={handleRequestSort}
                        loading={dataFetching}
                      />
                    </TableHead>
                    <TableBody className={"documents-table-body"}>
                      {documents
                        .slice(
                          filter.index * filter.page_size,
                          filter.index * filter.page_size + filter.page_size
                        )
                        .map((document) => (
                          <StyledTableRow
                            className={"documents-table-body-row"}
                          >
                            <StyledTableCell>{document.index}</StyledTableCell>
                            <StyledTableCell>
                              {document.document_name}
                            </StyledTableCell>
                            <StyledTableCell>
                              {dayjs(new Date(document.uploaded_on)).format(
                                "DD-MM-YYYY"
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Stack direction={"row"}>
                                <Button
                                  onClick={() => deleteFile(document)}
                                  sx={{
                                    border: "none",
                                    color: "#727272",
                                    fontSize: 16,
                                    fontWeight: 500,
                                    mr: 1,
                                    mt: 1,
                                    p: "10px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img src={images.deleteIcon} alt="delete" />
                                </Button>
                                <Button
                                  onClick={() => download(document.id, false)}
                                  sx={{
                                    border: "none",
                                    color: "#727272",
                                    fontSize: 16,
                                    fontWeight: 500,
                                    mr: 1,
                                    mt: 1,
                                    p: "10px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src={images.downloadIcon}
                                    alt="download"
                                  />
                                </Button>
                                <Button
                                  onClick={() => download(document.id, true)}
                                  sx={{
                                    border: "none",
                                    color: "#727272",
                                    fontSize: 16,
                                    fontWeight: 500,
                                    mr: 1,
                                    mt: 1,
                                    p: "10px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src={images.viewIcon}
                                    alt="view"
                                    className="action-icon"
                                  />
                                </Button>
                              </Stack>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      {documents.length === 0 && !loading && (
                        <StyledTableRow className={"request-table-body-row"}>
                          <StyledTableCell colSpan={7}>
                            <Stack textAlign={"center"}>
                              <img
                                src={images.emptyIcon}
                                alt={"no request"}
                                className={
                                  "vertical-align-middle empty-image mb-2"
                                }
                              />
                              <Stack
                                direction={"row"}
                                justifyContent={"center"}
                              >
                                <Typography variant={"body2"}>
                                  No Documents Found, Upload To see the
                                  Documents.
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
                        </StyledTableRow>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          colSpan={7}
                          count={count}
                          rowsPerPage={filter.page_size}
                          page={filter.index}
                          onRowsPerPageChange={(e) => {
                            setFilter((prevState) => ({
                              ...prevState,
                              delay: 0,
                              page_size: parseInt(e.target.value, 10),
                            }));
                          }}
                          onPageChange={handleChangePage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </>
        ) : (
          <PageLoader />
        )}
      </Grid>

      <Grid item md={12} lg={4}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            background: "#f5fbff",
            padding: "40px 0 70px 0 ",
            borderRadius: "10px",
            display: { xs: "none", md: "inline-block" },
            textAlign: "center",
            p: 5,
          }}
        >
          <Grid item xs={12} className="mb-5 card-heading-right mt-5">
            Upload Documents
          </Grid>
          <Grid
            item
            xs={12}
            className="dotted-card"
            sx={{ opacity: `${opacity}` }}
          >
            <Typography
              variant={"body1"}
              color={"#727272"}
              sx={{ mb: 1, mx: 2 }}
              data-tut={
                isDesktop ? "candidate_document_repo_select_cetegory" : ""
              }
            >
              Select Documents
            </Typography>
            <Grid item width={"100%"} sx={{ margin: "auto", mb: "16px" }}>
              <FormControl
                fullWidth
                sx={{
                  textAlign: "start",
                  borderRadius: "8px",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <Select
                  value={category}
                  sx={{
                    height: 38,
                    py: 1,
                    backgroundColor: "#F6F7FB",
                    maxWidth: "250px",
                    overFlow: "hidden",
                  }}
                  onChange={(e) => {
                    console.log(e);
                    setCategory(e.target.value);
                    setHasError(false);
                  }}
                  displayEmpty
                  renderValue={
                    !category
                      ? () => <Placeholder>Please Select Documents</Placeholder>
                      : undefined
                  }
                >
                  {categories.map((category_data, index) => (
                    <MenuItem value={category_data.id} key={index}>
                      {category_data.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {hasError && (
              <FormHelperText
                sx={{
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                  mb: "16px",
                }}
              >
                Select Category to Upload the Documents
              </FormHelperText>
            )}
            <Button
              variant="contained"
              component="label"
              className="upload-button"
              onClick={handleUpload}
              disabled={loading || isUploading}
              data-tut={isDesktop ? "candidate_document_repo_upload_file" : ""}
            >
              Choose File
            </Button>
            <input
              ref={fileUploadRef}
              hidden
              accept={identifiers.accept as string}
              size={identifiers.maxFileSize as number}
              type="file"
              onChange={handleFileUpload}
            />
            <p className="instructions mt-4">
              or drag file in here <br />
              accepted file .doc, .docx and .pdf (max size {identifiers.maxFileSizeText})
            </p>
          </Grid>
          {isUploading && (
            <Grid
              item
              className="dotted-card pt-3 text-start pb-3 py-5 "
              sx={{ mt: 2 }}
              width={"100%"}
            >
              Uploading File
              <LinearProgressWithLabel
                variant="determinate"
                value={percentageProcess}
                reqNumber={100}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VettingDocuments;
