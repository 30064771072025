import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  // TableFooter,
  TableHead,
  // TablePagination,
  TableRow,
  Paper,
} from "@mui/material";
import { images } from "../../../../../../../../utils/constants/images";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../../../../../../../components/Button/PrimaryButton";
import {
  setValue,
  setName,
  setRequestData,
  setReload,
  setNotificationMessage,
  VettingDocuments,
  Reload,
} from "../../../../../../../../utils/redux";
import { SubDocument } from "../../../../../../Agency/RequestDetails/RequestDetailsModel";
import { useParams } from "react-router-dom";
import {ContactPage, Delete, Download, InfoOutlined, Visibility} from "@mui/icons-material";
import axiosInstance from "../../../../../../../../utils/axios";
import { URLS } from "../../../../../../../../utils/constants/urls";
import Modal from "../../../../../../../../components/modal/Modal";
import { Document, Page } from "react-pdf";
import { identifiers } from "../../../../../../../../utils/constants/identifiers";
import { styled } from "@mui/material/styles";
import PageLoader from "../../../../../../../../components/PageLoader";

export interface SelectFilesProps {
  active: number;
}

const SelectFiles: FC<SelectFilesProps> = ({ active }) => {
  const dispatch = useDispatch();
  const { id } = useParams() as any;
  const reload = useSelector(Reload);
  const documents = useSelector(VettingDocuments);

  const [displayTimePeriod, setDisplayTimePeriod] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [document, setDocument] = useState<SubDocument | null>(null);
  const [documentType, setDocumentType] = useState<"pdf" | "image">();
  const [documentUrl, setDocumentUrl] = useState<string>();
  const [viewDocument, setViewDocument] = useState(false);
  const [numPages, setNumPages] = useState<number | null>(null);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F5FBFF",
      color: "#727272",
      border: "none",
      maxWidth: "300px",
      width: "300px",
    },
    [`&.${tableCellClasses.body}`]: {
      borderBottom: "none",
      color: "#263238",
      fontSize: "14px",
      fontWeight: 600,
    },
    [`&.${tableCellClasses.head}:first-of-type`]: {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
    },
    [`&.${tableCellClasses.head}:last-of-type`]: {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
    },
    [`&.${tableCellClasses.head}:nth-of-type(2)`]: {
      maxWidth: "200px",
      width: "200px",
    },
    [`&.${tableCellClasses.head}:nth-of-type(5)`]: {
      maxWidth: "200px",
      width: "200px",
    },
    [`&.${tableCellClasses.head}:nth-of-type(6)`]: {
      maxWidth: "180px",
      width: "180px",
    },
    [`&.${tableCellClasses.head}:last-of-type`]: {
      width: "3em",
      maxWidth: "3em",
      textAlign: "center",
    },
    [`&.${tableCellClasses.body}:first-of-type`]: {
      borderRadius: "12px",
      padding: "5px 10px",
    },
    [`&.${tableCellClasses.body}:last-of-type`]: {
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
      padding: "5px 10px",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&": {
      backgroundColor: "white",
      //paddingBottom: "15px",
      //paddingTop: "15px",
    },
  }));

  useEffect(() => {
    let hasOptions: boolean = false;
    documents[active].sub_documents.forEach((document: SubDocument) => {
      if (document.options) {
        hasOptions = true;
      }
    });
    setDisplayTimePeriod(hasOptions);
  }, [documents, active]);

  const download = async (document: SubDocument, view: boolean) => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `${URLS.document}/${document.document_repository_id}?type=document`
      );
      const type =
        data.mimetype.indexOf("pdf") > -1
          ? "pdf"
          : data.mimetype.indexOf("image") > -1
          ? "image"
          : null;
      if (!!type && view) {
        setDocumentType(type);
        setDocumentUrl(data.url);
        setViewDocument(true);
      } else {
        window.open(data.url, "_blank");
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in getting document Link ",
        })
      );
      // need to check
    }
  };

  const unlinkDocument = async (document: SubDocument) => {
    setDocument(document);
    setOpenModal(true);
  };

  const confirmDelete = useCallback(async () => {
    try {
      setLoading(true);
      if (
        documents[active].name === identifiers.cv ||
        documents[active].name === identifiers.opc
      ) {
        await axiosInstance.post(
          `${URLS.unlink_document}?stage=${process.env.REACT_APP_YOTI_ENV}`,
          {
            request_id: id,
            vetting_document_id: documents[active].id,
          }
        );
      } else {
        await axiosInstance.post(
          `${URLS.unlink_document}?stage=${process.env.REACT_APP_YOTI_ENV}`,
          {
            request_id: id,
            sub_document_id: document?.id,
          }
        );
      }
      dispatch(setReload(!reload));
      if (
        documents[active].name === identifiers.cv ||
        documents[active].name === identifiers.opc
      ) {
        dispatch(setValue(1));
        dispatch(setName(""));
      }
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: `File ${document?.name} has been removed Successfully`,
        })
      );
      setOpenModal(false);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in removing the document",
        })
      );
      setOpenModal(false);
    }
  }, [active, dispatch, document?.id, document?.name, documents, id, reload]);

  const deleteModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "20px",
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              textAlign: "center",
              letterSpacing: "0.15px",
            }}
          >
            Remove {document?.name} from Request?
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            {document?.name} will still be available in your Document
            Repository.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            Are you sure?
          </Typography>
        </Grid>
        <Grid container alignItems="right" justifyContent="right">
          <Button
            sx={{
              border: "none",
              color: "#fff",
              fontSize: 16,
              fontWeight: 500,
              mr: 2,
              mt: 1,
              p: "10px",
              background: '#dc3545',
              '&:hover': {
                background: 'red'
              }
            }}
            disabled={loading}
            onClick={confirmDelete}
          >
            {loading && <CircularProgress sx={{ mr: 1, color: '#fff' }} size={"1rem"} />}Remove Document
          </Button>
          <PrimaryButton
            disabled={loading}
            fullWidth={false}
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={() => {
              setOpenModal(false);
              setDocument(null);
            }}
          >
            Cancel
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [confirmDelete, document?.name, loading]);

  const viewModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          {documentType === "pdf" && (
            <Document
              file={{ url: documentUrl }}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          )}
          {documentType === "image" && (
            <img src={documentUrl} alt={"document"} className={"img-fluid"} />
          )}
        </Grid>
      </Grid>
    );
  }, [documentType, documentUrl, numPages]);

  const deleteModal = useMemo(() => {
    return (
      <Modal
        open={openModal}
        setModalClose={setOpenModal}
        children={deleteModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [openModal, deleteModalText]);

  const openViewModal = useMemo(() => {
    return (
      <Modal
        open={viewDocument}
        setModalClose={setViewDocument}
        children={viewModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [viewDocument, viewModalText]);

  return (
    <>
      {loading && <PageLoader />}
      <Grid container>
        {deleteModal}
        {openViewModal}
        <Grid
          container
          md={12}
          sx={{ mb: 3, display: "flex", justifyContent: "center" }}
        >
          <Grid item xs={10} md={10}>
            <TableContainer
              component={Paper}
              sx={{ boxShadow: "none", backgroundColor: "#f5fbff" }}
            >
              <Table
                sx={{
                  minWidth: 600,
                  borderSpacing: "0 1rem",
                  borderCollapse: "separate",
                }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Document Name</StyledTableCell>
                    {displayTimePeriod && (
                      <StyledTableCell> Issue & Validity</StyledTableCell>
                    )}
                    <StyledTableCell>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={"request-table-body"}>
                  {documents[active].sub_documents.map((document, index) => (
                    <StyledTableRow
                      key={index}
                      className={"request-table-body-row"}
                    >
                      <StyledTableCell
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          minHeight: "45px",
                        }}
                      >
                        <Stack>
                          {document.name}
                          {(document.agency_upload || documents[active].agency_upload) &&
                            <Stack gap={"0.3rem"} direction={"row"} alignItems={"center"}>
                              <ContactPage sx={{color: "#4a73c2", fontSize: '12px'}}/>
                              <Typography
                                sx={{color: "#4a73c2", fontSize: '12px'}}
                              >
                                Uploaded by: <strong>Compliance Officer</strong>
                              </Typography>
                            </Stack>
                          }
                        </Stack>
                        {document.approval_status === "REJECTED" && (
                          <Tooltip
                            data-tut="candidate_document_reported"
                            title={
                              "There is a problem with document reported by agency please upload a new one"
                            }
                            arrow
                          >
                            <img src={images.tooltip} alt={"tooltip"} />
                          </Tooltip>
                        )}
                      </StyledTableCell>
                      {displayTimePeriod && document.options && (
                        <StyledTableCell data-tut="candidate_proof_of_address_validity_field">
                          {document?.options[document.value]?.value}
                        </StyledTableCell>
                      )}
                      <StyledTableCell>
                        {document.document_repository_id ? (
                          <Stack direction={"row"}>
                            <PrimaryButton
                              sx={{
                                height: "36px",
                                background: " #5AB9F9",
                                boxShadow:
                                  "0px 10px 25px rgba(90, 185, 249, 0.25)",
                                borderRadius: "6px",
                                color: "white",
                                minWidth: "45px",
                              }}
                              disabled={loading}
                              onClick={() => download(document, true)}
                            >
                              <Visibility sx={{ color: "#ffffff" }} />
                            </PrimaryButton>
                            <PrimaryButton
                              sx={{
                                height: "36px",
                                background: " #5AB9F9",
                                boxShadow:
                                  "0px 10px 25px rgba(90, 185, 249, 0.25)",
                                borderRadius: "6px",
                                color: "white",
                                mx: 3,
                                minWidth: "45px",
                              }}
                              disabled={loading}
                              onClick={() => download(document, false)}
                            >
                              <Download sx={{ color: "#ffffff" }} />
                            </PrimaryButton>
                            <PrimaryButton
                              sx={{
                                height: "36px",
                                background: " #5AB9F9",
                                boxShadow:
                                  "0px 10px 25px rgba(90, 185, 249, 0.25)",
                                borderRadius: "6px",
                                color: "white",
                                minWidth: "45px",
                              }}
                              disabled={loading}
                              onClick={() => unlinkDocument(document)}
                            >
                              <Delete sx={{ color: "#ffffff" }} />
                            </PrimaryButton>
                          </Stack>
                        ) : (
                          <PrimaryButton
                            fullWidth
                            sx={{
                              height: "36px",
                              background: " #5AB9F9",
                              boxShadow:
                                "0px 10px 25px rgba(90, 185, 249, 0.25)",
                              borderRadius: "6px",
                              color: "white",
                              minWidth: "108px",
                            }}
                            onClick={() => {
                              dispatch(setValue(2));
                              dispatch(setName(" : " + document.name));
                              dispatch(
                                setRequestData({
                                  request_id: id,
                                  vetting_document_id: documents[active].id,
                                  sub_document_id: document.id,
                                  document_repo_id: "",
                                })
                              );
                            }}
                            data-tut={index === 0 ? "select_tab_btn" : ""}
                          >
                            {/* {loading && <CircularProgress sx={{ mr: 1 }} size={20} />} */}
                            Upload File
                          </PrimaryButton>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SelectFiles;
