import { createSlice } from '@reduxjs/toolkit';

interface ItrisState {
  isItrisEnabled: boolean;
  isItrisConfigured: boolean;
}

const initialState: ItrisState = {
  isItrisEnabled: false,
  isItrisConfigured: false,
};

const itrisStatusSlice = createSlice({
  name: 'itrisStatus',
  initialState,
  reducers: {
    setItrisStatus(state, action) {
      state.isItrisEnabled = action.payload;
    },
    setItrisConfigured(state, action) {
      state.isItrisConfigured = action.payload;
    },
    clearItrisStatus(state) {
      state.isItrisEnabled = false;
      state.isItrisConfigured = false;
    },
  },
});

export default itrisStatusSlice.reducer;

// Export actions to dispatch
export const { setItrisStatus, setItrisConfigured, clearItrisStatus } = itrisStatusSlice.actions;

// Export selectors to access the state
export const selectItrisStatus = (state: { itrisStatus: ItrisState }) =>
  state.itrisStatus.isItrisEnabled;

export const selectItrisConfigured = (state: { itrisStatus: ItrisState }) =>
  state.itrisStatus.isItrisConfigured;