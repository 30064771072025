import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import "./RequestDetails.scss";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axiosInstance from "../../../../utils/axios";
import { URLS } from "../../../../utils/constants/urls";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { VettingDocument } from "./RequestDetailsModel";
import TopSection from "./TopSection";
import { useDispatch, useSelector } from "react-redux";
import {
  AdditionalForms,
  Comment,
  initialReferenceState,
  IsEditable,
  Reference,
  setAdditionalForms,
  setComment,
  setIsEditable,
  setNotificationMessage,
  setReference,
  setVettingDocuments,
  VettingDocuments,
  setPreviewReference,
} from "../../../../utils/redux";
import PageLoader from "../../../../components/PageLoader";
import Validate from "./Validate";
import { User } from "../../../../utils/redux/reducer/authentication-slice";
import { Auth } from "aws-amplify";
import PrimaryButton from "../../../../components/Button/PrimaryButton";
import Modal from "../../../../components/modal/Modal";
import {Check} from "@mui/icons-material";

const RequestDetails: FC = () => {
  const location = useLocation();
  const params = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };
  const vettingDocuments = useSelector(VettingDocuments);
  const additionalForms = useSelector(AdditionalForms);
  const comment = useSelector(Comment);
  const references = useSelector(Reference);
  const isEditable = useSelector(IsEditable);
  // const [opacity, setOpacity] = useState("1");

  const [loading, setLoading] = useState<boolean>(false);
  const [requestId, setRequestId] = useState<string>("");
  const [requestDetails, setRequestDetails] = useState<{
    [key: string]: string | { error: boolean; message: string } | undefined;
  }>({
    company: "",
    designation: "",
    date: "",
    comment: "",
  });
  const [status, setStatus] = useState<boolean>(false);
  const [showCandidate, setShowCandidate] = useState<boolean>(false);
  const [reloadGetApi, setReloadGetApi] = useState(false);
  const [publishPopupOpen, setPublishPopupOpen] = useState(false);

  const setData = (
    value: string,
    field: string,
    error: { error: boolean; message: string } | undefined
  ) => {
    setRequestDetails((prevState) => ({
      ...prevState,
      [field]: value as string,
      [field + "Error"]: error ? error : { error: false, message: "" },
    }));
  };

  const getRequest = useCallback(async () => {
    try {
      if (reloadGetApi || !reloadGetApi) {
        setLoading(true);
        const { data } = await axiosInstance.get(
          `${URLS.requests}/${params.id}`
        );
        setRequestId(data.request_id);
        setRequestDetails({
          company: data.company,
          companyError: { error: false, message: "" },
          designation: data.designation,
          designationError: { error: false, message: "" },
          date: data.date,
          dateError: { error: false, message: "" },
        });
        dispatch(
          setVettingDocuments(
            data.vetting_documents.sort(
              (a: VettingDocument, b: VettingDocument) =>
                a.candidate_index - b.candidate_index
            )
          )
        );
        dispatch(setAdditionalForms(data.additional_forms || []));
        dispatch(
          setReference(
            data.reference_detail &&
              (!!data.reference_detail.max_references_count ||
                !!data.reference_detail.referee_connection_year)
              ? {
                  ...data.reference_detail,
                  published: data.published,
                  max_references_count_error: { error: false, message: "" },
                  min_references_count_error: { error: false, message: "" },
                  referee_connection_year_error: { error: false, message: "" },
                  reference_created: true,
                  request_references: data.reference_form_data.map(
                    (reference_form: {
                      type: "employment" | "character" | "academic";
                      reference_forms: {
                        agency_id: string;
                        id: string;
                        name: string;
                        type: string;
                        created_at: number;
                      }[];
                      additional_questionnaires: {
                        id: string;
                        form_data: any[];
                      }[];
                      ked_questionnaire: boolean;
                    }) => ({
                      type: reference_form.type,
                      ked_questionnaire: reference_form.ked_questionnaire,
                      additional_questionnaires:
                        !!reference_form.additional_questionnaires
                          ? reference_form.additional_questionnaires.map(
                              (additional_questionnaire) =>
                                additional_questionnaire.id
                            )
                          : [],
                      reference_documents: !!reference_form.reference_forms
                        ? reference_form.reference_forms.map(
                            (reference_form) => reference_form.id
                          )
                        : [],
                    })
                  ),
                  is_video_acknowledged:
                    user["custom:reference_tutorial"] === "true",
                }
              : {
                  ...initialReferenceState.reference,
                  is_video_acknowledged:
                    user["custom:reference_tutorial"] === "true",
                }
          )
        );
        if (data.reference_form_data) {
          dispatch(setPreviewReference(data.reference_form_data));
        } else {
          dispatch(
            setPreviewReference(initialReferenceState.preview_reference)
          );
        }
        dispatch(setComment(data.comment));
        dispatch(setIsEditable(data.candidate_applied_count === 0));
        setStatus(data.published);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      if (await Auth.currentUserInfo()) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "There is an error in fetching requests",
          })
        );
      }
    }
  }, [dispatch, params.id, user, reloadGetApi]);

  useEffect(() => {
    (async () => {
      await getRequest();
    })();
  }, [getRequest]);

  useEffect(() => {
    setShowCandidate(location.pathname.indexOf("applicants") > -1);
  }, [location.pathname]);

  const publishRequest = async (draft: boolean) => {
    if (draft) {
      await publish(draft);
    } else {
      setPublishPopupOpen(true);
    }
  };

  const publish = useCallback(
    async (draft: boolean) => {
      try {
        setLoading(true);
        const { valid, errorFields, date_error } = Validate(
          requestDetails,
          vettingDocuments
        );
        if (valid) {
          await axiosInstance.put(
            `${URLS.requests}/${params.id}?publish=${!draft}`,
            isEditable
              ? {
                  company: requestDetails.company as string,
                  designation: requestDetails.designation as string,
                  date: requestDetails.date as string,
                  vetting_documents: vettingDocuments,
                  additional_forms: additionalForms,
                  comment,
                  reference_detail: references.reference_created
                    ? {
                        ...references,
                        max_references_count: references.max_references_count
                          ? parseInt(references.max_references_count)
                          : null,
                        referee_connection_year:
                          references.referee_connection_year
                            ? parseInt(references.referee_connection_year)
                            : null,
                      }
                    : null,
                }
              : {
                  date: requestDetails.date as string,
                }
          );
          setLoading(false);
          setStatus(!draft);
          dispatch(
            setNotificationMessage({
              display: true,
              severity: "success",
              message: draft
                ? "Request has been saved as Draft Successfully"
                : "Request has been published successfully",
              autoHideDuration: 6000,
            })
          );
          setReloadGetApi(!reloadGetApi);
        } else {
          if (date_error) {
            setRequestDetails((prevState) => ({
              dateError: { error: true, message: "Please enter a valid date" },
              ...prevState,
            }));
          }
          dispatch(
            setNotificationMessage({
              display: true,
              severity: "error",
              message:
                errorFields.length === 0
                  ? "There are Errors in your Request"
                  : "There are Errors in your Request: " +
                    errorFields.join(", "),
              autoHideDuration: null,
            })
          );
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        console.log(e);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "There is an error in publishing requests",
          })
        );
      }
    },
    [
      additionalForms,
      comment,
      dispatch,
      isEditable,
      params.id,
      references,
      reloadGetApi,
      requestDetails,
      vettingDocuments,
    ]
  );

  const publishRequestModalData = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "20px",
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              textAlign: "center",
              letterSpacing: "0.15px",
            }}
          >
            Publish Request
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            Take a moment to review the form before submitting. Once submitted,
            changes cannot be made. If you need to make any modification, please
            use the 'Go Back' button below.
          </Typography>
        </Grid>
        <Grid container alignItems="right" justifyContent="right">
          <Button
            sx={{
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
              mr: 2,
              mt: 1,
              paddingLeft: '2rem',
              paddingRight: '2rem',
              '&:hover': {
                background: '#3ea7ea'
              }
            }}
            startIcon={!loading ? <Check/> : null }
            disabled={loading}
            onClick={async () => {
              setPublishPopupOpen(false);
              await publish(false);
            }}
          >
            {loading && <CircularProgress sx={{ mr: 1, color: '#fff', size: '1rem' }}  />} Publish Request
          </Button>
          <PrimaryButton
            disabled={loading}
            fullWidth={false}
            sx={{
              border: "none",
              color: "#727272",
              fontSize: 16,
              fontWeight: 500,
              mt: 1,
              p: "10px 1rem",
              background: 'none',
              '&:hover': {
                background: '#fff !important',
                color: "#727272 !important",
              }
            }}
            onClick={() => {
              setPublishPopupOpen(false);
            }}
          >
            Go Back
          </PrimaryButton>

        </Grid>
      </Grid>
    );
  }, [loading, publish]);

  const publishRequestModal = useMemo(() => {
    return (
      <Modal
        open={publishPopupOpen}
        setModalClose={setPublishPopupOpen}
        children={publishRequestModalData}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [publishPopupOpen, publishRequestModalData]);

  const mobileContainer = {
    padding: 0
  }

  return (
    <>
      {loading && <PageLoader />}
      {publishRequestModal}
      <Container
        sx={{
          minHeight: {
            xs: "calc(100vh - 124px)",
            md: "calc(100vh - 94px)"
          },
          p: '0 !important',
          mb: 3,
          ...(!matches ? mobileContainer : null),
        }}
        maxWidth={false}
      >
        <TopSection
          requestId={requestId}
          requestDetails={requestDetails}
          setData={setData}
          status={status}
          params={params}
          publish={publishRequest}
          loading={loading}
          showCandidate={showCandidate}
        />
        <Outlet />
      </Container>
    </>
  );
};

export default RequestDetails;
