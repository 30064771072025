import React, {FC} from "react";
import {Autocomplete, Divider, FormControl, Stack, TextField, Typography, Button} from "@mui/material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
interface columnDataInterface {
  type: string,
  heading: string,
  custom_fields: {
    name: string,
    id: string,
    type: string,
    type_id: number,
    attachment_category_id: number | "",
    custom_field_id: number | "",
  }[]
}

interface customFieldDropdownsInterface {
  id: string,
  name: string,
  type: number
}

interface customFields {
  [key: string]: string
}

interface renderMappingColumnInterface {
  columnData: columnDataInterface,
  customFieldDropdowns: customFieldDropdownsInterface[],
  attachmentCategoriesDropdowns: customFieldDropdownsInterface[],
  customFields: customFields,
  attachmentCategories: customFields,
  handleCustomFieldChange: (event: any, newValue: any, id: string) => void,
  handleAttachmentCategoryChange: (event: any, newValue: any, id: string) => void,
  handleAddCredential?: () => void,
  handleRemoveCredential?: (index: number) => void,
}

const RenderMappingColumn: FC<renderMappingColumnInterface> = ({
                                                                 columnData,
                                                                 customFieldDropdowns,
                                                                 customFields,
                                                                 attachmentCategoriesDropdowns,
                                                                 attachmentCategories,
                                                                 handleCustomFieldChange,
                                                                 handleAttachmentCategoryChange,
                                                                 handleAddCredential,
                                                                 handleRemoveCredential
                                                               }) => {


  return (
        <Stack key={columnData.type} spacing={0}>
          <Stack
            sx={{
              borderRadius: '1rem 1rem 0 0',
              padding: 2,

              background: '#E9F6FF',
            }}
          >
            <Typography fontWeight={'bold'}>
              {columnData.heading}
            </Typography>
          </Stack>
          <Stack
            gap={'2rem'}
            sx={{
              background: '#F5FBFF',
              padding: '1rem',
              borderRadius: '0 0 1rem 1rem',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Stack
              sx={{
                borderRadius: '16px',
                background: 'white',
                padding: '1rem',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              {columnData.custom_fields.map((field, index) => {
                let customFieldDropdownsItems = customFieldDropdowns;
                if(field.type_id){
                  customFieldDropdownsItems = customFieldDropdowns.filter((item) => item.type === field.type_id);
                }
                return(
                  <React.Fragment key={field.id}>
                    <Stack
                      flexDirection={'column'}
                      sx={{width: '100%'}}
                      gap={'0.5rem'}
                    >
                      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography fontWeight={'bold'}>{field.name} {field.type && `(${field.type})`}</Typography>
                        {columnData.type === 'credentials' && index >= 3 && (
                          <Button variant={"outlined"} sx={{ width:"fit-content" }}
                                  size={"small"}
                                  color="error" onClick={() => handleRemoveCredential && handleRemoveCredential(index)}>
                            Remove
                          </Button>
                        )}
                      </Stack>
                      <Stack gap={{
                        xs: "1rem",
                        lg: "0.5rem"
                      }} flexDirection={{
                        sm: "column",
                        lg: "row"
                      }}>
                        <FormControl sx={{ flex: 1 }}>
                          <Autocomplete
                            options={customFieldDropdownsItems}
                            getOptionLabel={(option) => option.name}
                            value={customFieldDropdownsItems.find(option => option.id == customFields[field.id]) || null}
                            onChange={(event, newValue) => handleCustomFieldChange(event, newValue, field.id)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Custom Field"
                                variant="outlined"
                              />
                            )}
                          />
                        </FormControl>

                        <FormControl sx={{ flex: 1 }}>
                          <Autocomplete
                            options={attachmentCategoriesDropdowns}
                            getOptionLabel={(option) => option.name}
                            value={attachmentCategoriesDropdowns.find(option => option.id == attachmentCategories[field.id]) || null}
                            onChange={(event, newValue) => handleAttachmentCategoryChange(event, newValue, field.id)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Attachment Category"
                                variant="outlined"
                              />
                            )}
                          />
                        </FormControl>
                      </Stack>
                    </Stack>

                    {(index+1) !== columnData.custom_fields.length &&
                      <Divider sx={{my: '1rem'}}/>
                    }
                  </React.Fragment>
                )
              })}
            </Stack>
            {columnData.type === 'credentials' && columnData.custom_fields.length < 10 && (
              <Button variant={"outlined"} sx={{ width:"fit-content", background: "#fff" }} startIcon={<AddBoxOutlinedIcon />} onClick={handleAddCredential}>
                Add New Credential
              </Button>
            )}
          </Stack>


        </Stack>
  )
}

export default RenderMappingColumn
