import React, { FC, useEffect, useState } from "react";
import PeriodOfStay from "./PeriodOfStay";
import NoOPC from "./NoOPC";
import CandidateNotStayedAbroad from "./CandidateNotStayedAbroad";
import PoliceClearanceCertificate from "./PoliceClearanceCertificate";
import { URLS } from "../../../../../../../utils/constants/urls";
import axiosInstance from "../../../../../../../utils/axios";
import PageLoader from "../../../../../../../components/PageLoader";
import SelectFiles from "../common/SelectFiles";
import UploadFiles from "../common/UploadFiles";
import { useDispatch, useSelector } from "react-redux";
import {
  setName,
  setValue,
  setVettingDocuments, UserRole,
  VettingDocuments,
} from "../../../../../../../utils/redux";
import { setRequestData } from "../../../../../../../utils/redux";
import { useParams } from "react-router-dom";
import { RoutingPageValue } from "../../../../../../../utils/redux";
import { setNotificationMessage } from "../../../../../../../utils/redux";
import { identifiers } from "../../../../../../../utils/constants/identifiers";
import {VettingDocument} from "../../../../../Agency/RequestDetails/RequestDetailsModel";

export interface SelectFilesProps {
  active: number;
  vettingDocuments?: VettingDocument[]
}

const OPC: FC<SelectFilesProps> = ({ active, vettingDocuments }) => {

  const documents = useSelector(VettingDocuments);
  const value = useSelector(RoutingPageValue);
  const dispatch = useDispatch();
  const { id, candidate_id } = useParams() as any;
  const [component, setComponent] = useState("periodOfStay");
  const [isLoading, setIsLoading] = useState(false);
  const role = useSelector(UserRole);

  useEffect(() => {
    if (vettingDocuments && vettingDocuments.length > 0) {
      dispatch(setVettingDocuments(vettingDocuments));
    }
  }, [vettingDocuments, dispatch]);

  useEffect(() => {
    if(documents && documents.length > 0){
      if (value === 1) {
        if (documents[active].is_opc_needed !== undefined) {
          if (documents[active].is_opc_needed) {
            if (documents[active].have_opc_certificate !== undefined) {
              if (documents[active].have_opc_certificate) {
                if (!documents[active].opc_document_repo_id) {
                  dispatch(setName(identifiers.opc));
                  setComponent("uploadFiles");
                } else {
                  setComponent("selectFiles");
                }
              } else {
                setComponent("noOpc");
              }
            } else {
              setComponent("policeClearanceCertificate");
              dispatch(setValue(value + 1));
            }
          } else {
            setComponent("notStayedAbroad");
            dispatch(setValue(value + 1));
          }
        } else {
          setComponent("periodOfStay");
        }
      } else if (value === 2) {
        if (documents[active].is_opc_needed) {
          if (documents[active].have_opc_certificate !== undefined) {
            if (documents[active].have_opc_certificate) {
              dispatch(setName(identifiers.opc));
              setComponent("uploadFiles");
            } else {
              setComponent("noOpc");
            }
          } else {
            setComponent("policeClearanceCertificate");
          }
        } else {
          setComponent("notStayedAbroad");
        }
      } else if (value === 3) {
        if (documents[active].have_opc_certificate) {
          dispatch(setName(identifiers.opc));
          setComponent("uploadFiles");
        } else {
          setComponent("noOpc");
        }
      }
      dispatch(
        setRequestData({
          request_id: id,
          vetting_document_id: documents[active].id,
        })
      );
    }

  }, [active, dispatch, documents, id, value, setVettingDocuments]);

  const setOpcValue = async (
    isOpcNeeded?: boolean,
    opcCertificate?: boolean
  ) => {
    setIsLoading(true);
    let info: {
      is_opc_needed: boolean | undefined;
      have_opc_certificate: boolean | undefined;
      vetting_doc_id: string;
      candidate_id?: string;
    } = {
      is_opc_needed: isOpcNeeded,
      have_opc_certificate: opcCertificate,
      vetting_doc_id: documents[active].id,
      candidate_id: role === 'agency' ? candidate_id : ''
    };
    try {
      await axiosInstance.patch(`${URLS.requests}/${id}/document`, info);
      const vetting_documents = JSON.parse(JSON.stringify(documents));
      vetting_documents[active].is_opc_needed = isOpcNeeded;
      vetting_documents[active].have_opc_certificate = opcCertificate;
      dispatch(setVettingDocuments(vetting_documents));
      if (value < 3) {
        dispatch(setValue(value + 1));
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in Updating the OPC status",
        })
      );
    }
  };

  const goBack = () => {
    const vetting_documents = JSON.parse(JSON.stringify(documents));
    if (value === 2) {
      if (component === "notStayedAbroad") {
        vetting_documents[active].is_opc_needed = undefined;
      } else {
        vetting_documents[active].is_opc_needed = undefined;
      }
    }
    dispatch(setVettingDocuments(vetting_documents));
    dispatch(setValue(value - 1));
  };

  return (
    <>
      {isLoading && <PageLoader />}
      {component === "periodOfStay" && (
        <PeriodOfStay setOpcValue={setOpcValue} />
      )}
      {component === "policeClearanceCertificate" && (
        <PoliceClearanceCertificate setOpcValue={setOpcValue} goBack={goBack} />
      )}
      {component === "noOpc" && <NoOPC setOpcValue={setOpcValue} />}
      {component === "notStayedAbroad" && (
        <CandidateNotStayedAbroad goBack={goBack} />
      )}
      {component === "uploadFiles" && <UploadFiles showBack={false} showHub={role !== 'agency'}/>}
      {component === "selectFiles" && role !== 'agency' && <SelectFiles active={active} />}
    </>
  );
};

export default OPC;
