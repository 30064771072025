import React, { FC, useCallback, useEffect, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { Outlet, useParams } from "react-router-dom";
import { URLS } from "../../../utils/constants/urls";
import LeftSection from "./LeftSection";
import { useDispatch, useSelector } from "react-redux";
import {
  ReferenceData,
  setNotificationMessage,
  setReferenceData,
} from "../../../utils/redux";
import Questions from "./Questions";
import { identifiers } from "../../../utils/constants/identifiers";
import PageLoader from "../../../components/PageLoader";
import axiosInstance from "../../../utils/axios";

const Reference: FC = () => {
  const dispatch = useDispatch();
  const { id, type } = useParams();
  const reference = useSelector(ReferenceData);
  const [isLoading, setIsLoading] = useState(false);

  const getReference = useCallback(async () => {
    setIsLoading(true);
    let referenceType =
      type === "employment-gap" ? identifiers.character : type;
    try {
      const { data } = await axiosInstance.get(
        `${URLS.get_referee_details}/${id}/${referenceType}`,
        { noAuth: true } as any
      );
      const available_routes: string[] = [
        identifiers.referee_details as string,
      ];
      if (data.request_reference.ked_questionnaire) {
        available_routes.push(identifiers.ked_questionnaire as string);
      }
      if (
        data.request_reference.reference_documents &&
        data.request_reference.reference_documents.length > 0
      ) {
        available_routes.push(identifiers.reference_documents as string);
      }
      if (
        data.request_reference.additional_questionnaires &&
        data.request_reference.additional_questionnaires.length > 0
      ) {
        available_routes.push(identifiers.additional_questionnaire as string);
      }
      available_routes.push(identifiers.reference_comments as string);
      dispatch(
        setReferenceData({
          ...data,
          id,
          type: type === "employment-gap" ? identifiers.gap : type,
          reference_document_forms: data.reference_document_forms,
          request_id: data.candidate_reference.request_id,
          completed: data.candidate_reference.is_complete,
          referee_details: data.candidate_reference.referee_details,
          is_gap_reference: data.candidate_reference.is_gap_reference,
          ked_questionnaire: Questions(
            type === "employment-gap"
              ? (identifiers.gap as string)
              : (type as string)
          ),
          active_index: data.completed ? 4 : -1,
          available_routes,
        })
      );
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in fetching references Data",
          autoHideDuration: 6000,
        })
      );
    }
  }, [dispatch, id, type]);

  useEffect(() => {
    (async () => {
      await getReference();
    })();
  }, [getReference]);

  return (
    <Grid container>
      {isLoading && <PageLoader />}
      <Grid
        item
        xs={12}
        md={4}
        display={"flex"}
        flexDirection="column"
        sx={{
          backgroundColor: "#f5fbff",
          minHeight: { xs: "auto", md: "100vh" },
          pt: { xs: "20px", md: "100px" },
        }}
      >
        <LeftSection />
      </Grid>
      {!isLoading && (
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            backgroundColor: { xs: "#f5fbff", md: "white" },
            pb: { xs: 10, md: 2 },
          }}
        >
          <Stack
            width={"100%"}
            height={"120px"}
            sx={{
              py: 4,
              borderBottom: { xs: "none", md: "1px solid #DADADA" },
            }}
          >
            <Grid
              container
              justifyContent={"center"}
              flexGrow={2}
              alignItems={"center"}
            >
              <Grid item xs={11} lg={8}>
                <Typography
                  variant={"h6"}
                  sx={{
                    fontWeight: 500,
                    lineHeight: "28px",
                    color: "#333333",
                  }}
                  textTransform={"capitalize"}
                >
                  {reference.type === identifiers.gap
                    ? `Employment gap Reference for ${reference.candidate_profile?.name}`
                    : `${type} Reference for ${reference.candidate_profile?.name}`}
                </Typography>
                <Typography
                  variant={"body2"}
                  sx={{
                    lineHeight: "28px",
                    color: "#727272",
                  }}
                >
                  Please provide the details as requested
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          <Outlet />
        </Grid>
      )}
    </Grid>
  );
};

export default Reference;
