import React, {FC, useCallback, useMemo, useState} from "react";
import {SubDocument} from "../../../RequestDetails/RequestDetailsModel";
import {useDispatch, useSelector} from "react-redux";
import axiosInstance from "../../../../../../utils/axios";
import {URLS} from "../../../../../../utils/constants/urls";
import {
  AcademicQualificationIds,
  Reload,
  setCandidateProfileReload,
  setNotificationMessage,
  setReload
} from "../../../../../../utils/redux";
import {Button, CircularProgress, Grid, Typography} from "@mui/material";
import PrimaryButton from "../../../../../../components/Button/PrimaryButton";
import {AcademicDocument} from "../../../../../../utils/redux/reducer/candidate-vetting-slice";
import Modal from "../../../../../../components/modal/Modal";
import {Delete} from "@mui/icons-material";

const AgencyDeleteAcademicItem: FC<{
  id: string | undefined,
  candidate_id: string | undefined,
  request_id: string | undefined
}> = ({
        id,
                                        candidate_id,
                                        request_id
      }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const reload = useSelector(Reload);
  const ids = useSelector(AcademicQualificationIds);
  const deleteFile = async () => {
    setOpenModal(true);
  };

  const confirmDelete = useCallback(async () => {
    try {
      setLoading(true);
      await axiosInstance.delete(
        `${URLS.candidate_academic}${id}`
      );
      await updateQualification("delete");
      setOpenModal(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: `Academic qualification has been deleted Successfully`,
        })
      );
      dispatch(setReload(!reload));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setOpenModal(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in deleting document",
        })
      );
    }
  }, [
    id,
    dispatch,
    reload,
  ]);

  const updateQualification = useCallback(
    async (state: "add" | "delete") => {
      setLoading(true);
      try {
        let updated_ids: string[] = [];
        ids.forEach((doc_id) => {
          if (doc_id !== id) {
            updated_ids.push(doc_id);
          }
        });

        await axiosInstance.patch(
          `${URLS.candidate_request_details}${request_id}/request-candidate`,
          {
            academic_qualification_ids: updated_ids,
            candidate_id
          }
        );
        dispatch(setReload(!reload));
        dispatch(setCandidateProfileReload(!reload));
        setLoading(false);
      } catch (e) {
        setLoading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: `Unable to ${
              state === "add" ? "add" : "remove"
            } Credentials from Request`,
          })
        );
      }
    },
    [id, reload, ids, id, dispatch]
  );

  const modalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "20px",
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              textAlign: "center",
              letterSpacing: "0.15px",
            }}
          >
            Delete Document Permanently
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            Academic qualification will be deleted permanently from
            the repository.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            Are you sure?
          </Typography>
        </Grid>
        <Grid container alignItems="right" justifyContent="right">
          <Button
            sx={{
              border: "none",
              color: "#fff",
              fontSize: 16,
              fontWeight: 500,
              mr: 2,
              mt: 1,
              p: "10px",
              background: '#dc3545',
              '&:hover': {
                background: 'red'
              }
            }}
            disabled={loading}
            onClick={confirmDelete}
          >
            {loading && <CircularProgress sx={{ mr: 1 }} />}Delete
          </Button>
          <PrimaryButton
            disabled={loading}
            fullWidth={false}
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Cancel
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [confirmDelete, loading]);

  const openVerificationModal = useMemo(() => {
    return (
      <Modal
        open={openModal}
        setModalClose={setOpenModal}
        children={modalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [openModal, modalText]);

  return(
    <>
      {openVerificationModal}
      <PrimaryButton
        sx={{
          height: "36px",
          background: " #5AB9F9",
          boxShadow:
            "0px 10px 25px rgba(90, 185, 249, 0.25)",
          borderRadius: "6px",
          color: "white",
          minWidth: "45px",
        }}
         disabled={loading}
        onClick={() => deleteFile()}
      >
        <Delete sx={{ color: "#ffffff" }} />
      </PrimaryButton>
    </>
  )
}

export default AgencyDeleteAcademicItem
