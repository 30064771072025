import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormControl, Grid, Typography, Box } from "@mui/material";
import "../../ViewRequest.scss";
import { VettingDocument } from "../../../../Agency/RequestDetails/RequestDetailsModel";
import RightToWork from "./RightToWrok";
import ProofOIdentity from "./ProofOfIdentity";
import ProofOfAddress from "./ProofOfAddress";
import InputField from "../../../../../../components/InputField";
import CV from "./CV";
import OPC from "./OPC";
import DBS from "./DBS";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  RoutingPageValue,
  setValue,
  DocumentName,
  setName,
  CandidateIDV,
  uCheckStatus,
} from "../../../../../../utils/redux";
import { useDispatch, useSelector } from "react-redux";
import NationalInsuranceProof from "./NationalInsuranceProof";
import { identifiers } from "../../../../../../utils/constants/identifiers";
import Modal from "../../../../../../components/modal/Modal";
import PrimaryButton from "../../../../../../components/Button/PrimaryButton";
import AcademicQualification from "./AcademicQualification";
import { images } from "../../../../../../utils/constants/images";
import Review from "./DBS/UCheck/Review";
import { tourStep } from "../../../../../../utils/constants/coachMarks";
import { useTour } from "@reactour/tour";
import { CognitoUser } from "amazon-cognito-identity-js";
import * as Amplify from "../../../../../../utils/services/amplify";
import {
  User,
  setUserData,
} from "../../../../../../utils/redux/reducer/authentication-slice";
import { isTourCompleted, markTourAsCompleted } from "../../../../../../utils/storage/tours";

export interface DocumentListProps {
  documents: VettingDocument[];
  active: number;
  handleActive: (item: VettingDocument, index: number) => void;
}

const DocumentList: FC<DocumentListProps> = ({
  documents,
  active,
  handleActive,
}) => {
  const dispatch = useDispatch();
  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  const value = useSelector(RoutingPageValue);
  const candidate_idv = useSelector(CandidateIDV);
  const name = useSelector(DocumentName);
  const status = useSelector(uCheckStatus);
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };

  const [index, setIndex] = useState<number>(0);
  const [successMsg, setSuccessMsg] = useState(``);
  const [viewPopUpModal, setViewPopUpModal] = useState(false);
  const [expandCommentSec, setExpandCommentSec] = useState(false);
  const [uCheckPopUpOpen, setUCheckPopUpOpen] = useState(false);
  // const [reportedDocIndex, setReportedDocIndex] = useState<number>();

  useEffect(() => {
    const index = documents.findIndex((document) => {
      if (
        document.name === identifiers.rtw &&
        document.is_british_or_irish_citizen &&
        candidate_idv &&
        candidate_idv.status !== "ONGOING"
      ) {
        return false;
      }
      return (
        document.sub_document_count !== parseInt(document.documents_required)
      );
    });
    if (
      documents[active].sub_document_count ===
        parseInt(documents[active].documents_required) &&
      !documents[active].is_rejected
    ) {
      if (
        documents[active].name === identifiers.rtw ||
        documents[active].name === identifiers.nin ||
        documents[active].name === identifiers.dbs
      ) {
        if (
          documents[active].rtw_code ||
          documents[active].nin ||
          documents[active].dbs_cnumber ||
          documents[active].dbs_dob
        ) {
          setViewPopUpModal(true);
        }
      } else {
        setViewPopUpModal(true);
      }
    }
    if (
      documents[active].name === identifiers.rtw &&
      documents[active].is_british_or_irish_citizen &&
      candidate_idv &&
      candidate_idv.status !== "ONGOING"
    ) {
      setViewPopUpModal(true);
    }
    setIndex(index);
    if (index < 0) {
      setSuccessMsg("You have Uploaded All documents");
    } else if (documents[active].name === identifiers.cv) {
      setSuccessMsg(`You have uploaded ${documents[active].name}`);
    } else {
      setSuccessMsg(
        `You have uploaded a required documents of ${documents[active].name}`
      );
    }
  }, [active, candidate_idv, dispatch, documents]);

  const viewModalText = useMemo(() => {
    return (
      <Grid container className="dotted-card p-0 ">
        <Grid
          item
          md={12}
          xs={12}
          padding={"5px 10px"}
          borderRadius={"inherit"}
          sx={{ background: "#F3FCFF" }}
        >
          <Typography variant="h6" fontSize={"16px"}>
            Document Uploaded Successfully
          </Typography>
        </Grid>
        <Grid item padding={"10px 10px"} textAlign={"center"} md={12} xs={12}>
          <Typography variant="h6" fontSize={"14px"}>
            Congratulations!
          </Typography>
          <Typography variant="h6" fontSize={"12px"} sx={{ color: "#333333" }}>
            {successMsg}
          </Typography>
        </Grid>
        <Grid
          item
          display={"flex"}
          md={12}
          xs={12}
          padding={"10px 10px"}
          justifyContent={"space-evenly"}
        >
          {index < 0 ? null : (
            <PrimaryButton
              fullWidth={false}
              sx={{
                mt: 1,
                border: "1px solid #5AB9F9",
                background: "white",
                color: "#5AB9F9",
                fontSize: 16,
                fontWeight: 500,
                minWidth: "140px",
                minHeight: "32px",
              }}
              onClick={() => {
                handleActive(documents[active], index);
                setViewPopUpModal(false);
                // setDocument(null);
              }}
            >
              Next Section
            </PrimaryButton>
          )}
          <PrimaryButton
            fullWidth={false}
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
              minWidth: "140px",
              minHeight: "32px",
            }}
            onClick={() => {
              setViewPopUpModal(false);
            }}
          >
            Continue
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [successMsg, index, handleActive, documents, active]);

  const openViewModal = useMemo(() => {
    return (
      <Modal
        open={viewPopUpModal}
        setModalClose={setViewPopUpModal}
        children={viewModalText}
        title={undefined}
        size="sm"
        padding="0px"
        Radius={"15px"}
        className={undefined}
      />
    );
  }, [viewPopUpModal, viewModalText]);

  const uCheckPopUp = useMemo(() => {
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            background: "#5AB9F9",
            padding: "20px ",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
          display={"flex"}
          alignItems={"center"}
        >
          <Box sx={{ display: "flex", direction: "row", alignItems: "center" }}>
            <Typography
              variant="h6"
              sx={{
                ml: 2,
                fontSize: 18,
                fontWeight: 500,
                color: "#000000",
                textAlign: "center",
                letterSpacing: "0.15px",
              }}
            >
              DBS application
            </Typography>
          </Box>
          <img
            onClick={() => {
              setUCheckPopUpOpen(false);
            }}
            style={{ width: "20px", cursor: "pointer" }}
            src={images.closeBtn}
            alt={"close button"}
          />
        </Grid>
        {documents[active].name === identifiers.dbs && (
          <Grid item md={12} padding={"10px"}>
            <Review
              role="agency"
              dbs_type={documents[active].sub_documents[0].key as string}
              disabled={true}
            />
          </Grid>
        )}
      </Grid>
    );
  }, [active, documents]);

  const openUCheckPopUp = useMemo(() => {
    return (
      <Modal
        open={uCheckPopUpOpen}
        setModalClose={setUCheckPopUpOpen}
        children={uCheckPopUp}
        title={undefined}
        size="md"
        padding="0px"
        className={undefined}
      />
    );
  }, [uCheckPopUp, uCheckPopUpOpen]);

  const setCoachMarksValue = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:document_report": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [user, dispatch]);

  useEffect(() => {
    let index = documents[active]?.sub_documents?.findIndex(
      (document) => document.approval_status === "REJECTED"
    );
    // console.log(index, documents);
    if (index >= 0) {
      if (!!user && user["custom:document_report"] !== "true") {
        // setReportedDocIndex(index);
        setCurrentStep(tourStep.candidate_document_reported.index);
      }
    }
  }, [documents, active, user, setCurrentStep]);

  useEffect(() => {
    let index = documents[active]?.sub_documents?.findIndex(
      (document) => document.approval_status === "REJECTED"
    );
    if (index >= 0) {
      if (!!user && user["custom:document_report"] !== "true") {
        if (tourStep.candidate_document_reported.index === currentStep) {
          if (!isTourCompleted('candidate_document_reported')) {
            setIsOpen(true);
          }
        } else if (
          currentStep ===
          tourStep.candidate_document_reported.index +
            tourStep.candidate_document_reported.steps
        ) {
          setIsOpen(false);
          markTourAsCompleted('candidate_document_reported');
          (async () => {
            await setCoachMarksValue();
          })();
        }
      }
    }
  }, [currentStep, setCoachMarksValue, setIsOpen, user, active, documents]);

  return (
    <Grid
      container
      sx={{ width: "100%" }}
      className="card-vetting mb-4 mb-md-0 "
    >
      {openViewModal}
      {openUCheckPopUp}
      <Grid
        container
        className="card-head-table"
        justifyContent={"space-between"}
      >
        <Grid
          item
          display={"flex"}
          alignItems={"center"}
          md={6}
          sx={{ pl: 3, py: 3 }}
        >
          {documents[active].name === identifiers.opc ||
          value === 1 ||
          value === 0 ? null : (
            <ArrowBackIosIcon
              sx={{
                cursor: "pointer",
                height: "12px",
                marginTop: "4px",
                width: "12px",
                fontWeight: 500,
              }}
              onClick={() => {
                if (value > 0) {
                  dispatch(setValue(value - 1));
                  if (
                    (documents[active].name === identifiers.dbs &&
                      value === 4) ||
                    value === 2
                  ) {
                    dispatch(setName(""));
                  }
                }
              }}
            />
          )}
          <Typography
            variant={"body2"}
            fontSize={"16px"}
            fontWeight={500}
            sx={{ pl: { xs: 3, ms: 2 } }}
            paddingTop={"10px"}
          >
            {documents[active]?.name === identifiers.cv
              ? "Upload your latest CV here"
              : documents[active]?.name}
          </Typography>
          {name !== identifiers.cv && name !== identifiers.opc && (
            <Typography
              variant={"body2"}
              fontSize={"16px"}
              fontWeight={"600"}
              sx={{ pl: { xs: 3, ms: 2 } }}
              paddingTop={"10px"}
            >
              {name}
            </Typography>
          )}
        </Grid>
        {documents[active].default_documents_required !== "N/A" && (
          <>
            <Grid
              item
              md={4}
              sx={{
                py: { xs: 0, md: 3 },
                pl: { xs: 3, md: 0 },
                textAlign: { xs: "left", md: "right" },
              }}
            >
              <Typography
                variant={"body2"}
                fontSize={"16px"}
                fontWeight={"bold"}
                paddingTop={"10px"}
              >
                Number of Documents required for this section
              </Typography>
            </Grid>
            <Grid
              data-tut="candidate_required_no_docs_tab"
              item
              xs={12}
              md={2}
              sx={{ px: 2, py: 1 }}
            >
              <FormControl
                sx={{ minWidth: 25, px: 1, paddingY: "8px", width: "100%" }}
              >
                <InputField
                  inputValue={documents[active].documents_required}
                  size={"small"}
                  className="headerTimePeriod mt-2"
                  disabled={true}
                />
              </FormControl>
            </Grid>
          </>
        )}
        {documents[active].name === identifiers.dbs && (
          <>
            {status === "REJECTED" && (
              <Grid item xs={12} md={4} display={"flex"} alignItems={"center"}>
                <PrimaryButton
                  fullWidth={true}
                  sx={{
                    background: "#F4D356",
                    border: "1px solid #5AB9F9",
                    color: "#212121",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                  onClick={() => setUCheckPopUpOpen(true)}
                >
                  View application form
                </PrimaryButton>
              </Grid>
            )}
            {status === "IN_REVIEW" && (
              <Grid item xs={12} md={4} display={"flex"} alignItems={"center"}>
                <PrimaryButton
                  fullWidth={true}
                  sx={{
                    background: "#F4D356",
                    border: "1px solid #5AB9F9",
                    color: "#212121",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                  onClick={() => setUCheckPopUpOpen(true)}
                >
                  View application form
                </PrimaryButton>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Grid
        container
        justifyContent={"center"}
        sx={{
          paddingBottom: {
            xs: '8rem',
            md: 0
          }
        }}
        //spacing={2}
      >
        {documents[active].name === identifiers.rtw && (
          <RightToWork active={active} />
        )}

        {documents[active].name === identifiers.academic_qualification && (
          <AcademicQualification active={active} />
        )}
        {documents[active].name === identifiers.poi && (
          <ProofOIdentity active={active} />
        )}
        {documents[active].name === identifiers.poa && (
          <ProofOfAddress active={active} />
        )}
        {documents[active].name === identifiers.nin && (
          <NationalInsuranceProof active={active} />
        )}
        {documents[active].name === identifiers.cv && <CV active={active} />}
        {documents[active].name === identifiers.opc && <OPC active={active} />}
        {documents[active].name === identifiers.dbs && <DBS active={active} />}
        {documents[active].comment && (
          <Grid
            md={10}
            container
            sx={{
              backgroundColor: "white",
              width: "85%",
              margin: "20px 0px 20px 0px",
              border: "1px dashed #DADADA",
              borderRadius: "12px",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                className=" pl-2 py-3"
                display={"flex"}
                alignItems={"center"}
              >
                Additional Comments{" "}
                {expandCommentSec ? (
                  <ExpandLessIcon
                    sx={{ ml: 2 }}
                    onClick={() => setExpandCommentSec(!expandCommentSec)}
                  />
                ) : (
                  <ExpandMoreIcon
                    sx={{ ml: 2 }}
                    onClick={() => setExpandCommentSec(!expandCommentSec)}
                  />
                )}
              </Grid>
            </Grid>
            {expandCommentSec && (
              <>
                <Grid
                  item
                  xs={12}
                  className=" pl-2 py-3"
                  sx={{
                    margin: "10px 10px",
                    border: "1px dotted red",
                    borderRadius: "15px",
                  }}
                >
                  <Typography>{documents[active].comment}</Typography>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default DocumentList;
