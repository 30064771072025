import React, {FC, useCallback, useMemo, useState} from "react";
import {Delete} from "@mui/icons-material";
import PrimaryButton from "../../../../../../components/Button/PrimaryButton";
import {SubDocument} from "../../../RequestDetails/RequestDetailsModel";
import Modal from "../../../../../../components/modal/Modal";
import {Button, CircularProgress, Grid, Typography} from "@mui/material";
import {identifiers} from "../../../../../../utils/constants/identifiers";
import axiosInstance from "../../../../../../utils/axios";
import {URLS} from "../../../../../../utils/constants/urls";
import {
  Reload,
  setCandidateProfileReload,
  setName,
  setNotificationMessage,
  setReload,
  setValue
} from "../../../../../../utils/redux";
import {useDispatch, useSelector} from "react-redux";

const AgencyDeleteItem: FC<{
  activeDocument: any,
  document: SubDocument,
  id: string | undefined,
  candidate_id: string | undefined
}> = ({
        activeDocument,
        id,
        candidate_id,
        document
      }) => {
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subDoc, setSubDoc] = useState<SubDocument | null>(null);
  const reload = useSelector(Reload);

  const unlinkDocument = async (document: SubDocument) => {
    setSubDoc(document);
    setOpenModal(true);
  };
  const confirmDelete = useCallback(async () => {
    try {
      setLoading(true);
      if (
        activeDocument.name === identifiers.cv ||
        activeDocument.name === identifiers.opc ||
        activeDocument.name === identifiers.dbs
      ) {
        await axiosInstance.post(
          `${URLS.unlink_document}?stage=${process.env.REACT_APP_YOTI_ENV}`,
          {
            request_id: id,
            vetting_document_id: activeDocument.id,
            candidate_id: candidate_id,
          }
        );
      } else {
        await axiosInstance.post(
          `${URLS.unlink_document}?stage=${process.env.REACT_APP_YOTI_ENV}`,
          {
            request_id: id,
            sub_document_id: document?.id,
            candidate_id: candidate_id,
          }
        );
      }
      dispatch(setReload(!reload));
      dispatch(setCandidateProfileReload(!reload));
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: `File ${document?.name} has been removed Successfully`,
        })
      );
      setOpenModal(false);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in removing the document",
        })
      );
      setOpenModal(false);
    }
  }, [dispatch, document?.id, document?.name, id, reload]);

  const deleteModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "20px",
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              textAlign: "center",
              letterSpacing: "0.15px",
            }}
          >
            Remove {document?.name} from Request?
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            Are you sure?
          </Typography>
        </Grid>
        <Grid container alignItems="right" justifyContent="right">
          <Button
            sx={{
              border: "none",
              color: "#fff",
              fontSize: 16,
              fontWeight: 500,
              mr: 2,
              mt: 1,
              p: "10px",
              background: '#dc3545',
              '&:hover': {
                background: 'red'
              }
            }}
            disabled={loading}
            onClick={confirmDelete}
          >
            {loading && <CircularProgress sx={{ mr: 1, color: '#fff' }} size={"1rem"} />}Remove Document
          </Button>
          <PrimaryButton
            disabled={loading}
            fullWidth={false}
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={() => {
              setOpenModal(false);
              setSubDoc(null);
            }}
          >
            Cancel
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [confirmDelete, document?.name, loading]);

  const deleteModal = useMemo(() => {
    return (
      <Modal
        open={openModal}
        setModalClose={setOpenModal}
        children={deleteModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [openModal, deleteModalText]);

  return (
    <>
      <PrimaryButton
        onClick={() => unlinkDocument(document)}
        sx={{
          borderRadius: "8px",
          p: "0px 16px",
          ml: 2,
          height: "40px",
          fontSize: "14px",
          background: '#fff',
          boxShadow: 'none',
          color: '#ff6d6d',
          '&:hover': {
            color: '#fff !important',
            background: '#ff6d6d !important',
            boxShadow: 'none !important',
          }

        }}
      >
        <Delete/>
      </PrimaryButton>
      {deleteModal}
    </>
  )
}
export default AgencyDeleteItem
