export const identifiers: { [key: string]: string | number } = {
  otp_digit: 6,
  accept:
    'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, image/png, image/jpg, image/jpeg',
  agency_accept:
    'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf',
  date_format: 'DD/MM/YYYY',
  month_format: 'MM-YYYY',
  date_input: 'YYYY-MM-DD',
  field_error: 'This Field is Required',
  character: 'character',
  academic: 'academic',
  employment: 'employment',
  gap: 'employment_gap',
  agency_max_file_size: 8388608,
  maxFileSize: 8388608,
  maxFileSizeText: '8MB',
  referee_details: 'referee_details',
  ked_questionnaire: 'ked_questionnaire',
  reference_documents: 'reference_documents',
  additional_questionnaire: 'additional_questionnaire',
  reference_comments: 'reference_comments',
  reference_document_forms: 'reference_document_forms',
  rtw: 'Right to Work',
  dbs: 'DBS Check',
  cv: 'CV',
  nin: 'National Insurance Proof',
  poa:'Proof of Address',
  poi:'Proof of Identity',
  academic_qualification: 'Credentials',
  opc: 'Overseas Check',
  rtw_url: 'https://www.gov.uk/prove-right-to-work',
};
