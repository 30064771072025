import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import "./styles.css";
import { Grid, IconButton } from "@mui/material";

const Modal = ({
  open,
  setModalClose,
  title,
  children,
  size,
  isBackdrop = false,
  className,
  closeButton = false,
  padding = "20px 24px",
  background = "white",
  Radius="5px",
  ...rest
}) => {
  const handleClose = () => {
    setModalClose(false);
  };
  return (
    <div>
      <Dialog
      PaperProps={{
        style: { borderRadius: Radius }   }}
        className={className}
        {...rest}
        fullWidth
        maxWidth={size || "xs"}
        open={open}
        onClose={isBackdrop ? () => {} : handleClose}
      >
        <Grid className={title ? "flexRow border" : ""}>
          <Stack direction={"row"} alignItems={"center"} paddingRight={"1rem"} justifyContent={"space-between"}>
            {title && <DialogTitle>{title}</DialogTitle>}
            {closeButton && (
              <div className="closeButton">
                <IconButton
                  size="small"
                  color="default"
                  className="deleteButton"
                  onClick={handleClose}
                  aria-label="edit"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            )}
          </Stack>
        </Grid>
        <DialogContent
          sx={{ padding: `${padding}`, background: `${background}` }}
        >
          {children}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Modal;
