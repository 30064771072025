import {identifiers} from "../../../../../../utils/constants/identifiers";
import {Stack} from "@mui/system";
import Citizenship from "../../../../Candidate/ViewRequest/VettingDocuments/DocumentList/RightToWrok/Citizenship";
import {CircularProgress} from "@mui/material";
import React, {FC, useEffect, useState} from "react";
import {VettingDocument} from "../../../RequestDetails/RequestDetailsModel";
import {useSelector} from "react-redux";
import {Reloading} from "../../../../../../utils/redux";
import UpdatedServices from "../../../../Candidate/ViewRequest/VettingDocuments/DocumentList/DBS/UpdatedServices";

const stackStyle = {
  backgroundColor: "#f5fbff",
  padding: '1.5rem 3rem',
  width: '100%',
  borderRadius: "12px",
  minHeight: '400px',
  alignItems: 'center',
  justifyContent: 'center'
}


const RtwDBSComponent: FC<{
  documents: VettingDocument[];
  active: number;
  candidate_id?: string;
  isDataLoading?: boolean;
}> = ({
        documents,
        active,
        candidate_id,
                               isDataLoading = false
      }) => {

  const reloading = useSelector(Reloading);
  const [country, setCountry] = useState("");

  const [dbs, setDbs] = useState<{
    id: string;
    comments: string | undefined;
    objective: string;
    name: string;
    display: boolean;
  }>({
    id: "",
    comments: "",
    objective: "",
    name: '',
    display: false,
  });

  const setDbsValue = (value: boolean) => {
    setDbs((prevState) => ({...prevState, display: false}));
    // setScheme(value ? "RTW" : "DBS_RTW");
  };

  useEffect(() => {
    const dbsDocument = documents.find(
      (document) => document.name === identifiers.dbs
    );
    if (!!dbsDocument) {
      if (dbsDocument.on_updated_dbs_services === undefined) {
        setDbs({
          id: dbsDocument.id,
          comments: dbsDocument.comments,
          objective: dbsDocument.sub_documents[0].name
            .split(" ")[0]
            .toUpperCase(),
          name: dbsDocument.sub_documents[0].name,
          display: true,
        });
      } else {
        setDbs((prevState) => ({
          ...prevState,
          objective: dbsDocument.sub_documents[0].name
            .split(" ")[0]
            .toUpperCase(),
          name: dbsDocument.sub_documents[0].name,
          display: false,
        }));
        // setScheme(dbsDocument.on_updated_dbs_services ? "RTW" : "DBS_RTW");
      }
    }
  }, [active, documents, candidate_id]);

  return (
    <>
      {
        documents[active].name === identifiers.rtw &&
        documents[active].is_british_or_irish_citizen === undefined &&
        <>
          <Stack sx={{position: 'absolute', left: 0, right: 0, top: '30px', bottom: 0, padding: '0 1rem'}}>

            <Stack sx={stackStyle}>
              <Stack>
                {reloading === '' &&
                  <Citizenship
                    setCountry={setCountry}
                    vettingDocId={documents[active].id}
                    role={'agency'}
                    candidateId={candidate_id}
                  />
                }
                {reloading === 'rtw' &&
                  <CircularProgress size={"1.25rem"}/>
                }
              </Stack>
            </Stack>

          </Stack>
        </>
      }

      {
        documents[active].name === identifiers.rtw &&
        documents[active].is_british_or_irish_citizen &&
        dbs.display &&
        <Stack
          sx={{position: 'absolute', left: 0, right: 0, top: '30px', bottom: 0, padding: '0 1rem', background: '#fff'}}>

          <Stack sx={stackStyle}>
            <UpdatedServices name={dbs.name} setDbs={setDbsValue} vettingDocId={dbs.id} role={"agency"}
                             candidate_id={candidate_id}/>
          </Stack>
        </Stack>
      }

      {
        documents[active].name === identifiers.rtw &&
        documents[active].is_british_or_irish_citizen !== undefined &&
        documents[active].is_british_or_irish_citizen === false &&
        dbs.display &&
        <Stack
          sx={{position: 'absolute', left: 0, right: 0, top: '30px', bottom: 0, padding: '0 1rem', background: '#fff'}}>
          <Stack sx={stackStyle}>
            <UpdatedServices name={dbs.name} setDbs={setDbsValue} vettingDocId={dbs.id} role={"agency"}
                             candidate_id={candidate_id}/>
          </Stack>
        </Stack>
      }

      {
        documents[active].name === identifiers.dbs &&
        dbs.display &&
        <Stack
          sx={{position: 'absolute', left: 0, right: 0, top: '30px', bottom: 0, padding: '0 1rem', background: '#fff'}}>

          {!isDataLoading &&
          <Stack sx={stackStyle}>
            <UpdatedServices name={dbs.name} setDbs={setDbsValue} vettingDocId={dbs.id} role={"agency"}
                             candidate_id={candidate_id}/>
          </Stack>
          }
          {isDataLoading &&
            <CircularProgress size={"1.25rem"}/>
          }
        </Stack>
      }
    </>
  )
}
export default RtwDBSComponent;
