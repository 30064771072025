import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { User } from "../../../../../../../../utils/redux/reducer/authentication-slice";
import {
  Reload,
  RequestData, setCandidateProfileReload, setName,
  setReload,
  setRequestData, setValue
} from "../../../../../../../../utils/redux/reducer/candidate-vetting-slice";
import FromDevice from "./FromDevice";
import { CircularProgress, Grid } from "@mui/material";
import PrimaryButton from "../../../../../../../../components/Button/PrimaryButton";
import mime from "mime";
import axiosInstance from "../../../../../../../../utils/axios";
import { URLS } from "../../../../../../../../utils/constants/urls";
import { setNotificationMessage } from "../../../../../../../../utils/redux";
import axios from "axios";
import { identifiers } from "../../../../../../../../utils/constants/identifiers";

const UploadOnly: FC<{
  showBack?: boolean,
  onClickBack?: () => void,
  name: string,
  request_id?: string,
  vetting_document_id: string | undefined,
  candidate_id: string | undefined,
  sub_document_id?: string | null,
  onUploadComplete?: () => void,
}> = ({
  showBack = true,
  name,
  request_id,
  vetting_document_id,
  candidate_id,
  onClickBack,
  sub_document_id,
  onUploadComplete
}) => {
    const dispatch = useDispatch();

    const user: { [key: string]: string } = useSelector(User) as {
      [key: string]: string;
    };

    const [files, setFiles] = useState<File[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [percentageProcess, setPercentageProcess] = useState(0);
    const reload = useSelector(Reload);


    const handleFileUpload = async () => {
      if (files && files.length !== 0) {
        setLoading(true);
        const file: File = files[0];

        const formData: {
          name: string;
          type: string;
          directory: string;
          file_name: string;
          request_id?: string;
          candidate_id?: string
        } = {
          type: mime.getType(file.name)
            ? (mime.getType(file.name) as string)
            : file.type,
          name: `${new Date().getTime()}_${file.name}`,
          file_name: name?.replace(" / ", " or ").replace(" : ", "") as string,
          directory: name === "CV" ? "cv" : "documents",
          request_id: request_id,
          candidate_id: candidate_id,
        };

        try {
          const { data } = await axiosInstance.post(
            URLS.candidate_file_upload,
            formData
          );

          await uploadFile(
            file,
            data.url,
            mime.getType(file.name)
              ? (mime.getType(file.name) as string)
              : file.type,
            data.id
          );
        } catch (e: any) {
          setLoading(false);
          dispatch(
            setNotificationMessage({
              display: true,
              severity: "error",
              message: `Unable to Upload File`,
            })
          );
        }

      }

    }

    const uploadFile = async (
      file: File,
      url: string,
      mime: string,
      file_id: string
    ) => {
      try {
        const { status } = await axios.put(url, file, {
          headers: { "Content-Type": mime },
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total || 100)
            );
            setPercentageProcess(percentCompleted);
          },
        });
        if (status === 200) {
          setTimeout(async () => await linkDocument(file_id), 4000);
        }
      } catch (e) {
        setLoading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: `Unable to Upload File`,
          })
        );
      }
    };

    const linkDocument = async (file_id?: string) => {
      try {
        setLoading(true);
        const file_name: string = name
          ?.replace(" / ", " or ")
          .replace(" : ", "") as string;

        const linkData: {
          request_id: string | undefined,
          vetting_document_id: string | undefined,
          document_repo_id: string | undefined,
          candidate_id: string | undefined,
          sub_document_id?: string | undefined,
        } = {
          request_id: request_id,
          vetting_document_id: vetting_document_id,
          document_repo_id: file_id,
          candidate_id: candidate_id,
        };

        if (sub_document_id) {
          linkData.sub_document_id = sub_document_id;
        }
        await axiosInstance.post(
          `${URLS.link_document}?stage=${process.env.REACT_APP_YOTI_ENV}`,
          linkData
        );
        dispatch(setReload(!reload));

        dispatch(setCandidateProfileReload(!reload));
        // setTimeout(() => {
        //   dispatch(setRequestData({...request, document_repo_id: file_id}));
        //   dispatch(setValue(file_name === identifiers.cv ? 0 : 1));
        //   dispatch(setName(""));
        // }, 500);
        if (onUploadComplete) {
          onUploadComplete()
        }
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "success",
            message: `File ${file_name} has been uploaded Successfully`,
          })
        );
        setLoading(false);
      } catch (e) {
        setLoading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: `Unable to upload File `,
          })
        );
      }
    };

    return (
      <>
        <FromDevice files={files} setFiles={setFiles} />
        <Grid
          item
          display={"flex"}
          justifyContent="center"
          className="card-footer"
          md={12}
          xs={12}
          sx={{ p: 3 }}
          alignItems={'center'}
        >
          {showBack && (
            <PrimaryButton
              fullWidth={false}
              sx={{
                width: { xs: "150px", md: "212px" },
                height: "36px",
                background: "#FFFFFF",
                border: " 1px solid #5AB9F9",
                borderRadius: "5px",
                padding: { xs: "0px", md: "4px 44px" },
                color: "#5AB9F9",
                fontSize: "14px",
                margin: { xs: "0px 20px 0px 0px", md: "0px 20px" },
              }}
              onClick={() => {
                if (onClickBack) {
                  onClickBack();
                }
              }}
            >
              Go back
            </PrimaryButton>
          )}
          <PrimaryButton
            fullWidth={false}
            sx={{
              width: { xs: "150px", md: "212px" },
              height: "36px",
              background: " #5AB9F9",
              boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
              borderRadius: "6px",
              color: "#ffffff",
            }}
            disabled={loading}
            onClick={() => (handleFileUpload())}
          >
            {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
            Upload
          </PrimaryButton>
        </Grid>
      </>
    )
  }
export default UploadOnly
