import { Stack } from "@mui/system";
import { ArrowDropDown, ArrowDropUp, ReportOutlined, WarningAmberOutlined } from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Collapse,
  Chip, CircularProgress
} from "@mui/material";
import React, { FC, useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import PrimaryButton from "../../../../../../../components/Button/PrimaryButton";
import Modal from "../../../../../../../components/modal/Modal";
import axiosInstance from "../../../../../../../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { Reload, setCandidateProfileReload, setReload } from "../../../../../../../utils/redux";
import FailCasesBreakdowns from "./FailCasesBreakdowns";

const FailedCases: FC<{
  checks: any,
  subChecks: any,
  type: string,
  id: string,
  showReinit?: boolean
}> = ({ checks, subChecks, type, id, showReinit = true }) => {
  const dispatch = useDispatch();

  const reload = useSelector(Reload);

  const [reInitializing, setReInitializing] = useState(false);
  const [isClosingYotiCheck, setIsClosingYotiCheck] = useState(false);

  // for yoti fail case reason table icons
  const getReasonTypeIcon = (type: string) => {
    switch (type) {
      case 'REJECT':
        return <CloseIcon color="error" />;
      case 'NOT_AVAILABLE':
        return <ReportOutlined color="action" />;
      case 'CONSIDER':
        return <ReportOutlined color="action" />;
      default:
        return null;
    }
  };

  const closeYotiCheck = async () => {
    setIsClosingYotiCheck(true);
    await axiosInstance.post(
      `${process.env.REACT_APP_NODE_V3_API_URL}/authenticated/yoti/re-init/${id}`
    );
    dispatch(setReload(!reload));
    dispatch(setCandidateProfileReload(!reload));
  }

  const viewModalText = useMemo(() => {
    return (
      <Grid container className="dotted-card p-0 ">
        <Grid
          item
          md={12}
          xs={12}
          padding={"5px 10px"}
          borderRadius={"inherit"}
          sx={{ background: "#F3FCFF" }}
        >
          <Typography variant="h6" fontSize={"16px"}>
            Re-initialize Yoti Check
          </Typography>
        </Grid>
        <Grid item padding={"10px 10px"} textAlign={"center"} md={12} xs={12}>
          {isClosingYotiCheck &&
            <Typography variant="h6" fontSize={"1rem"}>
              Re-initializing Yoti check, please wait...
            </Typography>
          }
          {!isClosingYotiCheck &&
            <Typography variant="h6" fontSize={"1rem"}>
              Are you sure you want to re-initialize Yoti Check?
            </Typography>
          }

        </Grid>
        <Grid
          item
          display={"flex"}
          md={12}
          xs={12}
          padding={"1rem 1rem"}
          gap={"1.5rem"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <PrimaryButton
            fullWidth={false}
            disabled={isClosingYotiCheck}
            sx={{
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
              minWidth: "140px",
              minHeight: "32px",
            }}
            onClick={() => closeYotiCheck()}
          >
            {isClosingYotiCheck && <CircularProgress sx={{ mr: 1, color: '#9a9a9a' }} size={"1rem"} />}
            Confirm
          </PrimaryButton>
          <PrimaryButton
            fullWidth={false}
            disabled={isClosingYotiCheck}
            sx={{
              border: "1px solid #5AB9F9",
              background: "white",
              color: "#5AB9F9",
              fontSize: 16,
              fontWeight: 500,
              minWidth: "140px",
              minHeight: "32px",
            }}
            onClick={() => {
              setReInitializing(false)
            }}
          >
            Cancel
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [reInitializing, closeYotiCheck]);

  const openViewModal = useMemo(() => {
    return (
      <Modal
        open={reInitializing}
        setModalClose={() => setReInitializing(false)}
        children={viewModalText}
        title={undefined}
        size="sm"
        padding="0px"
        Radius={"15px"}
        className={undefined}
      />
    );
  }, [reInitializing, viewModalText]);

  return (
    <Grid item xs={12} md={12}>
      {openViewModal}
      <Stack sx={{
        background: '#ffeaea',
        borderRadius: '0.5rem',
        boxShadow: '0 0 0 1px #e5c5c5',
        padding: '1rem',
        gap: '1rem',
        mb: 2
      }}>
        <Stack direction={"row"} gap={"0.5rem"} alignItems={"center"}>
          <WarningAmberOutlined />
          <Typography variant="h6" sx={{ fontSize: '1rem' }}>
            The following issue(s) were identified during the Right to Work validation
          </Typography>
        </Stack>

        <Stack sx={{
          background: '#fff',
          borderRadius: '0.5rem',
          boxShadow: '0 0 0 1px #e5c5c5',
          padding: '1rem',
          gap: '1rem'
        }}>
          <Chip label={type.replace(/_/g, ' ')}
            sx={{
              width: 'fit-content',
              fontSize: "0.75rem",
              height: 'fit-content',
              padding: '0.1rem 0'
            }} color={"error"} />
          {checks.map((check: any) => (
            <Stack>
              <Typography variant="h6" sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
                {check.reason_code.replace(/_/g, ' ')}
              </Typography>
              <Typography sx={{ fontSize: '0.875rem' }}>
                {check.message}
              </Typography>
              <Divider sx={{ mt: 1, mb: 1 }} />
            </Stack>


          ))}

          {subChecks.length > 0 &&
            <Stack gap={"1rem"}>
              {subChecks.map((reason: any, index: number) => (

                <Stack gap={"0.5rem"} key={index}>

                  <Typography variant="h6" sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
                    {reason.type.replace(/_/g, ' ')}
                  </Typography>

                  {reason.recommendations.length > 0 &&
                    <Table
                      size="small"
                      sx={{
                        borderSpacing: "0 0.5rem",
                        borderCollapse: "separate",
                        backgroundColor: "#fff",
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: '30%', paddingLeft: 0, fontWeight: 'normal' }}>
                            Reason Code
                          </TableCell>
                          <TableCell sx={{ width: '10%', fontWeight: 'normal' }}>
                            Type
                          </TableCell>
                          <TableCell sx={{ width: '60%', fontWeight: 'normal' }}>
                            Message
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reason.recommendations.map((recommendation: any, index: number) => (
                          <TableRow
                            key={index}
                          >
                            <TableCell sx={{ textAlign: 'left', width: '30%', paddingLeft: 0 }}>
                              <Stack gap={"0.5rem"}>
                                <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
                                  {recommendation.reason.replace(/_/g, ' ')}
                                </Typography>

                                {recommendation.breakdowns && recommendation.breakdowns.length > 0 &&
                                  <FailCasesBreakdowns breakdowns={recommendation.breakdowns} />
                                }
                              </Stack>
                            </TableCell>
                            <TableCell sx={{ textAlign: 'left', width: '10%' }}>
                              <Stack direction={"row"} gap={"0.5rem"} alignItems={"center"}>
                                {getReasonTypeIcon(recommendation.value)}
                                {recommendation.value.replace(/_/g, ' ')}
                              </Stack>
                            </TableCell>
                            <TableCell sx={{ textAlign: 'left', width: '60%' }}>
                              {recommendation.message}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  }
                </Stack>

              ))}
            </Stack>


          }
        </Stack>

        {showReinit &&
          <Button
            variant="contained"
            sx={{ width: 'fit-content', color: 'white' }}
            size={"large"}
            onClick={() => setReInitializing(true)}
          >
            Reinitialize Check
          </Button>
        }


      </Stack>
      {showReinit &&
        <Divider sx={{ margin: '2rem 0' }} />
      }
    </Grid>
  )
}

export default FailedCases;
