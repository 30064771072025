import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Typography, useTheme, useMediaQuery
} from "@mui/material";
import DocumentList from "./DocumentList";
import CheckBox from "../../../../../components/CheckBox";
import {
  Authenticated,
  VettingDocuments as VettingDocumentsData,
  setValue,
  setName,
  CandidateIDV,
  AcademicQualificationIds,
  setCandidateVettingDocActiveIndex,
  uCheckStatus,
} from "../../../../../utils/redux";

import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { useSelector, useDispatch } from "react-redux";
import { VettingDocument } from "../../../Agency/RequestDetails/RequestDetailsModel";
import { identifiers } from "../../../../../utils/constants/identifiers";
import { useTour } from "@reactour/tour";
import { CognitoUser } from "amazon-cognito-identity-js";
import * as Amplify from "../../../../../utils/services/amplify";
import {
  User,
  setUserData,
} from "../../../../../utils/redux/reducer/authentication-slice";
import { tourStep } from "../../../../../utils/constants/coachMarks";
import { isTourCompleted, markTourAsCompleted } from "../../../../../utils/storage/tours";

const LinearProgressWithLabel: FC<
  {
    value: number;
    reqNumber: number;
  } & LinearProgressProps
> = ({ value, reqNumber, ...props }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          value={(value / reqNumber) * 100}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {`${Math.round(value)}`}/{reqNumber}
        </Typography>
      </Box>
    </Box>
  );
};

const VettingDocuments: FC = () => {
  const dispatch = useDispatch();
  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  // for scroll to active tab
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  // target div to scroll
  const targetDivRef = useRef<HTMLDivElement | null>(null);

  const isAuthenticated = useSelector(Authenticated);
  const candidate_idv = useSelector(CandidateIDV);
  const documents = useSelector(VettingDocumentsData);
  const selectedAcademicQualifications = useSelector(AcademicQualificationIds);
  const status = useSelector(uCheckStatus);
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };

  const [active, setActive] = useState<number>(0);

  const setCoachMarksValue = useCallback(
    async (value: any) => {
      //if (!!user && user["custom:reference_tutorial"] !== "true") {
      const currentUser: CognitoUser = await Amplify.UserDetail();
      const body = value;
      await Amplify.UpdateUserDetails(body, currentUser);
      await Amplify.RefreshSession();
      dispatch(setUserData({ ...user, ...body }));
      //}
    },
    [user, dispatch]
  );

  useEffect(() => {
    // console.log(documents);
    if (!!documents && documents.length > 0) {
      if (documents[active].name === identifiers.cv) {
        if (!!documents[active].cv_document_repo_id) {
          dispatch(setValue(0));
          dispatch(setName(""));
        } else {
          dispatch(setValue(1));
          // dispatch(setName(""));
        }
      } else if (documents[active].name === identifiers.dbs && !!status) {
        if (
          status === "IN_REVIEW" ||
          status === "SUBMITTED" ||
          status === "REJECTED" ||
          status === "APPROVED"
        ) {
          dispatch(setValue(2));
        }
      } else {
        dispatch(setValue(1));
        dispatch(setName(""));
      }
    }
  }, [active, dispatch, documents, status]);

  useEffect(() => {
    if (!!documents && documents.length > 0) {
      if (user["custom:id_proof_tutorial"] !== "true") {
        if (documents[active].name === identifiers.poi) {
          setCurrentStep(tourStep.candidate_proof_identity.index);
        }
      }
      if (user["custom:address_tutorial"] !== "true") {
        if (documents[active].name === identifiers.poa) {
          setCurrentStep(tourStep.candidate_proof_address.index);
        }
      }
      if (user["custom:nin_tutorial"] !== "true") {
        if (documents[active].name === identifiers.nin) {
          setCurrentStep(tourStep.candidate_nin.index);
        }
      }
      if (user["custom:dbs_tutorial"] !== "true") {
        if (documents[active].name === identifiers.dbs) {
          setCurrentStep(tourStep.candidate_dbs.index);
        }
      }
      if (user["custom:opc_tutorial"] !== "true") {
        if (documents[active].name === identifiers.opc) {
          setCurrentStep(tourStep.canidate_opc.index);
        }
      }
    }
  }, [documents, active, user, setCurrentStep]);

  useEffect(() => {
    if (!!documents && documents.length > 0) {
      if (documents[active].name === identifiers.poi) {
        if (tourStep.candidate_proof_identity.index === currentStep) {
          if (!isTourCompleted('candidate_proof_identity')) {
            setIsOpen(true);
          }
        } else if (
          currentStep ===
          tourStep.candidate_proof_identity.index +
            tourStep.candidate_proof_identity.steps
        ) {
          setIsOpen(false);
          markTourAsCompleted('candidate_proof_identity');
          if (!!user && user["custom:id_proof_tutorial"] !== "true") {
            (async () => {
              await setCoachMarksValue({ "custom:id_proof_tutorial": "true" });
            })();
          }
        }
      }

      if (documents[active].name === identifiers.poa) {
        if (tourStep.candidate_proof_address.index === currentStep) {
          if (!isTourCompleted('candidate_proof_address')) {
            setIsOpen(true);
          }
        } else if (
          currentStep ===
          tourStep.candidate_proof_address.index +
            tourStep.candidate_proof_address.steps
        ) {
          setIsOpen(false);
          markTourAsCompleted('candidate_proof_address');
          if (!!user && user["custom:address_tutorial"] !== "true") {
            (async () => {
              await setCoachMarksValue({
                "custom:address_tutorial": "true",
              });
            })();
          }
        }
      }

      if (documents[active].name === identifiers.nin) {
        if (tourStep.candidate_nin.index === currentStep) {
          if (!isTourCompleted('candidate_nin')) {
            setIsOpen(true);
          }
        } else if (
          currentStep ===
          tourStep.candidate_nin.index + tourStep.candidate_nin.steps
        ) {
          setIsOpen(false);
          markTourAsCompleted('candidate_nin');
          if (!!user && user["custom:nin_tutorial"] !== "true") {
            (async () => {
              await setCoachMarksValue({
                "custom:nin_tutorial": "true",
              });
            })();
          }
        }
      }

      if (documents[active].name === identifiers.dbs) {
        if (tourStep.candidate_dbs.index === currentStep) {
          if (!isTourCompleted('candidate_dbs')) {
            setIsOpen(true);
          }
        } else if (documents[active].on_updated_dbs_services === undefined) {
          if (
            currentStep ===
            tourStep.candidate_dbs.index + tourStep.candidate_dbs.steps
          ) {
            setIsOpen(false);
            markTourAsCompleted('candidate_dbs');
            if (!!user && user["custom:dbs_tutorial"] !== "true") {
              (async () => {
                await setCoachMarksValue({
                  "custom:dbs_tutorial": "true",
                });
              })();
            }
          }
        } else {
          if (
            currentStep ===
            tourStep.candidate_dbs.index + (tourStep.candidate_dbs.steps - 1)
          ) {
            setIsOpen(false);
            markTourAsCompleted('candidate_dbs');
            if (!!user && user["custom:dbs_tutorial"] !== "true") {
              (async () => {
                await setCoachMarksValue({
                  "custom:dbs_tutorial": "true",
                });
              })();
            }
          }
        }
      }
      if (documents[active].name === identifiers.opc) {
        if (tourStep.canidate_opc.index === currentStep) {
          if (!isTourCompleted('canidate_opc')) {
            setIsOpen(true);
          }
        } else if (
          currentStep ===
          tourStep.canidate_opc.index + tourStep.canidate_opc.steps
        ) {
          setIsOpen(false);
          markTourAsCompleted('canidate_opc');
          if (!!user && user["custom:opc_tutorial"] !== "true") {
            (async () => {
              await setCoachMarksValue({
                "custom:opc_tutorial": "true",
              });
            })();
          }
        }
      }
    }
  }, [currentStep, documents, active, setIsOpen, user, setCoachMarksValue]);

  const handleActive = useCallback(
    (item: VettingDocument, index: number) => {

      setActive(index);
      // check if mobile
      if(isMobile){
        // check if scrollable div has current
        if (targetDivRef.current) {
          targetDivRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start', // Scroll to the top of the element
            inline: 'nearest', // Align nearest to the horizontal center
          });
        }
      }

      dispatch(setCandidateVettingDocActiveIndex(index));
      if (item.name === identifiers.cv) {
        if (!!item.cv_document_repo_id) {
          dispatch(setValue(0));
          dispatch(setName(""));
        } else {
          dispatch(setValue(1));
          dispatch(setName(""));
        }
      } else if (item.name === identifiers.dbs && !!status) {
        if (
          status === "IN_REVIEW" ||
          status === "SUBMITTED" ||
          status === "REJECTED" ||
          status === "APPROVED"
        ) {
          dispatch(setValue(2));
        }
      } else {
        dispatch(setValue(1));
        dispatch(setName(""));
      }
    },
    [dispatch, status]
  );

  const background = (item: VettingDocument, index: number) => {
    if (index === active) {
      return "#E3F4FF";
    }
    if (item.is_rejected) {
      return "#F6D3D3";
    }
    if (item.name === identifiers.opc && item.approval_status === "REJECTED") {
      return "#F6D3D3";
    }

    if (
      item.name === identifiers.rtw &&
      item.is_british_or_irish_citizen &&
      candidate_idv &&
      candidate_idv.status !== "ONGOING"
    ) {
      return "#E3F4FF";
    }
    if (
      item.name === identifiers.rtw &&
      item.sub_document_count === parseInt(item.documents_required) &&
      !!item.rtw_code
    ) {
      return "#E3F4FF";
    }

    if (
      item.name === identifiers.rtw &&
      item.sub_document_count > 0 &&
      !item.rtw_code
    ) {
      return "#FEFBEC";
    }

    if (
      item.name === identifiers.nin &&
      item.sub_document_count === parseInt(item.documents_required) &&
      !!item.nin
    ) {
      return "#E3F4FF";
    }
    if (item.name === identifiers.nin && item.nin) {
      return "#FEFBEC";
    }
    if (
      item.name === identifiers.nin &&
      item.sub_document_count > 0 &&
      !item.nin
    ) {
      return "#FEFBEC";
    }
    if (item.sub_document_count === parseInt(item.documents_required)) {
      return "#E3F4FF";
    }
    if (item.name === identifiers.dbs && status === "SUBMITTED") {
      return "#FEFBEC";
    }
    if (item.name === identifiers.dbs && status === "IN_REVIEW") {
      return "#FEFBEC";
    }
    if (item.name === identifiers.dbs && status === "REJECTED") {
      return "#F6D3D3";
    }
    if (item.name === identifiers.dbs && status === "APPROVED") {
      return "#FEFBEC";
    }

    if (item.sub_document_count > 0) {
      return "#FEFBEC";
    }
    if (
      item.name === identifiers.academic_qualification &&
      selectedAcademicQualifications?.length > 0
    ) {
      return "#E3F4FF";
    }
    return "#FFFFFF";
  };

  return (
    <Grid container sx={{ mt: 3 }}>
      <Grid item xs={12} lg={4}>
        <List dense sx={{ width: "100%", p: 0 }}>
          {documents?.map((item, index) => (
            <ListItem sx={{ px: { xs: 0, md: 3 } }} disablePadding key={index}>
              {item.name === identifiers.rtw &&
              item.is_british_or_irish_citizen ? (
                <ListItemIcon>
                  <CheckBox
                    checked={
                      candidate_idv && candidate_idv?.status !== "ONGOING"
                    }
                    disabled
                    type="shield"
                    sx={{ marginBottom: "8px" }}
                    shieldColor={
                      item.is_rejected
                        ? "error"
                        : item.is_british_or_irish_citizen &&
                          candidate_idv &&
                          candidate_idv?.status !== "ONGOING"
                        ? "success"
                        : "warning"
                    }
                  />
                </ListItemIcon>
              ) : item.name === identifiers.rtw &&
                !item.is_british_or_irish_citizen ? (
                <ListItemIcon>
                  <CheckBox
                    checked={
                      (item.sub_document_count ===
                        parseInt(item.documents_required) &&
                        !!item.rtw_code) ||
                      item.sub_document_count > 0
                    }
                    disabled
                    type="shield"
                    sx={{ marginBottom: "8px" }}
                    shieldColor={
                      item.is_rejected
                        ? "error"
                        : item.sub_document_count ===
                            parseInt(item.documents_required) && item.rtw_code
                        ? "success"
                        : "warning"
                    }
                  />
                </ListItemIcon>
              ) : item.name === identifiers.academic_qualification ? (
                <ListItemIcon>
                  <CheckBox
                    checked={selectedAcademicQualifications?.length > 0}
                    disabled
                    type="shield"
                    sx={{ marginBottom: "8px" }}
                    shieldColor={
                      item.is_rejected
                        ? "error"
                        : selectedAcademicQualifications?.length > 0
                        ? "success"
                        : "warning"
                    }
                  />
                </ListItemIcon>
              ) : (
                <ListItemIcon>
                  <CheckBox
                    checked={
                      item.sub_document_count > 0 ||
                      (item.name === identifiers.dbs &&
                        (status === "IN_REVIEW" ||
                          status === "SUBMITTED" ||
                          status === "REJECTED" ||
                          status === "APPROVED"))
                    }
                    disabled
                    type="shield"
                    sx={{ marginBottom: "8px" }}
                    shieldColor={
                      item.is_rejected
                        ? "error"
                        : item.name === identifiers.opc &&
                          item.approval_status === "REJECTED"
                        ? "error"
                        : item.name === identifiers.nin &&
                          item.sub_document_count ===
                            parseInt(item.documents_required) &&
                          item.nin
                        ? "success"
                        : item.name !== identifiers.nin &&
                          item.sub_document_count ===
                            parseInt(item.documents_required)
                        ? "success"
                        : item.name === identifiers.dbs &&
                          status === "IN_REVIEW"
                        ? "warning"
                        : item.name === identifiers.dbs &&
                          status === "SUBMITTED"
                        ? "warning"
                        : item.name === identifiers.dbs && status === "APPROVED"
                        ? "warning"
                        : item.name === identifiers.dbs && status === "REJECTED"
                        ? "error"
                        : "warning"
                    }
                  />
                </ListItemIcon>
              )}
              <ListItemButton
                data-tut={
                  index === active ? "candidate_vetting_docs_reported_tab" : ""
                }
                onClick={() => handleActive(item, index)}
              >
                <ListItemText
                  data-tut={
                    item.name === identifiers.rtw
                      ? "candidate_rtw_tab"
                      : item.name === identifiers.poi
                      ? "candidate_proof_of_identity_tab"
                      : item.name === identifiers.dbs
                      ? "candidate_dbs_tab"
                      : item.name === identifiers.nin
                      ? "candidate_nin_tab"
                      : item.name === identifiers.opc
                      ? "candidate_opc_tab"
                      : item.name === identifiers.academic_qualification
                      ? "candidate_academic_qualification_tab"
                      : item.name === identifiers.poa
                      ? "candidate_proof_of_address_tab"
                      : ""
                  }
                  sx={{
                    background: background(item, index),
                    border: "1px solid rgba(90, 185, 249, 0.25)",
                    borderRadius: "8px",
                    margin: "0 0 10px 0",
                    padding: "8px 20px",
                  }}
                  primary={
                    <Typography
                      variant={"body2"}
                      fontWeight={500}
                      color={"#212121"}
                      fontSize={14}
                    >
                      {item.name}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography
                        variant={"body2"}
                        fontWeight={400}
                        color={"#333333"}
                        fontSize={14}
                      >
                        {item.name === identifiers.cv
                          ? "Upload your latest CV"
                          : item.sub_text}
                      </Typography>
                      {isAuthenticated && item.documents_required !== "N/A" && (
                        <>
                          {item.name === identifiers.rtw &&
                          item.is_british_or_irish_citizen ? (
                            <LinearProgressWithLabel
                              variant="determinate"
                              value={
                                candidate_idv &&
                                candidate_idv.status !== "ONGOING"
                                  ? 1
                                  : 0
                              }
                              color={
                                index === active
                                  ? "primary"
                                  : candidate_idv &&
                                    candidate_idv?.status !== "ONGOING"
                                  ? "success"
                                  : "secondary"
                              }
                              reqNumber={1}
                            />
                          ) : item.name === identifiers.rtw &&
                            !item.is_british_or_irish_citizen ? (
                            <LinearProgressWithLabel
                              variant="determinate"
                              value={item.sub_document_count}
                              color={
                                index === active
                                  ? "primary"
                                  : item.is_rejected
                                  ? "error"
                                  : item.sub_document_count ===
                                      parseInt(item.documents_required) &&
                                    item.rtw_code
                                  ? "success"
                                  : item.sub_document_count > 0
                                  ? "warning"
                                  : item.sub_document_count > 0 &&
                                    !item.rtw_code
                                  ? "warning"
                                  : "secondary"
                              }
                              reqNumber={parseInt(item.documents_required)}
                            />
                          ) : item.name ===
                            identifiers.academic_qualification ? (
                            <LinearProgressWithLabel
                              variant="determinate"
                              value={
                                selectedAcademicQualifications &&
                                selectedAcademicQualifications?.length
                                  ? selectedAcademicQualifications?.length
                                  : 0
                              }
                              color={
                                index === active
                                  ? "primary"
                                  : selectedAcademicQualifications &&
                                    selectedAcademicQualifications?.length > 0
                                  ? "primary"
                                  : "secondary"
                              }
                              reqNumber={
                                selectedAcademicQualifications &&
                                selectedAcademicQualifications?.length > 0
                                  ? selectedAcademicQualifications?.length
                                  : 1
                              }
                            />
                          ) : (
                            <LinearProgressWithLabel
                              variant="determinate"
                              value={item.sub_document_count}
                              color={
                                index === active
                                  ? "primary"
                                  : item.is_rejected
                                  ? "error"
                                  : item.name === identifiers.opc &&
                                    item.approval_status === "REJECTED"
                                  ? "error"
                                  : item.name === identifiers.nin &&
                                    item.sub_document_count ===
                                      parseInt(item.documents_required) &&
                                    item.nin
                                  ? "primary"
                                  : item.name === identifiers.nin &&
                                    item.sub_document_count > 0 &&
                                    item.nin
                                  ? "warning"
                                  : item.name === identifiers.nin && item.nin
                                  ? "warning"
                                  : item.name !== identifiers.nin &&
                                    item.sub_document_count ===
                                      parseInt(item.documents_required)
                                  ? "primary"
                                  : item.name === identifiers.dbs &&
                                    status === "IN_REVIEW"
                                  ? "warning"
                                  : item.name === identifiers.dbs &&
                                    status === "SUBMITTED"
                                  ? "warning"
                                  : item.name === identifiers.dbs &&
                                    status === "APPROVED"
                                  ? "warning"
                                  : item.name === identifiers.dbs &&
                                    status === "REJECTED"
                                  ? "error"
                                  : item.sub_document_count > 0
                                  ? "warning"
                                  : "secondary"
                              }
                              reqNumber={parseInt(item.documents_required)}
                            />
                          )}
                        </>
                      )}
                    </>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Grid>
      {documents?.length > 0 && (
        <Grid item xs={12} md={12} lg={8} ref={targetDivRef}>
          <DocumentList
            active={active}
            documents={documents}
            handleActive={handleActive}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default VettingDocuments;
