import React, {FC} from "react";
import AcademicQualification
  from "../../../../Candidate/ViewRequest/VettingDocuments/DocumentList/AcademicQualification/AcademicQualification";
import {Grid, Typography} from "@mui/material";
import {identifiers} from "../../../../../../utils/constants/identifiers";

export interface SelectFilesProps {
  active: number;
}

const CandidateAcademicQualificationForm: FC<SelectFilesProps> = ({active}) => {
  return (
    <Grid xs={12} container className="card-vetting" sx={{mt: '1.5rem !important'}}>
      <Grid
        container
        className="card-head-table"
        direction="row"
        sx={{ marginBottom: '0 !important' }}
        justifyContent={"space-between"}
        alignItems={'center'}
      >
        <Grid
          item
          xs={12}
          sx={{pl: 1, py: 3}}
        >
          <Typography
            variant={"body2"}
            fontSize={"16px"}
            fontWeight={"bold"}
            paddingTop={"10px"}
            sx={{pl: {xs: "18px", md: 2}}}
          >
            Add New Credential
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{pl: 1, pb: 3}}
      >
        <AcademicQualification active={active}/>
      </Grid>
    </Grid>
  )
}
export default CandidateAcademicQualificationForm
