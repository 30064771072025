import { Grid, Typography } from "@mui/material";
import React, { ChangeEvent, FC, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import PrimaryButton from "../../../../../../../components/Button/PrimaryButton";
import { useSelector } from "react-redux";
import { UserRole } from "../../../../../../../utils/redux";

interface PeriodOfStayProps {
  setOpcValue: (isOpcNeeded: boolean, opcCertificate?: boolean) => void;
}

const PeriodOfStay: FC<PeriodOfStayProps> = ({ setOpcValue }) => {
  const [values, setValues] = useState("");
  const role = useSelector(UserRole);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValues(event.target.value);
  };

  return (
    <Grid container justifyContent={"center"} lg={12} sx={{ pl: 2 }}>
      <Grid
        md={7}
        xs={11}
        item
        className="pop-up-dotted-card-dbs content-card"
        sx={{ marginTop: "55px", marginBottom: "65px" }}
      >
        <Typography
          variant={"body1"}
          color={"#333333"}
          sx={{
            mb: 1,
            fontSize: "18px",
            fontWeight: "500",
            letterSpacing: 0.15,
            marginBottom: "30px",
          }}
        >
          {role === 'agency' &&
            <>Has the applicant stayed abroad for longer than 6 months <br></br> within last 5
              years ?
            </>
          }
          {role === 'candidate' &&
            <>Have you stayed abroad for longer than 6 month <br></br> within last 5
              years ?
            </>
          }
        </Typography>
        <FormControl>
          <RadioGroup
            className="radio-group"
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={values}
            onChange={handleChange}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid
        item
        display={"flex"}
        justifyContent={"center"}
        className="card-footer"
        md={12}
        sx={{ p: 3 }}
      >
        <PrimaryButton
          fullWidth={false}
          sx={{
            width: { xs: "150px", md: "212px" },
            height: "36px",
            background: " #5AB9F9",
            boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
            borderRadius: "6px",
            color: "white",
          }}
          disabled={!values}
          onClick={() => {
            if (values === "no") {
              setOpcValue(false);
            } else {
              setOpcValue(true);
            }
          }}
        >
          {/* {loading && <CircularProgress sx={{ mr: 1 }} size={20} />} */}
          Next
        </PrimaryButton>
      </Grid>
    </Grid>
  );
};

export default PeriodOfStay;
